import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGroup } from 'deprecated/Components/SettingsList/Security/GroupManagement/GroupTypes';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateGroup {
  execute(group: IGroup): Promise<IGroup>;
}

export class CreateGroup extends RemoteUseCaseBase implements ICreateGroup {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(group: IGroup): Promise<IGroup> {
    const response = await axios.post(this.url.href, group, this.config.getAuthConfig());
    return response.data;
  }
}
