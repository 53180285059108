import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IDownloadInfo from 'deprecated/Domain/IDownloadInfo';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetDownloadInfoById {
  execute: (id: string) => Promise<IDownloadInfo>;
}

export default class GetDownloadInfoById implements IGetDownloadInfoById {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async execute(id: string): Promise<IDownloadInfo> {
    const url = new URL(this.host.href);
    url.pathname = `api/downloads/${id}`;

    const response = await axios.get<IDownloadInfo>(url.href, this.config.getAuthConfig());

    return response.data;
  }
}
