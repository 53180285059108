export interface IServiceDictionary {
  [key: string]: never;
}

export interface IServiceRegister {
  register<T>(serviceName: string, service: T): void;
}

export interface IServiceProvider {
  get<T>(serviceName: string): T;
}

/**
 * @deprecated
 */
export interface IServiceContainer extends IServiceRegister, IServiceProvider {
  services: IServiceDictionary;
}

/**
 * @deprecated
 */
export class ServiceContainer implements IServiceContainer {
  public services: IServiceDictionary;

  constructor(services?: IServiceDictionary) {
    this.services = services || {};
  }

  public get<T>(serviceName: string): T {
    return this.services[serviceName];
  }

  public register<T>(serviceName: string, service: T): void {
    this.services[serviceName] = service as never;
  }
}
