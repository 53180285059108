import { IGetUserPresenter, IGetUser } from 'deprecated/Boundary/GetUser';
import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class GetUser implements IGetUser {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute(id: number, presenter: IGetUserPresenter) {
    try {
      const response = await this.gateway.getUser(id);
      presenter.user(response);
    } catch (error) {
      presenter.notUser(error);
    }
  }
}
