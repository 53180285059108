import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteSegment {
  execute(segmentId: number, presenter: IDeleteSegmentPresenter): void;
}

export interface IDeleteSegmentPresenter {
  segmentDeleted(): void;
  segmentNotDeleted(error: string[] | string): void;
}

export class DeleteSegment implements IDeleteSegment {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(segmentId: number, presenter: IDeleteSegmentPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/segments/${segmentId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());
      presenter.segmentDeleted();
    } catch (e) {
      if (isAxiosError(e)) {
        const { response } = e;
        if (response && response.status === 404) {
          presenter.segmentNotDeleted(response.data.errors[0]);
        } else {
          presenter.segmentNotDeleted('There was an issue deleting the segment');
        }
      }
    }
  }
}
