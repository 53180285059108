import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IFeatureLabel from 'deprecated/Domain/IFeatureLabel';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetFeatureLabels {
  execute(): Promise<IFeatureLabel[]>;
}

export class GetFeatureLabels extends RemoteUseCaseBase implements IGetFeatureLabels {
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/feature-labels', undefined, config);
  }

  public async execute(): Promise<IFeatureLabel[]> {
    const { data } = await axios.get(`${this.url.href}/`, this.config.getAuthConfig());
    return data;
  }
}
