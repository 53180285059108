import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteCanonicalProduct {
  execute(productId: number, presenter: IDeleteCanonicalProductPresenter): Promise<void>;
}

export interface IDeleteCanonicalProductPresenter {
  deleteProductResult(status: number): void;
}

export default class DeleteCanonicalProduct implements IDeleteCanonicalProduct {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(productId: number, presenter: IDeleteCanonicalProductPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/canonicalProduct/${productId}`;

    const requestConfig = this.config.getAuthConfig();

    try {
      await axios.delete(url.href, requestConfig).then((response) => {
        presenter.deleteProductResult(response.status);
      });
    } catch (e) {
      if (isAxiosError(e) && e.response) {
        presenter.deleteProductResult(e.response.status);
      }
    }
  }
}
