// The import order in this file matters, moving some of the components
// on top of the scss might break css specificity
import './deprecated/index.scss';
import '@smartwyre/scss/lib/index.scss';
import App from 'App';
import InitServiceContainer from 'deprecated/InitServiceContainer/InitServiceContainer';
import InitAxios from '@smartwyre/components/lib/InitAxios';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { ENV_CONFIG_FRAGMENT_TYPE } from 'fragments/Config';
import { EnvConfigProvider } from 'contexts/EnvConfigContext/EnvConfigContext';

import BackLinkTracker from 'ui/BackLink/components/BackLinkTracker/BackLinkTracker';

import { initializeAppInsights } from './init/utils/initializeAppInsights';
import { initializeSentry } from './init/utils/initializeSentry';
import * as serviceWorker from './serviceWorker';
import ConfigProvider from 'antd/lib/config-provider';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import InitAuthentication from 'init/components/InitAuthentication';

import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import InitOrganizationContext from 'contexts/organizations/OrganizationContext/components/InitOrganizationContext';
import InitThemeContext from 'contexts/organizations/ThemeContext/components/InitThemeContext';
import { settingsThemeDetailQueryCacheKey } from '__generated__/api/{orgNodeCode}/settings/theme/Api';
import { orgNodesDetailQueryCacheKey } from '__generated__/api/OrgNodes/{code}/Api';
import { PageTitleProvider } from 'contexts/PageTitleContext';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  },
});

async function getEnvConfig() {
  const x = await axios.get<ENV_CONFIG_FRAGMENT_TYPE>('/envconfig.json');
  return x.data;
}

getEnvConfig().then(async (envConfig) => {
  initializeAppInsights(envConfig);
  initializeSentry(envConfig);

  /**
   * Required for `InitOrganizationContext` and `InitThemeContext` to work
   */
  authenticatedAxios.defaults.baseURL = envConfig.api_url;

  const app = (
    <ConfigProvider theme={{ hashed: false }}>
      <BrowserRouter>
        <BackLinkTracker>
          <EnvConfigProvider value={envConfig}>
            <QueryClientProvider client={queryClient}>
              <InitOrganizationContext>
                <InitThemeContext>
                  <PageTitleProvider>
                    <InitServiceContainer>
                      <InitAuthentication>
                        <InitAxios
                          apiUrl={envConfig.api_url}
                          axiosInstance={authenticatedAxios}
                          handleAxiosError={(error) => {
                            if (error?.response?.status === 401) {
                              queryClient.removeQueries({
                                predicate: (query) =>
                                  // These should stay in the cache on logout given they are not tied to a user session
                                  query.queryKey[0] !== settingsThemeDetailQueryCacheKey() && query.queryKey[0] !== orgNodesDetailQueryCacheKey(),
                              });
                            }
                            return Promise.reject(error);
                          }}
                        >
                          <App />
                        </InitAxios>
                      </InitAuthentication>
                    </InitServiceContainer>
                  </PageTitleProvider>
                </InitThemeContext>
              </InitOrganizationContext>
            </QueryClientProvider>
          </EnvConfigProvider>
        </BackLinkTracker>
      </BrowserRouter>
    </ConfigProvider>
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!);
  root.render(app);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
