/* eslint-disable jsx-a11y/control-has-associated-label */
import DownOutlined from '@ant-design/icons/DownOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';
import Switch from 'antd/lib/switch';
import React from 'react';

import { useNotificationsSuppressionListQuery } from '__generated__/api/notifications/suppression/Api';

import useNotificationSuppression from 'hooks/notifications/useNotificationSuppression';

import classes from './SuppressNotificationPortal.module.scss';

export const SuppressNotificationPortal = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { isSmartwyreUser } = useAuthentication();
  const queryResult = useNotificationsSuppressionListQuery({});

  const { update } = useNotificationSuppression();

  if (!isSmartwyreUser) {
    return null;
  }

  if (!showModal) {
    return (
      <div className={classes.closedModal}>
        <button
          data-testid="portal-toggle-button"
          className={classes.button}
          type="button"
          onClick={() => setShowModal(true)}
        >
          <UpOutlined />
        </button>
      </div>
    );
  }

  const isErrorOrLoading = queryResult.isLoading || queryResult.error || !queryResult.data?.data;

  return (
    <div className={classes.openModal}>
      <div className={classes.wrapper}>
        {isErrorOrLoading ? (
          <div className={classes.error}>
            <LoadRetry {...queryResult} />
            <DownOutlined onClick={() => setShowModal(false)} />
          </div>
        ) : (
          <>
            <div className={classes.left}>
              <ExclamationCircleOutlined style={{ fontSize: 48, margin: '1rem' }} />
              <span className={classes.text}>
                <div className={classes.header}>Suppress Notifications</div>
                <div className={classes.subtext}>
                  Having this toggle on will prevent any notifications (previously alerts) from
                  being sent to the customers based on the changes you are making.
                </div>
              </span>
            </div>
            <div className={classes.right}>
              <DownOutlined onClick={() => setShowModal(false)} />
              <div className={classes.switch}>
                <span>Suppress Notifications</span>
                <Switch
                  checked={queryResult.data?.data?.suppressCurrentUserNotifications}
                  onChange={(i: boolean) => update(i)}
                  data-testid="SuppressNotificationButton"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SuppressNotificationPortal;
