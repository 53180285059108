// Based on: https://css-tricks.com/snippets/javascript/lighten-darken-color/
//
// Use positive numbers to lighten.
// Use negative numbers to darken.
//
// Use options to override a specific colour to a specific amount.

export interface ILightenDarkenColorOptions {
  readonly override: { [color: string]: number };
}

export const lightenDarkenColor = (
  col: string,
  amt: number,
  options?: ILightenDarkenColorOptions,
) => {
  let amount = amt;

  if (options && options.override) {
    const key = Object.keys(options.override).find((k) => k.toLowerCase() === col.toLowerCase());

    if (key !== undefined) {
      amount = options.override[key];
    }
  }

  let usePound = false;

  if (col[0] === '#') {
    /**
     * @TODO - fix no-param-reassign
     */
    // eslint-disable-next-line no-param-reassign
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  // eslint-disable-next-line no-bitwise
  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  // eslint-disable-next-line no-bitwise
  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  // eslint-disable-next-line no-bitwise
  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  // eslint-disable-next-line no-bitwise
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};
