import NoPriceTooltip from '../../tooltips/NoPriceTooltip';
import { PriceExpiredTooltip } from '../../tooltips/PriceExpiredTooltip';
import { useMemo } from 'react';

import { isBeforeToday } from 'utils/dateFormats/dateFormats';
import { formatPriceWithUom } from 'utils/format/priceWithUom';

export interface ListPriceColumnTooltipProps {
  notificationType: 'InternalProgram' | 'SharedProgram';
  listPrice?: number;
  unitOfMeasure?: string;
  validTo?: Date | null;
  hasNoPrice?: boolean;
}

export default function ListPriceColumn({
  notificationType,
  listPrice,
  unitOfMeasure,
  validTo,
  hasNoPrice,
}: ListPriceColumnTooltipProps) {
  const tooltip = useMemo(() => {
    if (hasNoPrice || !listPrice || !unitOfMeasure) {
      return <NoPriceTooltip notificationType={notificationType} />;
    }
    if (validTo && isBeforeToday(validTo)) {
      return <PriceExpiredTooltip validTo={validTo} />;
    }

    return null;
  }, [hasNoPrice, listPrice, unitOfMeasure, validTo, notificationType]);

  return (
    <>
      {formatPriceWithUom(listPrice, unitOfMeasure, true)}&nbsp;
      {!!tooltip && tooltip}
    </>
  );
}
