import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import ISegmentListing from 'deprecated/Domain/ISegmentListing';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateSegment {
  execute(segmentRequest: ISegmentListing, presenter: ICreateSegmentPresenter): void;
}

export interface ICreateSegmentPresenter {
  segmentSaved(segment: ICreateSegmentResponse): void;
  segmentNotSaved(): void;
}

export interface ICreateSegmentResponse {
  id: number;
  name: string;
}

export default class CreateSegment implements ICreateSegment {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(segmentRequest: ISegmentListing, presenter: ICreateSegmentPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/segments`;

    try {
      const response: AxiosResponse<ICreateSegmentResponse> = await authenticatedAxios.post(
        url.href,
        segmentRequest,
        this.config.getAuthConfig(),
      );
      presenter.segmentSaved(response.data);
    } catch {
      presenter.segmentNotSaved();
    }
  }
}
