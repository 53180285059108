/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

import { HttpClient, RequestParams } from 'utils/axios/http-client';

import {
  ApiResponseQueriesGetNotificationGetNotificationQuery,
  FrameworkWebResponsesApiResponse,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsDetail
   * @request GET:/api/notifications/{notificationId}
   */
  notificationsDetail = async (variables: {
    notificationId: number;
    requestParams?: RequestParams;
  }) => {
    const { notificationId } = variables;
    const { data } = await this.request<
      ApiResponseQueriesGetNotificationGetNotificationQuery,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications/${notificationId}`,
      method: 'GET',
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const notificationsDetailQueryCacheKey = (variables: {
  notificationId: number;
  requestParams?: RequestParams;
}) => {
  const { notificationId } = variables;
  return ['GET', `/api/notifications/${notificationId}`, variables];
};

/**
  Query Hooks
 */

export const useNotificationsDetailQuery = (
  variables: { notificationId: number; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseQueriesGetNotificationGetNotificationQuery,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    notificationsDetailQueryCacheKey(variables),
    () => new Api().notificationsDetail(variables),
    options,
  );
};

/**
  Mutation Hooks
 */

export const useNotificationsDetailMutation = (
  options?: Omit<
    UseMutationOptions<
      ApiResponseQueriesGetNotificationGetNotificationQuery,
      AxiosError<FrameworkWebResponsesApiResponse>,
      { notificationId: number; requestParams?: RequestParams },
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(new Api().notificationsDetail, options);
};
