/* eslint-disable */
/* tslint:disable */


export enum NotificationTypeApplicationEnum {
  InternalPriceList = 'InternalPriceList',
  SharedPriceList = 'SharedPriceList',
  InternalProgram = 'InternalProgram',
  SharedProgram = 'SharedProgram',
  AccommodationRequest = 'AccommodationRequest',
  AccommodationRequestManager = 'AccommodationRequestManager',
  AccommodationRequestApproved = 'AccommodationRequestApproved',
  AccommodationRequestDenied = 'AccommodationRequestDenied',
  ExpectedGuidance = 'ExpectedGuidance',
}


export interface ApiResponseQueriesGetNotificationsGetNotificationsQueryResponseNotifications {
  status: string | null;
  succeeded: boolean;
  /** @format date-time */
  dateTimeUtc: string;
  errors: FrameworkApplicationResponsesApplicationMessage[] | null;
  messages: FrameworkApplicationResponsesApplicationMessage[] | null;
  data: QueriesGetNotificationsGetNotificationsQueryResponse | null;
}


export interface FrameworkWebResponsesApiResponse {
  status: string | null;
  succeeded: boolean;
  /** @format date-time */
  dateTimeUtc: string;
  errors: FrameworkApplicationResponsesApplicationMessage[] | null;
  messages: FrameworkApplicationResponsesApplicationMessage[] | null;
}


export interface FrameworkApplicationResponsesApplicationMessage {
  key: string | null;
  description: string | null;
}


export interface QueriesGetNotificationsGetNotificationsQueryResponse {
  notifications: QueriesGetNotificationsGetNotificationsQueryNotification[] | null;
  /** @format int32 */
  totalCount: number;
}


export interface QueriesGetNotificationsGetNotificationsQueryNotification {
  /** @format int32 */
  id: number;
  notificationType: NotificationTypeApplicationEnum;
  title: string | null;
  subtitle: string | null;
  tags: string[] | null;
  /** @format date-time */
  raisedAt: string;
  /** @format date-time */
  resolvedAt: string | null;
  /** @format date-time */
  readAt: string | null;
}


export interface NotificationsListParams {
  /** @format int32 */
  offset?: number;
  /** @format int32 */
  limit: number;
  isRead?: boolean;
  isResolved?: boolean;
  notificationType?: NotificationTypeApplicationEnum;
}

