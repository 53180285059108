import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeletePriceRuleExpectedNetCostInclusion {
  execute(priceRuleId: number, reasonForChange: string): Promise<void>;
}

export class DeletePriceRuleExpectedNetCostInclusion
  extends RemoteUseCaseBase
  implements IDeletePriceRuleExpectedNetCostInclusion
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/price-rules', orgNodeCode, config);
  }

  public async execute(priceRuleId: number, reasonForChange: string): Promise<void> {
    const response = await axios.delete<void>(
      `${this.url.href}/${priceRuleId}/expected-net-cost-inclusion`,
      {
        ...this.config.getAuthConfig(),
        data: {
          reasonForChange,
        },
      },
    );
    return response.data;
  }
}
