import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { UploadFile } from 'antd/lib/upload/interface';

import { IApiUploadResponse, isApiUploadResponseError } from 'deprecated/Gateways/UploadGateway';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUploadPriceListCsv {
  execute(
    presenter: IUploadPriceListCsvPresenter,
    formData: FormData,
    csv: UploadFile,
  ): Promise<void>;
}

export interface IUploadPriceListCsvPresenter {
  uploadResult(result: IApiUploadResponse): void;
  startUpload(): void;
}

const contentType = 'multipart/form-data';

export default class UploadPriceListCsv implements IUploadPriceListCsv {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(
    presenter: IUploadPriceListCsvPresenter,
    formData: FormData,
    csv: UploadFile,
  ): Promise<void> {
    const url = new URL(this.host.href);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData.append('upload', csv as any);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/sellerPriceLists/import`;
    url.searchParams.set('format', 'csv');

    const requestConfig = this.config.getAuthConfig();
    requestConfig.transformRequest = () => formData;

    if (!requestConfig.headers) {
      throw new Error('Authorization headers not available while trying to upload price list CSV');
    }

    requestConfig.headers['content-type'] = contentType;

    try {
      const response = await axios.post(url.href, formData, requestConfig);
      const { results, errorHeadline, invalidHeaders, fileLevelError, hasFileLevelError } =
        response.data;

      const uploadResponse = {
        results,
        success: true,
        errorHeadline,
        invalidHeaders,
        fileLevelError,
        hasFileLevelError,
      };

      presenter.uploadResult(uploadResponse);
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.status !== 200 &&
        isApiUploadResponseError(error.response)
      ) {
        const uploadResponse: IApiUploadResponse = {
          errorHeadline: error.response.errorHeadline,
          results: [
            {
              rowNumber: 0,
              errors: ['CSV_UPLOAD_ERROR'],
              status: 'Not Created',
              errorsWithColumnInfo: [],
            },
          ],
          invalidHeaders: error.response.invalidHeaders,
          fileLevelError: error.response.fileLevelError,
          hasFileLevelError: error.response.hasFileLevelError,
          success: false,
        };
        presenter.uploadResult(uploadResponse);
      } else {
        throw error;
      }
    }
  }
}
