import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { PriceRulePriceList } from 'deprecated/Domain/IPriceRulePriceListType';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetPriceRulePriceListTypes {
  execute(): Promise<PriceRulePriceList[]>;
}

export default class GetPriceRulePriceListTypes implements IGetPriceRulePriceListTypes {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(): Promise<PriceRulePriceList[]> {
    const url = new URL(this.hostname);
    url.pathname = '/api/system/referenceData/priceRulePriceListTypes';

    const response = await axios.get(url.href, this.config.getAuthConfig());

    return response.data;
  }
}
