import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IUpdatePackageConfiguration } from 'deprecated/Boundary/GetProductDetails';
import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPackageConfigurationUpdater {
  execute: (productId: number, packageConfiguration: IUpdatePackageConfiguration) => Promise<void>;
}

export default class UpdatePackageConfiguration implements IPackageConfigurationUpdater {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (productId: number, packageConfiguration: IUpdatePackageConfiguration) => {
    const url = new URL(this.hostname);
    url.pathname = `/api/canonicalproduct/${productId}/package-configuration/${packageConfiguration.id}`;
    await axios.patch(url.href, packageConfiguration, this.config.getAuthConfig());
  };
}
