/* eslint-disable class-methods-use-this */
import listify from 'listify';

import {
  ErrorWithColumnInfo,
  IApiUploadResponse,
  IApiUploadResponseErrors,
} from 'deprecated/Gateways/UploadGateway';

export interface IUploadMessageResponse {
  headlineMessage: string;
  errors: string[];
}

export interface IUploadMessage {
  execute(response: IApiUploadResponse): IUploadMessageResponse;
}

type ErrorMapping = [string, string];

type ColumnErrorPair = {
  columnName: string;
  error: string;
};

const GENERAL_ERROR_MESSAGE_HEADLINE = 'No rows were imported';

const COLUMN_TOKEN = '%COLUMN%'; // the column name (from the API)
const VALUE_TOKEN = '%VALUES_COUNT%'; // "value" or "header", will be pluralized if applicable
const AUXILIARY_TOKEN = '%AUX%'; // "is" or "are"

const COLUMN_IMPORT_ERROR_MAPPINGS: ErrorMapping[] = [
  ['MISSING_VALUE', `Missing value for ${COLUMN_TOKEN}`],
  ['INVALID_VALUE', `Invalid value "${VALUE_TOKEN}" for ${COLUMN_TOKEN}`],
  [
    'UNSUPPORTED_VALUE',
    `The value "${VALUE_TOKEN}" in ${COLUMN_TOKEN} ${AUXILIARY_TOKEN} not supported`,
  ],
  ['INTERNAL_REFERENCE_IDENTIFIER_IN_USE', `The identifier for ${COLUMN_TOKEN} is already in use`],
];

const ROW_IMPORT_ERROR_MAPPINGS: ErrorMapping[] = [
  ['INVALID_UOM_COMPARISON', 'UOM not validated: cannot convert to redemption price UOM'],
  [
    'INVALID_MINIMUMORDERQTY_UOM_COMPARISON',
    'Minimum Order Qty UOM not validated: cannot convert to redemption price UOM',
  ],
  ['UOM_REDEMPTION_PRICE_MISSING', 'UOM not validated: Product is missing from redemption price'],
  [
    'PRICE_TOLERANCE_REDEMPTION_PRICE_MISSING',
    'Price tolerance not validated: Product is missing from redemption price',
  ],
  ['INVALID_PRICE_TOLERANCE', 'Price exceeds the tolerance set for this organization'],
  ['INVALID_MANUFACTURER', 'Manufacturer does not exist'],
  ['INVALID_PRODUCT_OWNER', 'Product Owner does not exist'],
  [
    'NOT_AUTHORIZED_AS_THE_PRODUCT_OWNER',
    'You must be the product owner to update the packaging configuration',
  ],
  ['NO_PACKAGE_CONFIG_LOADED', 'No package config loaded'],
  ['INVALID_PRICE', 'Invalid Price'],
  ['INVALID_ORDERING_ORGANISATION', 'Ordering organization does not share prices with you'],
  ['INVALID_DUE_DATE', 'Invalid Due Date format'],
  ['INVALID_NET_DAYS', 'Invalid Net Days format'],
  ['INVALID_DAY_OF_MONTH', 'Invalid Day Of Month format'],
  ['INVALID_PAYMENT_TERM_TYPE', 'Invalid payment term type'],
  ['INVALID_SKU', 'Custom Identifier already exists'],
  ['ORIGINAL_PRODUCT_NOT_FOUND', 'Original Product not found'],
  [
    'PRODUCT_NOT_FOUND_OR_REPACK',
    'No product exists with the specified product identifier, or the product is a repack',
  ],
  ['PRODUCT_NOT_FOUND', 'No product was found that matches the product identifier'],
  [
    'REPACK_NOT_FOUND',
    'No repackaged product was found with the specified Original Manufacturer and Product Identifier',
  ],
  ['MISSING_PACKAGE_SIZE_LEVEL', 'Missing Package Size Level'],
  ['MISSING_MANUFACTURER', 'Missing Manufacturer'],
  ['INVALID_ORIGINAL_MANUFACTURER', 'Could not find the specified Original Manufacturer'],
  ['DUPLICATE_PRODUCT', 'Duplicate product ignored'],
  [
    'DUPLICATE_PRICES_ARE_NOT_EQUIVALENT',
    'Duplicate prices that are not equivalent to each other were rejected',
  ],
  [
    'WRONG_MANUFACTURER_ID_FOR_REPACKED_PRODUCT',
    'Wrong manufacturer for repacked product was corrected',
  ],
  ['FEATURE_LABEL_NOT_FOUND', 'Feature label could not be found'],
  ['DUPLICATE_PACKAGE_CONFIGURATION', 'Cannot upload duplicate package configurations'],
  ['PRODUCT_REPACK_ALREADY_EXISTS', 'A product with the product identifier already exists.'],
  ['ERROR_ADDING_PACKAGE_CONFIGURATION', 'Error adding package configuration'],
  ['PACKAGE_SIZE_LEVEL_REQUIRED', 'Package size level is required if the product is a repack'],
  ['INVALID_FEATURE_LABEL', 'Feature label value is invalid'],
  [
    'INVALID_RAW_PRODUCT_INFO',
    "Smallest Quantity, Smallest UOM ANSI, and Smallest UOM UN must match if they're for the same product",
  ],
  [
    'IDENTIFIERS_CANNOT_BE_THE_SAME',
    'The identifier, secondary identifier, and tertiary identifier must all be unique',
  ],
  ['LOCATIONS_ROW_DUPLICATE_NAME', 'Location Names should be unique throughout the CSV'],
  ['LOCATIONS_ROW_DUPLICATE_GLN', 'Location GLNs should be unique throughout the CSV'],
  [
    'LOCATIONS_ROW_DUPLICATE_MAIN_HEADQUARTERS',
    'Only 1 location can be specified as Main Headquarters throughout the CSV',
  ],
  ['LOCATIONS_EXISTING_DUPLICATE_NAME', 'Location with specified name already exists'],
  ['LOCATIONS_EXISTING_DUPLICATE_GLN', 'Location with specified GLN already exists'],
  ['LOCATIONS_EXISTING_DUPLICATE_MAIN_HEADQUARTERS', 'Main Headquarters Location already exists'],
  ['UNIT_OF_MEASURE_NOT_SUPPORTED', 'Specified Smallest UOM ANSI is not supported'],
  ['PRICE_HAS_NO_PRODUCT', 'The price could not be matched to a product'],
  [
    'PRICE_MUST_BE_GREATER_THAN_ZERO',
    'Price must be greater than zero. If no price is applicable then "N/A" should be used as input',
  ],
  ['INVALID_PRODUCT_TYPE_UOM', 'UOM is not compatible for this product type'],
  [
    'INVALID_PRODUCT_TYPE_MINIMUMORDERQTY_UOM',
    'Minimum Order Qty UOM is not compatible for this product type',
  ],
  [
    'LOCATIONS_ROW_DUPLICATE_CUSTOM_LOCATION_IDENTIFIER',
    'Custom Location Identifiers should be unique throughout the CSV',
  ],
];

const FILE_IMPORT_ERROR_MAPPINGS: ErrorMapping[] = [
  ['INVALID_HEADERS', `The following ${VALUE_TOKEN} ${AUXILIARY_TOKEN} missing`],
  [
    'REDEMPTION_PRICE_LIST_ALREADY_EXISTS',
    'Redemption price list for selected year already exists',
  ],
  ['NO_HEADER_FOUND', 'No header found in file'],
];

const ERROR_MESSAGE_MAPPINGS: ErrorMapping[] = [
  ...COLUMN_IMPORT_ERROR_MAPPINGS,
  ...ROW_IMPORT_ERROR_MAPPINGS,
  ...FILE_IMPORT_ERROR_MAPPINGS,
];

export default class UploadMessage implements IUploadMessage {
  public execute(response: IApiUploadResponse) {
    const { success, hasFileLevelError, results, errorHeadline } = response;
    const errors: string[] = [];

    if (!success) {
      errors.push('There was an issue uploading the file.');
    }

    if (hasFileLevelError) {
      return this.getFileLevelError(response);
    }

    errors.push(...this.getColumnErrors(results));
    errors.push(...this.getRowErrors(results));

    const rowsWithAnyError = results.filter(
      (element: IApiUploadResponseErrors) =>
        (element.errors && element.errors.length > 0) ||
        (element.errorsWithColumnInfo && element.errorsWithColumnInfo.length > 0),
    );

    if (errors.length === 0 && rowsWithAnyError.length > 0) {
      errors.push(...this.constructErrorMessages(rowsWithAnyError));
    }

    const createdRows = results.filter((r) => r.status === 'Created');
    const importedRows = results.filter((r) => r.status === 'Updated');
    const createdWithWarningRows = results.filter((r) => r.status === 'Created With Warnings');

    return {
      errors,
      headlineMessage: this.constructHeadlineMessage(
        createdRows.length + createdWithWarningRows.length,
        importedRows.length,
        createdWithWarningRows.length > 0,
        errorHeadline,
      ),
    };
  }

  private getFileLevelError(response: IApiUploadResponse) {
    const mapping = FILE_IMPORT_ERROR_MAPPINGS.find((e) => response.fileLevelError === e[0]);
    const errors = [];

    if (mapping === undefined) {
      errors.push('An unspecified error happened.');
      return {
        errors,
        headlineMessage: response.errorHeadline ?? GENERAL_ERROR_MESSAGE_HEADLINE,
      };
    }

    const [apiError, description] = mapping;

    if (apiError === 'INVALID_HEADERS') {
      let modifiedDescription = this.replaceValues(
        response.invalidHeaders.length,
        description,
        'header',
      );
      modifiedDescription += `: ${listify(response.invalidHeaders, {
        separator: ', ',
        finalWord: 'and',
      })}`;
      errors.push(modifiedDescription);
    } else {
      errors.push(description);
    }

    return { errors, headlineMessage: response.errorHeadline ?? GENERAL_ERROR_MESSAGE_HEADLINE };
  }

  private constructErrorMessages(rowsWithError: IApiUploadResponseErrors[]): string[] {
    const messages = [];
    for (const { rowNumber, errors, errorsWithColumnInfo } of rowsWithError) {
      const hasErrors = errors.length > 0;
      const hasErrorsWithColumnInfo = errorsWithColumnInfo.length > 0;

      if (hasErrors) {
        const errorsMessage = listify(errors.map(this.getErrorMessageDescription), {
          separator: ', ',
          finalWord: 'and',
        });

        messages.push(`${errorsMessage} on row ${rowNumber}.`);
      }

      if (hasErrorsWithColumnInfo) {
        const mappedErrorsWithColumnInfo = errorsWithColumnInfo.map(
          ({ error, columnName, value }: ErrorWithColumnInfo) => {
            const errorMessage = this.getErrorMessageDescription(error);
            const parsedValue = value != null ? value : '';
            return errorMessage
              .replace(COLUMN_TOKEN, columnName)
              .replace(VALUE_TOKEN, parsedValue)
              .replace(AUXILIARY_TOKEN, 'is');
          },
        );

        const errorsWithColumnInfoMessage = listify(mappedErrorsWithColumnInfo, {
          separator: ', ',
          finalWord: 'and',
        });
        messages.push(`${errorsWithColumnInfoMessage} on row ${rowNumber}.`);
      }
    }

    return messages;
  }

  private constructHeadlineMessage(
    numberOfSuccessfullyImportedRows: number,
    numberOfUpdatedRows: number,
    importHasWarnings: boolean,
    errorHeadline: string | null,
  ) {
    let imported = '';
    let updated = '';
    let rowsCreated = true;
    let rowsUpdated = true;

    if (numberOfSuccessfullyImportedRows <= 0) {
      imported = GENERAL_ERROR_MESSAGE_HEADLINE;
      imported = errorHeadline ? `${imported}. ${errorHeadline}` : imported;
      rowsCreated = false;
    } else if (numberOfSuccessfullyImportedRows === 1) {
      imported = importHasWarnings
        ? '1 row was imported with warnings'
        : 'Successfully imported 1 row';
    } else {
      imported = importHasWarnings
        ? `${numberOfSuccessfullyImportedRows} rows were imported with warnings`
        : `Successfully imported ${numberOfSuccessfullyImportedRows} rows`;
    }

    if (numberOfUpdatedRows <= 0) {
      updated = 'No rows were updated';
      updated = errorHeadline ? `${updated}. ${errorHeadline}` : updated;
      rowsUpdated = false;
    } else if (numberOfUpdatedRows === 1) {
      updated = 'Successfully updated 1 row';
    } else {
      updated = `Successfully updated ${numberOfUpdatedRows} rows`;
    }

    /**
     * @TODO - refactor nested ternary
     */
    // eslint-disable-next-line no-nested-ternary
    return (!rowsCreated && !rowsUpdated) || (rowsCreated && !rowsUpdated)
      ? imported
      : rowsUpdated && !rowsCreated
      ? updated
      : `${imported},${updated}`;
  }

  private rowsWithError(results: IApiUploadResponseErrors[], expectedErrorMessage: string) {
    return results.filter(
      (element: IApiUploadResponseErrors) =>
        element.errors && element.errors.includes(expectedErrorMessage),
    );
  }

  private getColumnErrors(results: IApiUploadResponseErrors[]) {
    const allColumnErrors = results.flatMap((f) => f.errorsWithColumnInfo);
    const resultsGroupedByColumnAndError = this.groupResultsByColumnAndError(allColumnErrors);

    const errors: string[] = [];

    if (resultsGroupedByColumnAndError.size > 0) {
      resultsGroupedByColumnAndError.forEach((_, json) => {
        const columnErrorPair: ColumnErrorPair = JSON.parse(json);

        const errorMapping = COLUMN_IMPORT_ERROR_MAPPINGS.find(
          (e) => columnErrorPair.error === e[0],
        );

        if (errorMapping === undefined) {
          return;
        }

        const [apiError] = errorMapping;

        const rowsWithError = this.rowsWithErrorWithColumnName(
          results,
          apiError,
          columnErrorPair.columnName,
        );
        if (rowsWithError.length === 0) {
          return;
        }

        errors.push(...this.constructErrorMessages(rowsWithError));
      });
    }

    return errors;
  }

  private replaceValues(length: number, description: string, word: string) {
    let toReplace = description;

    if (length === 1) {
      toReplace = toReplace.replace(VALUE_TOKEN, word).replace(AUXILIARY_TOKEN, 'is');
    }
    if (length > 1) {
      toReplace = toReplace.replace(VALUE_TOKEN, `${word}s`).replace(AUXILIARY_TOKEN, 'are');
    }

    return toReplace;
  }

  private groupResultsByColumnAndError(errors: ErrorWithColumnInfo[]) {
    return errors.reduce((prev, curr) => {
      const error: ColumnErrorPair = {
        columnName: curr.columnName,
        error: curr.error,
      };
      const errorString = JSON.stringify(error);

      if (!prev.has(errorString)) {
        prev.set(errorString, [curr]);
      } else {
        prev.set(errorString, [...(prev.get(errorString) ?? []), curr]);
      }

      return prev;
    }, new Map<string, ErrorWithColumnInfo[]>());
  }

  private getRowErrors(results: IApiUploadResponseErrors[]) {
    const errors: string[] = [];

    const allErrors = results.flatMap((r) => r.errors);
    const errorMapping = ROW_IMPORT_ERROR_MAPPINGS.filter((e) => allErrors.includes(e[0]));

    for (const [apiError] of errorMapping) {
      const rowsWithError = this.rowsWithError(results, apiError);

      if (rowsWithError.length > 0) {
        errors.push(...this.constructErrorMessages(rowsWithError));
      }
    }

    return errors;
  }

  private rowsWithErrorWithColumnName(
    results: IApiUploadResponseErrors[],
    error: string,
    columnName: string,
  ) {
    return results.filter((element: IApiUploadResponseErrors) =>
      element.errorsWithColumnInfo.some(
        (e: ErrorWithColumnInfo) => e.columnName === columnName && e.error === error,
      ),
    );
  }

  private getErrorMessageDescription(apiError: string): string {
    const mapping = ERROR_MESSAGE_MAPPINGS.find((e) => apiError === e[0]);
    return mapping ? mapping[1] : apiError;
  }
}
