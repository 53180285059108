import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IProgramPayment } from 'deprecated/Domain/IProgramPayment';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetProgramPayments {
  execute(programId: string, presenter: IGetProgramPaymentsPresenter): void;
}

export interface IGetProgramPaymentsPresenter {
  onSuccess(programPayments: IProgramPayment[]): void;
  onFailure(): void;
}

export default class GetProgramPayments implements IGetProgramPayments {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(programId: string, presenter: IGetProgramPaymentsPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/programPayments/${programId}`;

    try {
      const response = await axios.get<IProgramPayment[]>(url.href, this.config.getAuthConfig());
      presenter.onSuccess(response.data);
    } catch (error) {
      presenter.onFailure();
    }
  }
}
