import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ENV_CONFIG_FRAGMENT_TYPE } from 'fragments/Config';

export function initializeAppInsights(envConfig: ENV_CONFIG_FRAGMENT_TYPE) {
  if (['production', 'staging'].includes(envConfig.node_env)) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: envConfig.ai_instrumentation_key || '',
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackEvent({ name: 'app insights is activated' });
  }
}
