import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { NetworkGroupWithOrganizations } from 'deprecated/Boundary/NetworkGroups';
import NetworkPolicyTypes from 'deprecated/Constants/NetworkPolicyTypes';
import { OrganisationListing } from 'deprecated/Domain/IOrganisation';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetNetworkGroupsAndOrganizationsByPolicy {
  execute(policyType: NetworkPolicyTypes | 'all-orgs'): Promise<INetworkGroupsAndOrganizations>;
}

export interface INetworkGroupsAndOrganizations {
  networkGroups: NetworkGroupWithOrganizations[];
  organizations: OrganisationListing[];
}

export class GetNetworkGroupsAndOrganizationsByPolicy
  extends RemoteUseCaseBase
  implements IGetNetworkGroupsAndOrganizationsByPolicy
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, `api/node/${orgNodeCode}/network-groups/organizations`, orgNodeCode, config);
  }

  public async execute(policyType: NetworkPolicyTypes): Promise<INetworkGroupsAndOrganizations> {
    const { data } = await axios.get(`${this.url.href}/${policyType}`, this.config.getAuthConfig());
    return data;
  }
}
