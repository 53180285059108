import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { GroupsQueriesGetProductGroupsGetProductGroupsQueryGroup } from '__generated__/api/products/groups/data-contracts';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IBulkUpdateProducts {
  execute(
    request: IBulkUpdateProductsRequest,
  ): Promise<ResponseResult<IBulkUpdateProductsResponse>>;
}

export interface IBulkUpdateProductsRequest {
  productIds: number[];
  productSegmentIds: number[] | null;
  featureLabelId: number | null;
  productActiveStatus: boolean | null;
  activeAttributes: BulkProductAttributes[];
  SetKnownUsersGroups?: GroupsQueriesGetProductGroupsGetProductGroupsQueryGroup[];
}

export interface IBulkUpdateProductsResponse {
  test: '';
}

export type BulkProductAttributes =
  | 'ProductActiveStatus'
  | 'ProductSegments'
  | 'FeatureLabel'
  | 'CreateProductGroupStatus'
  | 'AddProductsToProductGroup'
  | 'RemoveProductsFromProductGroup'
  | 'SetKnownUsersGroups';

export default class BulkUpdateProducts implements IBulkUpdateProducts {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    request: IBulkUpdateProductsRequest,
  ): Promise<ResponseResult<IBulkUpdateProductsResponse>> {
    const url = new URL(this.hostname);
    url.pathname = 'api/canonicalproduct/bulk-manage';

    try {
      const response = await axios.patch<IBulkUpdateProductsResponse>(
        url.href,
        request,
        this.config.getAuthConfig(),
      );

      return Result.success(response.data);
    } catch (e) {
      return Result.failed(e, 'There was an error updating the products.');
    }
  }
}
