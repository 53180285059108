import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IRecalculateForecastPlan {
  recalculateTransactions(forecastPlanId: number): Promise<boolean>;
  recalculateEarnings(forecastPlanId: number): Promise<boolean>;
}

export default class RecalculateForecastPlan implements IRecalculateForecastPlan {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async recalculateTransactions(forecastPlanId: number): Promise<boolean> {
    const url = new URL(this.hostname);
    url.pathname = `api/planning/${forecastPlanId}/recalculate`;

    try {
      await axios.post(url.href, null, this.config.getAuthConfig());
      return true;
    } catch (e) {
      return false;
    }
  }

  public async recalculateEarnings(forecastPlanId: number): Promise<boolean> {
    const url = new URL(this.hostname);
    url.pathname = `api/planning/${forecastPlanId}/recalculate-earnings`;

    try {
      await axios.post(url.href, null, this.config.getAuthConfig());
      return true;
    } catch (e) {
      return false;
    }
  }
}
