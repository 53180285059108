import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGroup } from 'deprecated/Components/SettingsList/Security/GroupManagement/GroupTypes';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGroupsService {
  all(): Promise<IGroup[]>;
  assignUser(id: number, userId: number): Promise<void>;
  unassignUser(id: number, userId: number): Promise<void>;
}

export class GroupsService implements IGroupsService {
  private readonly host: URL;

  private readonly config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(hostname);
    this.config = config;
  }

  private getBasePath(path: string): URL {
    const url = new URL(this.host.href);
    url.pathname = `api/${path}`;
    return url;
  }

  public async all(): Promise<IGroup[]> {
    const url = this.getBasePath('groups');

    return axios
      .get<IGroup[]>(url.href, this.config.getAuthConfig())
      .then((response) => response.data);
  }

  public async assignUser(groupId: number, userId: number): Promise<void> {
    const url = this.getBasePath(`groups/${groupId}/users/${userId}`);
    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async unassignUser(id: number, userId: number): Promise<void> {
    const url = this.getBasePath(`groups/${id}/users/${userId}`);
    await axios.delete(url.href, this.config.getAuthConfig());
  }
}
