/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';
import { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import {
  ContentType,
  HttpClient,
  RequestParams,
} from 'utils/axios/http-client';

import {
  ApiResponseUsersUserDefaultNodesGetUserDefaultNodesQueryResponseOrganizations,
  EndpointsUsersUpdateUserDefaultNodesBodyRequest,
  FrameworkWebResponsesApiResponse,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Users
   * @name UsersDefaultNodesList
   * @summary Returns default nodes for the user from the router nodes
   * @request GET:/api/users/default-nodes
   */
  usersDefaultNodesList = async (variables: { requestParams?: RequestParams }) => {
    const { data } = await this.request<
      ApiResponseUsersUserDefaultNodesGetUserDefaultNodesQueryResponseOrganizations,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/users/default-nodes`,
      method: 'GET',
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
  /**
   * No description
   *
   * @tags Users
   * @name UsersDefaultNodesUpdate
   * @summary updates a users default nodes
   * @request PUT:/api/users/default-nodes
   */
  usersDefaultNodesUpdate = async (variables: {
    data: EndpointsUsersUpdateUserDefaultNodesBodyRequest;
    requestParams?: RequestParams;
  }) => {
    const { data } = await this.request<
      FrameworkWebResponsesApiResponse,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/users/default-nodes`,
      method: 'PUT',
      body: variables.data,
      type: ContentType.Json,
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const usersDefaultNodesListQueryCacheKey = (variables: {
  requestParams?: RequestParams;
}) => {
  return [`api`, `users`, `default-nodes`, variables.requestParams].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useUsersDefaultNodesListQuery = (
  variables: { requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseUsersUserDefaultNodesGetUserDefaultNodesQueryResponseOrganizations,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    usersDefaultNodesListQueryCacheKey(variables),
    () => new Api().usersDefaultNodesList(variables),
    options,
  );
};

/**
  Mutation Hooks
 */

export const useUsersDefaultNodesUpdateMutation = (
  options?: Omit<
    UseMutationOptions<
      FrameworkWebResponsesApiResponse,
      AxiosError<FrameworkWebResponsesApiResponse>,
      { data: EndpointsUsersUpdateUserDefaultNodesBodyRequest; requestParams?: RequestParams },
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(new Api().usersDefaultNodesUpdate, options);
};
