import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ApiResponse from 'fragments/common/ApiResponse';

import {
  IGetOtherOrganizationDetailsPresenter,
  IGetOtherOrganizationDetails,
  IOtherOrganizationDetails,
} from 'deprecated/Boundary/GetOtherOrganizationDetails';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export default class GetOtherOrganizationDetails implements IGetOtherOrganizationDetails {
  public config: IGetTokenFromLocalStorage;

  private hostname: URL;

  constructor(hostname: string, private orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = new URL(hostname);
    this.config = config;
  }

  public async execute(presenter: IGetOtherOrganizationDetailsPresenter, otherOrgId: string) {
    const url = new URL(this.hostname.href);
    url.pathname = `api/organizations/${encodeURIComponent(otherOrgId)}/network-policies`;

    const response = await axios.get<ApiResponse<IOtherOrganizationDetails>>(
      url.href,
      this.config.getAuthConfig(),
    );

    if (response.data.data) {
      presenter.update(response.data.data);
    }
  }
}
