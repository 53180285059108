import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { UploadFile } from 'antd/lib/upload/interface';

import { IApiUploadResponse } from 'deprecated/Gateways/UploadGateway';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUploadLocationCsv {
  execute(presenter: IUploadLocationCsvPresenter, csv: UploadFile): Promise<void>;
}

export interface IUploadLocationCsvPresenter {
  uploadResult(result: IApiUploadResponse): void;
  startUpload(): void;
}

export default class UploadLocationCsv implements IUploadLocationCsv {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: IUploadLocationCsvPresenter, csv: UploadFile): Promise<void> {
    const url = new URL(this.host.href);

    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData.append('upload', csv as any);

    url.pathname = 'api/location/import';

    const requestConfig = this.config.getAuthConfig();
    requestConfig.transformRequest = () => formData;
    requestConfig.headers = {
      ...requestConfig.headers,
    };

    try {
      const response = await axios.post(url.href, formData, requestConfig);
      const { results, errorHeadline, invalidHeaders, fileLevelError, hasFileLevelError } =
        response.data;

      const uploadResponse = {
        results,
        success: true,
        errorHeadline,
        invalidHeaders,
        fileLevelError,
        hasFileLevelError,
      };

      presenter.uploadResult(uploadResponse);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status !== 200) {
        const uploadResponse: IApiUploadResponse = {
          results: [
            {
              rowNumber: 0,
              errors: ['CSV_UPLOAD_ERROR'],
              status: 'Not Created',
              errorsWithColumnInfo: [],
            },
          ],
          invalidHeaders: [],
          success: false,
          errorHeadline: 'No rows imported.',
          fileLevelError: null,
          hasFileLevelError: false,
        };

        presenter.uploadResult(uploadResponse);
      } else {
        throw error;
      }
    }
  }
}
