import React from 'react';

import { QueriesGetInternalProgramNotificationGetInternalProgramNotificationCustomersQueryCustomer } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/customers/data-contracts';

import Select from 'ui/Select';

export interface IInternalProgramNotificationCustomerSelectProps {
  selectedCustomerId?: number;
  customers: QueriesGetInternalProgramNotificationGetInternalProgramNotificationCustomersQueryCustomer[];
  onCustomerSelected: (customerId: number) => void;
}

export const InternalProgramNotificationCustomerSelect = ({
  selectedCustomerId,
  customers,
  onCustomerSelected,
}: IInternalProgramNotificationCustomerSelectProps) => {
  return (
    <div>
      <span>Choose Customer &nbsp;&nbsp;</span>
      <Select value={selectedCustomerId} defaultActiveFirstOption onChange={onCustomerSelected}>
        {customers.map((x) => (
          <Select.Option key={x.id} value={x.id}>
            {x.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default InternalProgramNotificationCustomerSelect;
