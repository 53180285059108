import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ApiResponse from 'fragments/common/ApiResponse';

import { ISuggestedProduct } from 'deprecated/Boundary/GetProductDetails';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetSuggestedProducts {
  execute(identifier: string | null): Promise<ApiResponse<ISuggestedProduct>>;
}

export class GetSuggestedProducts extends RemoteUseCaseBase implements IGetSuggestedProducts {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/transactions/product-identifiers/search', orgNodeCode, config);
  }

  public execute(identifier: string | null): Promise<ApiResponse<ISuggestedProduct>> {
    return axios
      .get<ApiResponse<ISuggestedProduct>>(
        `${this.url.href}/${identifier}`,
        this.config.getAuthConfig(),
      )
      .then((response) => {
        return response.data;
      });
  }
}
