import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import { NetworkGroupWithOrganizations } from 'deprecated/Boundary/NetworkGroups';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetNetworkGroupsWithTaggedOrganisations {
  request(): Promise<AxiosResponse<NetworkGroupWithOrganizations[]>>;
  execute(): Promise<NetworkGroupWithOrganizations[]>;
}

export default class GetNetworkGroupsWithTaggedOrganisations
  implements IGetNetworkGroupsWithTaggedOrganisations
{
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public request = () => {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${this.orgNodeCode}/network-groups/organisations`;
    return axios.get<NetworkGroupWithOrganizations[]>(url.href, this.config.getAuthConfig());
  };

  public execute = async () => {
    const response = await this.request();
    return response.data;
  };
}
