import { useOktaAuth } from '@okta/okta-react';
import Button from '@smartwyre/components/lib/Button/Button';

import classes from './LoginError.module.scss';

interface ILoginErrorProps {
  error: string | undefined;
}

export const LoginError = ({ error }: ILoginErrorProps) => {
  const { oktaAuth } = useOktaAuth();
  const errorMessage = `There was an error logging in. ${error} Please reload and try again. If this problem persists, please contact Smartwyre`;
  return (
    <div className={`${classes.wrap} ${classes.cover}`}>
      <div>
        <h3 className={classes.subHeading}>Oops!</h3>
        <h5 className={classes.heading}>{errorMessage}</h5>
        <Button className={classes.button} onClick={() => oktaAuth.signOut()} size="small">
          Try Again
        </Button>
      </div>
    </div>
  );
};
