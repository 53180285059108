import isNil from 'lodash/isNil';

export const isNullOrEmpty = (value: string | null | undefined): value is '' | null | undefined => {
  return isNil(value) || value.length === 0;
};

export const isNullOrWhitespace = (
  value: string | null | undefined,
): value is '' | ' ' | null | undefined => {
  if (isNullOrEmpty(value)) {
    return true;
  }

  return /^\s*$/i.test(value);
};
