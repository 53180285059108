import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICostListWithPriceRuleInclusion {
  costListId: string;
  priceRulesToIncludeIds: string[];
  priceRulesToExcludeIds: string[];
}

export interface IManagePriceRulesInclusionForCostListRequest {
  costLists: ICostListWithPriceRuleInclusion[];
}

export interface IManagePriceRulesInclusionForCostList {
  execute(request: IManagePriceRulesInclusionForCostListRequest): Promise<void>;
}

export class ManagePriceRulesInclusionForCostList
  extends RemoteUseCaseBase
  implements IManagePriceRulesInclusionForCostList
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, `api/node/${orgNodeCode}/cost-list/price-rule-inclusion`, orgNodeCode, config);
  }

  public async execute(request: IManagePriceRulesInclusionForCostListRequest): Promise<void> {
    return axios.post(this.url.href, request, this.config.getAuthConfig());
  }
}
