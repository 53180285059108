import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ILocationWithFipsCode } from 'deprecated/Domain/ILocation';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetLocationsByOrganization {
  execute(presenter: IGetLocationsByOrganizationPresenter): Promise<void>;
}

export interface IGetLocationsByOrganizationPresenter {
  presentOrganizationLocations(locations: ILocationWithFipsCode[] | null): void;
}

export default class GetLocationsByOrganization implements IGetLocationsByOrganization {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(presenter: IGetLocationsByOrganizationPresenter) {
    const url = new URL(this.hostname);
    url.pathname = '/api/location/by-organization';

    try {
      const response = await axios.get<ILocationWithFipsCode[]>(
        url.href,
        this.config.getAuthConfig(),
      );
      presenter.presentOrganizationLocations(response.data);
    } catch (error) {
      presenter.presentOrganizationLocations(null);
    }
  }
}
