import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IDefinedVolumeFormula from 'deprecated/Domain/IDefinedVolumeFormula';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUpdateDefinedVolumeFormula {
  execute(id: number, values: IDefinedVolumeFormula): Promise<void>;
}

export class UpdateDefinedVolumeFormula
  extends RemoteUseCaseBase
  implements IUpdateDefinedVolumeFormula
{
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/defined-volume-formulas', undefined, config);
  }

  public async execute(id: number, values: IDefinedVolumeFormula): Promise<void> {
    await axios.patch(`${this.url.href}/${id}`, values, this.config.getAuthConfig());
  }
}
