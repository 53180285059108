import { useNavigate, useParams } from 'react-router-dom';

import NotificationModal from 'components/Notifications/components/NotificationModal/NotificationModal';

export const NotificationModalPortal = () => {
  const params = useParams();
  const navigate = useNavigate();
  const pathname = params['*'] || '';

  // if we are not on the dashboard or using the new ui, do not show anything
  if (!pathname.includes('dashboard/notifications/')) {
    return null;
  }

  const notificationId = pathname.split('/')[2];
  return (
    <div>
      {!!notificationId && (
        <NotificationModal
          notificationId={parseInt(notificationId, 10)}
          onClose={() => {
            navigate({
              pathname: '.',
              search: window.location.search,
            });
          }}
        />
      )}
    </div>
  );
};

export default NotificationModalPortal;
