import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ENV_CONFIG_FRAGMENT_TYPE } from 'fragments/Config';

export function initializeSentry(envConfig: ENV_CONFIG_FRAGMENT_TYPE) {
  try {
    Sentry.init({
      dsn: envConfig.sentryDsn,
      environment: envConfig.env_name,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      tracesSampleRate: envConfig.sentryTracesSampleRate,
      ignoreErrors: [
        'ResizeObserver loop completed with undelivered notifications.',
        /^Request failed with status code (\d*)$/,
        'Network Error',
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],
    });
  } catch (error) {
    console.error({ message: `Unable to load Sentry: ${error}` });
  }
}
