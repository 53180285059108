import { IFilterValuesWatcher } from 'deprecated/Boundary/FilterValues/IFilterValuesWatcher';
import IParameterStore from 'deprecated/Gateways/ProductsQueryGateway/IParameterStore';
import { ParamaterType } from 'deprecated/Utilities/paramaterTypes';

import IApplyFilter from './IApplyFilter';

export default class ApplyFilter implements IApplyFilter {
  private parameterStore: IParameterStore;

  private filterValuesWatcher: IFilterValuesWatcher;

  constructor(parameterStore: IParameterStore, filterValuesWatcher: IFilterValuesWatcher) {
    this.parameterStore = parameterStore;
    this.filterValuesWatcher = filterValuesWatcher;
  }

  public execute = (filterName: string, filterValue: ParamaterType) => {
    this.parameterStore.set(filterName, filterValue);
    const parameters = this.parameterStore.getParameters();
    this.filterValuesWatcher.filterValues(parameters);
  };
}
