import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ApiResponse from 'fragments/common/ApiResponse';

import { ISuggestedOrganization } from 'deprecated/Domain/ISuggestedOrganization';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetHeadquartersMatchesByAlias {
  execute(
    aliases: string[],
    searchType: TransactionsSearchTransactionSearchTypeApplicationEnum,
  ): Promise<ApiResponse<ISuggestedOrganization>>;
}

export enum TransactionsSearchTransactionSearchTypeApplicationEnum {
  Standard = 'Standard',
  Dts = 'Dts',
}

export class GetHeadquartersMatchesByAlias
  extends RemoteUseCaseBase
  implements IGetHeadquartersMatchesByAlias
{
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/transactions/organization-identifiers/search', undefined, config);
  }

  public execute(
    aliases: string[],
    searchType: TransactionsSearchTransactionSearchTypeApplicationEnum,
  ): Promise<ApiResponse<ISuggestedOrganization>> {
    return axios
      .get<ApiResponse<ISuggestedOrganization>>(
        `${this.url.href}/${searchType}/${aliases}`,
        this.config.getAuthConfig(),
      )
      .then((response) => {
        return response.data;
      });
  }
}
