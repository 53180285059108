import {
  IOrgNodeGateway,
  IGetOrganisationDataPresenter,
  IGetOrganisationData,
} from 'deprecated/Boundary/OrgNodeGateway';

export default class GetOrganisationData implements IGetOrganisationData {
  private readonly gateway: IOrgNodeGateway;

  constructor(gateway: IOrgNodeGateway) {
    this.gateway = gateway;
  }

  public async execute(presenter: IGetOrganisationDataPresenter, code: string) {
    const response = await this.gateway.getOrganisationData(code);
    presenter.organisationData(response);
  }
}
