/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

import { ContentType, HttpClient, RequestParams } from 'utils/axios/http-client';

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';

import {
  ApiResponseQueriesGetUserNotificationSuppressionGetUserNotificationSuppressionQuery,
  EndpointsNotificationsSuppressionUpdateSuppressAllNotificationsForCurrentUserBodyRequest,
  FrameworkWebResponsesApiResponse,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsSuppressionList
   * @request GET:/api/notifications/suppression
   */
  notificationsSuppressionList = async (variables: { requestParams?: RequestParams }) => {
    const { data } = await this.request<
      ApiResponseQueriesGetUserNotificationSuppressionGetUserNotificationSuppressionQuery,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications/suppression`,
      method: 'GET',
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsSuppressionUpdate
   * @request PUT:/api/notifications/suppression
   */
  notificationsSuppressionUpdate = async (variables: {
    body: EndpointsNotificationsSuppressionUpdateSuppressAllNotificationsForCurrentUserBodyRequest;
    requestParams?: RequestParams;
  }) => {
    const { data } = await this.request<
      FrameworkWebResponsesApiResponse,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications/suppression`,
      method: 'PUT',
      body: variables.body,
      type: ContentType.Json,
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const notificationsSuppressionListQueryCacheKey = (variables: {
  requestParams?: RequestParams;
}) => {
  return [`/api/notifications/suppression`, variables.requestParams].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useNotificationsSuppressionListQuery = (
  variables: { requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseQueriesGetUserNotificationSuppressionGetUserNotificationSuppressionQuery,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    notificationsSuppressionListQueryCacheKey(variables),
    () => new Api().notificationsSuppressionList(variables),
    options,
  );
};

/**
  Mutation Hooks
 */

export const useNotificationsSuppressionUpdateMutation = (
  options?: Omit<
    UseMutationOptions<
      FrameworkWebResponsesApiResponse,
      AxiosError<FrameworkWebResponsesApiResponse>,
      {
        body: EndpointsNotificationsSuppressionUpdateSuppressAllNotificationsForCurrentUserBodyRequest;
        requestParams?: RequestParams;
      },
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(new Api().notificationsSuppressionUpdate, options);
};
