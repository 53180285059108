import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';

import ApiResponse from 'fragments/common/ApiResponse';

import { determineOrgNodeCode } from 'utils/organizations/determineOrgNodeCode';

import { IdNamePair } from 'deprecated/Domain/IdNamePair';

export type GetProductFiltersFilters = {
  productTypes: IdNamePair<number>[];
  segments: IdNamePair<number>[];
  brandFamilies: IdNamePair<number>[];
  productOwners: IdNamePair<number>[];
  productSubTypes: IdNamePair<string>[];
  activeIngredients: IdNamePair<string>[];
  crops: IdNamePair<string>[];
  pests: IdNamePair<string>[];
  modesOfAction: IdNamePair<string>[];
  featureLabels: IdNamePair<number>[];
  productGroups: IdNamePair<number>[];
};

export type GetProductFiltersResponse = ApiResponse<{
  filters: GetProductFiltersFilters | undefined;
}>;

export async function getProductFilters() {
  const orgNodeCode = determineOrgNodeCode();
  const response = await authenticatedAxios.get<GetProductFiltersResponse>(
    `/api/node/${orgNodeCode}/products/filters`,
  );
  return response.data;
}
