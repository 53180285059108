import { INotificationContent } from '../../../../NotificationContent';
import ViewAsNetsToggle from '../ViewAsNetsToggle';
import React, { useState } from 'react';

import { QueriesGetInternalProgramNotificationGetInternalProgramNotificationCustomersQueryCustomer } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/customers/data-contracts';
import { NotificationsInternalProgramNotificationsDetailParams } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/data-contracts';

import Search from 'ui/Search';

import styles from './InternalProgramNotification.module.scss';

import InternalProgramNotificationCustomerSelect from './components/InternalProgramNotificationCustomerSelect/InternalProgramNotificationCustomerSelect';
import InternalProgramNotificationTableWrapper from './components/InternalProgramNotificationTable/InternalProgramNotificationTableWrapper';

export interface IInternalProgramNotificationProps extends INotificationContent {
  queryParams: NotificationsInternalProgramNotificationsDetailParams;
  customers: QueriesGetInternalProgramNotificationGetInternalProgramNotificationCustomersQueryCustomer[];
  onCustomerSelected: (customerId: number) => void;
  onPaginate: (offset: number, limit: number) => void;
  onSearch: (search: string) => void;
}

export default function InternalProgramNotification({
  notification,
  queryParams,
  customers,
  onCustomerSelected,
  onPaginate,
  onSearch,
}: IInternalProgramNotificationProps) {
  const [viewAsNets, setViewAsNets] = useState<boolean>(false);

  return (
    <div>
      <div className={styles.controls}>
        <div className={styles.controls}>
          <ViewAsNetsToggle viewAsNets={viewAsNets} setViewAsNets={setViewAsNets} />
          <InternalProgramNotificationCustomerSelect
            selectedCustomerId={queryParams.organizationId}
            customers={customers}
            onCustomerSelected={onCustomerSelected}
          />
        </div>
        <div>
          <Search onSearch={onSearch} onBlur={(e) => onSearch(e.target.value)} />
        </div>
      </div>
      <InternalProgramNotificationTableWrapper
        notification={notification}
        queryParams={queryParams}
        viewAsNets={viewAsNets}
        onPaginate={onPaginate}
      />
    </div>
  );
}
