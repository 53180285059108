import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ICostListInclusion } from 'deprecated/Domain/PriceRule/IPriceRuleResponse';
import { IPriceRuleWithCostListInclusions } from 'deprecated/RemoteUseCases/GetPriceRulesWithCostListInclusion';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPriceRulesCostListInclusions {
  execute(priceRuleId: string, costListInclusions: ICostListInclusion[]): Promise<void>;
}

export default class PriceRulesCostListInclusions implements IPriceRulesCostListInclusions {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  public constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    priceRuleId: string,
    costListInclusions: ICostListInclusion[],
  ): Promise<void> {
    const url = new URL(this.hostname);
    url.pathname = `/api/price-rules/${priceRuleId}/cost-list-inclusion`;

    await axios.post<IPriceRuleWithCostListInclusions>(
      url.href,
      { costListInclusions },
      this.config.getAuthConfig(),
    );
  }
}
