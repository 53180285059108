import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUpdatePriceRuleExpectedNetCostInclusion {
  execute(priceRuleId: number, reasonForChange: string, included: boolean | null): Promise<void>;
}

export class UpdatePriceRuleExpectedNetCostInclusion
  extends RemoteUseCaseBase
  implements IUpdatePriceRuleExpectedNetCostInclusion
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/price-rules', orgNodeCode, config);
  }

  public async execute(
    priceRuleId: number,
    reasonForChange: string,
    included: boolean | null,
  ): Promise<void> {
    const response = await axios.post<void>(
      `${this.url.href}/${priceRuleId}/expected-net-cost-inclusion`,
      { included, reasonForChange },
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
