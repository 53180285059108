import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IUpdatePackageConfiguration } from 'deprecated/Boundary/GetProductDetails';
import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreatePackageConfiguration {
  execute: (
    productId: number,
    packageConfiguration: IUpdatePackageConfiguration,
  ) => Promise<number>;
}

export default class CreatePackageConfiguration implements ICreatePackageConfiguration {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (productId: number, packageConfiguration: IUpdatePackageConfiguration) => {
    const url = new URL(this.hostname);
    url.pathname = `/api/canonicalproduct/${productId}/package-configuration`;
    const result = await axios.post<number>(
      url.href,
      packageConfiguration,
      this.config.getAuthConfig(),
    );
    return result.data;
  };
}
