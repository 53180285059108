import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ITerritory from 'deprecated/Domain/ITerritory';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

type CreateTerritoryResponse = { territoryId: number };

export interface ICreateTerritory {
  execute(territory: ITerritory): Promise<CreateTerritoryResponse | void>;
}

export default class CreateTerritory implements ICreateTerritory {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(territory: ITerritory): Promise<CreateTerritoryResponse | void> {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/territories`;
    const headers = this.config.getAuthConfig();

    const response = await axios.post<CreateTerritoryResponse>(url.href, territory, headers);

    return response.data;
  }
}
