import { IGetLocations } from 'deprecated/Boundary/GetLocations';
import { ILocationApiGateway } from 'deprecated/Boundary/LocationApiGateway';

export default class GetLocations implements IGetLocations {
  private readonly orgNodeCode: string;

  private readonly gateway: ILocationApiGateway;

  constructor(orgNodeCode: string, gateway: ILocationApiGateway) {
    this.orgNodeCode = orgNodeCode;
    this.gateway = gateway;
  }

  public async execute() {
    return this.gateway.getLocations(this.orgNodeCode);
  }
}
