import ISearchQueryStore from 'deprecated/Gateways/ProductsQueryGateway/ISearchQueryStore';

import IGetSearchQueryParameter from './IGetSearchQueryParameter';

export default class GetSearchQueryParameter implements IGetSearchQueryParameter {
  private searchQueryStore: ISearchQueryStore;

  constructor(searchQueryStore: ISearchQueryStore) {
    this.searchQueryStore = searchQueryStore;
  }

  public execute() {
    const searchQuery = this.searchQueryStore.getSearchQuery();

    if (!searchQuery || searchQuery.trim() === '') {
      return {};
    }

    return { query: searchQuery };
  }
}
