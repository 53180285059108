import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IProgramRequest } from 'deprecated/Domain/Program/IProgramRequest';
import { IProgramResponse } from 'deprecated/Domain/Program/IProgramResponse';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface ICreateProgram {
  execute(program: IProgramRequest): Promise<ResponseResult<IProgramResponse>>;
}

export default class CreateProgram implements ICreateProgram {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(program: IProgramRequest): Promise<ResponseResult<IProgramResponse>> {
    const url = new URL(this.hostname);
    url.pathname = '/api/programs';
    const headers = this.config.getAuthConfig();

    try {
      const response = await axios.post<IProgramResponse>(url.href, program, headers);
      return Result.success(response.data);
    } catch (error) {
      return Result.failed(error);
    }
  }
}
