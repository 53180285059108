import { useMemo } from 'react';

import { useEnvConfig } from 'contexts/EnvConfigContext/hooks/useEnvConfig';
import { useOrganization } from 'contexts/organizations/OrganizationContext/hooks/useOrganization';
import useTheme from 'contexts/organizations/ThemeContext/hooks/useTheme';

import { initializeServiceContainer } from 'deprecated/InitServiceContainer/utils/initializeServiceContainer';
import { ServiceProvider } from 'deprecated/Utilities/ServiceContext';

export interface IInitServiceContainerProps {
  children: React.ReactNode;
}

/**
 * @deprecated - use react query
 */
const InitServiceContainer = ({ children }: IInitServiceContainerProps) => {
  const envConfig = useEnvConfig();
  const orgNode = useOrganization();
  const theme = useTheme();

  // Service Container setup
  const serviceContainer = useMemo(() => {
    return initializeServiceContainer(envConfig, orgNode, theme);
  }, [envConfig, orgNode, theme]);

  return <ServiceProvider value={serviceContainer}>{children}</ServiceProvider>;
};

export default InitServiceContainer;
