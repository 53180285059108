import axios from 'axios';
import queryString from 'query-string';

import ApiResponse from 'fragments/common/ApiResponse';

import { RetailSaleToEndUserSaleUnlinkedCounterPartiesAlert } from 'deprecated/Boundary/GetAlerts';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAlertRetailSaleToEndUserSalesUnlinkedCounterParties {
  execute(
    id: number,
    offset: number,
    limit: number,
  ): Promise<ApiResponse<RetailSaleToEndUserSaleUnlinkedCounterPartiesAlert>>;
}

export class GetAlertRetailSaleToEndUserSalesUnlinkedCounterParties
  implements IGetAlertRetailSaleToEndUserSalesUnlinkedCounterParties
{
  private readonly hostname: string;

  private readonly config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute(
    id: number,
    offset: number,
    limit: number,
  ): Promise<ApiResponse<RetailSaleToEndUserSaleUnlinkedCounterPartiesAlert>> {
    const url = new URL(this.hostname);
    url.pathname = `api/alerts/${id}/unlinked/transactions/retail-sale-to-end-user-sales/counter-parties`;
    url.searchParams.append('offset', offset.toString());
    url.searchParams.append('limit', limit.toString());

    const urlWithQuery = queryString.stringifyUrl({
      url: url.toString(),
    });

    return axios
      .get<ApiResponse<RetailSaleToEndUserSaleUnlinkedCounterPartiesAlert>>(
        urlWithQuery,
        this.config.getAuthConfig(),
      )
      .then((response) => {
        return response.data;
      });
  }
}
