import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import Tooltip from 'ui/Tooltip/Tooltip';

export interface NoPriceTooltipProps {
  notificationType: 'InternalProgram' | 'SharedProgram';
}

export default function NoPriceTooltip({ notificationType }: NoPriceTooltipProps) {
  return (
    <Tooltip
      data-testid="NoPriceTooltip:Tooltip"
      title={
        notificationType === 'InternalProgram'
          ? 'Unable to find a price for this product in the current program year, please update your pricing'
          : 'There are no shared prices for this product'
      }
    >
      <InfoCircleOutlined data-testid="NoPriceTooltip:Icon" />
    </Tooltip>
  );
}
