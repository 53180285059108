import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IPresentActivation } from 'deprecated/RemoteUseCases/ActivateProduct';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeactivateProduct {
  execute: (productId: string, presented: IPresentActivation) => void;
}

export default class DeactivateProduct implements IDeactivateProduct {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(productId: string, presenter: IPresentActivation) {
    const url = new URL(this.hostname);
    url.pathname = `/api/canonicalProduct/${productId}/deactivate`;

    try {
      await axios.put(url.href, '', this.config.getAuthConfig());
      presenter.inactive();
    } catch {
      presenter.active();
    }
  }
}
