import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';

import { ISearchQueryParameter } from 'deprecated/UseCases/GetSearchQueryParameter/IGetSearchQueryParameter';
import { ISortingParameters } from 'deprecated/UseCases/IGetSortingParameters';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ParameterCollection } from 'deprecated/Utilities/paramaterTypes';

export type ProductsArguments = ISearchQueryParameter &
  ISortingParameters & { offset: number; filters: ParameterCollection };

export interface IProductsApiGateway {
  attachRawDataToProduct: (productId: string, rawProductData: string) => Promise<void>;
  detachRawDataFromProduct: (productId: string, rawProductData: string) => Promise<void>;
}

export default class ProductsApiGateway implements IProductsApiGateway {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async attachRawDataToProduct(productId: string, rawProductData: string): Promise<void> {
    const attachRawDataUrl = new URL(this.host.href);
    attachRawDataUrl.pathname = `api/canonicalproduct/${productId}/attach`;
    attachRawDataUrl.searchParams.set('rawId', rawProductData);

    try {
      await authenticatedAxios.patch(attachRawDataUrl.href, {}, this.config.getAuthConfig());
    } catch (error) {
      // fix ES lint warning
    }
  }

  public async detachRawDataFromProduct(productId: string, rawProductData: string): Promise<void> {
    const detachRawDataUrl = new URL(this.host.href);
    detachRawDataUrl.pathname = `api/canonicalproduct/${productId}/detach`;
    detachRawDataUrl.searchParams.set('rawId', rawProductData);

    try {
      await authenticatedAxios.patch(detachRawDataUrl.href, {}, this.config.getAuthConfig());
    } catch (error) {}
  }
}
