import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

type ProtectedRouteProps = PropsWithChildren<{
  isAllowed: boolean;
  redirectPath: string;
}>;

/**
 * Referenced from and documented https://www.robinwieruch.de/react-router-private-routes/
 */
const ProtectedRoute: FC<ProtectedRouteProps> = ({ isAllowed, redirectPath, children }) => {
  const location = useLocation();
  if (!isAllowed) {
    return (
      <Navigate
        to={redirectPath}
        state={{ from: location.pathname, query: location.search }}
        replace
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
