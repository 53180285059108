/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { HttpClient, RequestParams } from 'utils/axios/http-client';

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';

import {
  ApiResponseQueriesGetNotificationsGetNotificationsQueryResponseNotifications,
  FrameworkWebResponsesApiResponse,
  NotificationsListParams,
} from './data-contracts';


export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsList
   * @request GET:/api/notifications
   */
  notificationsList = async (variables: {
    query: NotificationsListParams;
    requestParams?: RequestParams;
  }) => {
    const { data } = await this.request<
      ApiResponseQueriesGetNotificationsGetNotificationsQueryResponseNotifications,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications`,
      method: 'GET',
      query: variables.query,
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const notificationsListQueryCacheKey = (variables: {
  query: NotificationsListParams;
  requestParams?: RequestParams;
}) => {
  return [`api`, `notifications`, variables.query, variables.requestParams].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useNotificationsListQuery = (
  variables: { query: NotificationsListParams; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseQueriesGetNotificationsGetNotificationsQueryResponseNotifications,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    notificationsListQueryCacheKey(variables),
    () => new Api().notificationsList(variables),
    options,
  );
};

/**
  Mutation Hooks
 */
