import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IBrandGateway } from 'deprecated/Boundary/BrandGateway';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export default class BrandApiGateway implements IBrandGateway {
  public config: IGetTokenFromLocalStorage;

  private readonly host: URL;

  public constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async attachRawData(brandId: string, rawDataId: string): Promise<void> {
    const attachRawDataUrl = new URL(this.host.href);
    attachRawDataUrl.pathname = `api/brand/${brandId}/datasource`;
    attachRawDataUrl.searchParams.set('dataSourceId', rawDataId);

    await axios.post(attachRawDataUrl.href, {}, this.config.getAuthConfig());
  }

  public async detachRawData(brandId: string): Promise<void> {
    const detachRawDataUrl = new URL(this.host.href);
    detachRawDataUrl.pathname = `api/brand/${brandId}/datasource`;
    await axios.delete(detachRawDataUrl.href, this.config.getAuthConfig());
  }
}
