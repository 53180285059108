/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

import { HttpClient, RequestParams } from 'utils/axios/http-client';

import {
  FrameworkWebResponsesApiResponse,
  NotificationsReadStatusUpdateParams,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsReadStatusUpdate
   * @request PUT:/api/notifications/{notificationId}/read-status
   */
  notificationsReadStatusUpdate = async (variables: {
    query: NotificationsReadStatusUpdateParams;
    requestParams?: RequestParams;
  }) => {
    const { notificationId, ...query } = variables.query;
    const { data } = await this.request<
      FrameworkWebResponsesApiResponse,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications/${notificationId}/read-status`,
      method: 'PUT',
      query: query,
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const notificationsReadStatusUpdateQueryCacheKey = (variables: {
  query: NotificationsReadStatusUpdateParams;
  requestParams?: RequestParams;
}) => {
  const { notificationId, ...query } = variables.query;
  return ['PUT', `/api/notifications/${notificationId}/read-status`, variables];
};

/**
  Query Hooks
 */

export const useNotificationsReadStatusUpdateQuery = (
  variables: { query: NotificationsReadStatusUpdateParams; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<FrameworkWebResponsesApiResponse, AxiosError<FrameworkWebResponsesApiResponse>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    notificationsReadStatusUpdateQueryCacheKey(variables),
    () => new Api().notificationsReadStatusUpdate(variables),
    options,
  );
};

/**
  Mutation Hooks
 */

export const useNotificationsReadStatusUpdateMutation = (
  options?: Omit<
    UseMutationOptions<
      FrameworkWebResponsesApiResponse,
      AxiosError<FrameworkWebResponsesApiResponse>,
      { query: NotificationsReadStatusUpdateParams; requestParams?: RequestParams },
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(new Api().notificationsReadStatusUpdate, options);
};
