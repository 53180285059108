import axios from '@smartwyre/utils/lib/authenticatedAxios';

import {
  IGetPriceRulesNames,
  IGetPriceRulesNamesPresenter,
  PriceRuleName,
} from 'deprecated/Boundary/GetPriceRulesNames';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export default class GetPriceRulesNames implements IGetPriceRulesNames {
  public config: IGetTokenFromLocalStorage;

  public host: URL;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  /**
   * @TODO - fix `consistent-return` rule
   */
  // eslint-disable-next-line consistent-return
  public async execute(presenter: IGetPriceRulesNamesPresenter, priceRulesIds: number[]) {
    const url = new URL(this.host.href);

    url.pathname = 'api/price-rules/priceRulesNames';

    priceRulesIds.forEach((x) => url.searchParams.append('priceRuleId', x.toString()));

    try {
      const response = await axios.get<{ names: PriceRuleName[] }>(
        url.href,
        this.config.getAuthConfig(),
      );

      presenter.success(response.data.names);
    } catch (error) {
      return [];
    }
  }
}
