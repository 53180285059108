/* eslint-disable class-methods-use-this */
import IGetSearchQueryParameter from '../GetSearchQueryParameter/IGetSearchQueryParameter';

import { IGetPlans, IPlanningListItem, IPresentPlanListItem } from 'deprecated/Boundary/GetPlans';
import { IPlanningApiGateway } from 'deprecated/Gateways/PlanningApiGateway';
import { IGetSortingParameters } from 'deprecated/UseCases/IGetSortingParameters';

export default class GetPlans implements IGetPlans {
  private readonly planningApiGateway: IPlanningApiGateway;

  private getSearchQueryParameter: IGetSearchQueryParameter;

  private getSortParameters: IGetSortingParameters;

  constructor(
    planningApiGateway: IPlanningApiGateway,
    getSearchQueryParameter: IGetSearchQueryParameter,
    getSortParameters: IGetSortingParameters,
  ) {
    this.planningApiGateway = planningApiGateway;
    this.getSearchQueryParameter = getSearchQueryParameter;
    this.getSortParameters = getSortParameters;
  }

  public async execute(offset: number, limit = 20) {
    const searchQueryParameter = this.getSearchQueryParameter.execute();
    const sortParameters = this.getSortParameters.execute();

    const response = await this.planningApiGateway.getPlansByQuery({
      ...searchQueryParameter,
      ...sortParameters,
      offset,
      limit,
    });

    return response.plans.map(this.toPresentablePlan);
  }

  private toPresentablePlan = (plan: IPlanningListItem): IPresentPlanListItem => {
    return {
      id: Number(plan.id),
      name: plan.name,
      identifier: plan.identifier,
      dateFrom: plan.dateFrom,
      dateTo: plan.dateTo,
      cutoverDate: plan.cutoverDate,
      type: plan.type,
      createdOn: plan.createdOn,
      reportStatus: plan.reportStatus,
      forecastPlanStatus: plan.forecastPlanStatus,
    };
  };
}
