import { ISearchPresenter, ISearchWatcher } from 'deprecated/Boundary/SearchWatcher/ISearchWatcher';

/**
 * @deprecated
 */
export default class SearchWatcher implements ISearchWatcher {
  private observers: ISearchPresenter[];

  constructor() {
    this.observers = [];
  }

  public registerObserver(observer: ISearchPresenter): void {
    this.observers.push(observer);
  }

  public unregisterObserver(presenter: ISearchPresenter): void {
    const index = this.observers.findIndex((obs) => obs === presenter);
    this.observers.splice(index, 1);
  }

  public search(searchText: string): void {
    this.observers.forEach((observer: ISearchPresenter) => {
      observer.search(searchText);
    });
  }

  public reset(): void {
    this.observers = [];
  }
}
