import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError, AxiosResponse } from 'axios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export type DocumentWithSasLink = {
  id: number;
  containerName: string;
  fileName: string;
  sasUrl: string;
};

export interface IGetAzureDownloadTokens {
  request(containerId: number | undefined): Promise<AxiosResponse<DocumentWithSasLink[]>>;
  execute(containerId: number): Promise<DocumentWithSasLink[]>;
}

export default class GetAzureDownloadToken implements IGetAzureDownloadTokens {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public request(containerId: number) {
    const url = new URL(this.hostname);
    url.pathname = `api/documents/${encodeURIComponent(containerId)}`;
    return axios.get<DocumentWithSasLink[]>(url.href, this.config.getAuthConfig());
  }

  public async execute(containerId: number) {
    try {
      const response = await this.request(containerId);
      return response.data;
    } catch (e) {
      const error = e as AxiosError<DocumentWithSasLink[]>;
      return Promise.reject(error);
    }
  }
}
