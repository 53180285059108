import { IBrandGateway } from 'deprecated/Boundary/BrandGateway';

import { IDetachRawDataFromBrand, IDetachRawDataFromBrandPresenter } from './Boundary';

export default class DetachRawDataFromBrand implements IDetachRawDataFromBrand {
  private readonly brandGateway: IBrandGateway;

  constructor(brandGateway: IBrandGateway) {
    this.brandGateway = brandGateway;
  }

  public execute(brandId: string, presenter: IDetachRawDataFromBrandPresenter) {
    this.brandGateway.detachRawData(brandId).then(presenter.done);
  }
}
