import { IGetUsers } from 'deprecated/Boundary/GetUsers';
import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class GetUsers implements IGetUsers {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute() {
    return this.gateway.getUserManagementUsers();
  }
}
