import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteGroup {
  execute(groupId: number): Promise<void>;
}

export class DeleteGroup extends RemoteUseCaseBase implements IDeleteGroup {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(groupId: number): Promise<void> {
    return axios
      .delete(`${this.url.href}/${groupId}`, this.config.getAuthConfig())
      .then(() => undefined);
  }
}
