import {
  IGetAllPackageConfigurationDataPresenter,
  IGetAllPackageConfigurationData,
} from 'deprecated/Boundary/GetAllPackageConfigurationData';
import { IPackageConfigurationDataApiGateway } from 'deprecated/Gateways/PackageConfigurationDataGateway';

export default class GetAllPackageConfigurationData implements IGetAllPackageConfigurationData {
  private readonly gateway: IPackageConfigurationDataApiGateway;

  constructor(gateway: IPackageConfigurationDataApiGateway) {
    this.gateway = gateway;
  }

  public async execute(presenter: IGetAllPackageConfigurationDataPresenter) {
    const response = await this.gateway.getPackageConfigurationData();

    presenter.packageConfigurationData(response);
  }
}
