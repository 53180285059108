import { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import notification from 'antd/lib/notification';
import { AxiosError } from 'axios';

import { SUPPORT_EMAIL } from './SystemStrings';

function isError(error: unknown): error is Error {
  return error instanceof Error;
}

function getStandardErrorMessages(axiosError: AxiosError): string[] | null {
  const axiosErrorWithErrors = axiosError as AxiosError<{ errors: string[] }>;

  if (
    axiosErrorWithErrors.response?.data.errors != null &&
    axiosErrorWithErrors.response.data.errors.every((x) => typeof x === 'string')
  ) {
    return axiosErrorWithErrors.response.data.errors;
  }
  return null;
}

/**
 * @deprecated
 */
export function getErrorMessage(error: unknown): string | null {
  if (isAxiosError(error)) {
    const standardErrorMsgs = getStandardErrorMessages(error);
    if (standardErrorMsgs) {
      return standardErrorMsgs.join(', ');
    }
    return error.message;
  }
  if (isError(error)) {
    return error.message;
  }
  return null;
}

/**
 * @deprecated
 * Notifies with an axios error
 * Taken from `https://axios-http.com/docs/handling_errors`
 */
export const handleAxiosError = <T, D>(
  error: AxiosError<T, D> | undefined | null,
  message = 'Error',
) => {
  if (!error || error.response?.status === 500) {
    notification.error({
      message,
      description: `Something went wrong, please contact ${SUPPORT_EMAIL}`,
    });

    return;
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    notification.error({
      message,
      description: `${error.response.data}`,
    });
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    notification.error({
      message,
      description: 'Something went wrong',
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    notification.error({
      message,
      description: error.message,
    });
  }
};
