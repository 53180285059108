// takes a parameter of defaultOrNode ie winfield and builds the url to send the user to
export function redirectToDefaultOrgNode(defaultOrgNode: string) {
  const host = window.location.hostname;
  const domainParts = host.split('.');
  // check if the url contains an environment ie node.sandbox.smartwyre.app
  const hasEnvSubdomain = domainParts[2] !== 'app';
  let orgRedirect = '';
  // include env if present
  if (hasEnvSubdomain) {
    orgRedirect = `${window.location.protocol}//${defaultOrgNode}.${domainParts[1]}.${domainParts[2]}.${domainParts[3]}`;
  } else {
    orgRedirect = `${window.location.protocol}//${defaultOrgNode}.${domainParts[1]}.${domainParts[2]}`;
  }
  // redirect to new node
  window.location.replace(orgRedirect);
}
