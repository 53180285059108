export function determineOrgNodeCode(hostname = window.location.hostname) {
  if (process.env.REACT_APP_OVERRIDE_ORG_NODE_CODE) {
    return process.env.REACT_APP_OVERRIDE_ORG_NODE_CODE;
  }

  if (hostname === 'localhost') {
    return 'development';
  }

  const domains = hostname.split('.');
  if (domains.length < 3) {
    throw new Error(`${hostname} does not contain a valid org node code`);
  }

  return domains[0];
}
