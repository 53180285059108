import InlineFilterWrapper from '@smartwyre/components/lib/Filters/components/InlineFilterWrapper';
import SwitchFilter from '@smartwyre/components/lib/Filters/components/SwitchFilter';

import styles from './index.module.scss';

interface IViewAsNetsToggleProps {
  viewAsNets: boolean;
  setViewAsNets: (viewAsNets: boolean) => void;
}

const ViewAsNetsToggle = ({ viewAsNets, setViewAsNets }: IViewAsNetsToggleProps) => {
  return (
    <InlineFilterWrapper label="View as Nets" className={styles.viewAsNetsToggle}>
      <SwitchFilter checked={viewAsNets} onChange={(checked) => setViewAsNets(checked)} />
    </InlineFilterWrapper>
  );
};

export default ViewAsNetsToggle;
