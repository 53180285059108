import React from 'react';

import { ICustomTheme } from 'fragments/organization/ICustomTheme';

const SMARTWYRE_DARK_BLUE = '#020046';
const SMARTWYRE_LIGHT_BLUE = '#a5d9f6';
const SMARTWYRE_WHITE = '#fff';

export const DEFAULT_THEME: ICustomTheme = {
  // settings page - customizable colors
  primaryColor: SMARTWYRE_DARK_BLUE,
  secondaryColor: SMARTWYRE_DARK_BLUE,
  tertiaryColor: SMARTWYRE_DARK_BLUE,
  headerTextColor: SMARTWYRE_WHITE,

  // settings page - customizable images
  loginBackground: '',
  headerLogo: '',
  loginLogo: '',

  // used in login page or globally
  loginTextColor: SMARTWYRE_DARK_BLUE,
  useLoginBottomGradient: false,

  // generated by `ui/src/initializeServiceContainer.tsx`
  secondaryVariantColor: SMARTWYRE_LIGHT_BLUE,
  primaryVariantColor: SMARTWYRE_LIGHT_BLUE,
  primaryButtonTextColor: SMARTWYRE_WHITE,
  tableRowHoverColor: SMARTWYRE_LIGHT_BLUE,
  tableHighlightBackgroundColor: SMARTWYRE_LIGHT_BLUE,
};

export const ThemeContext = React.createContext<ICustomTheme>(DEFAULT_THEME);
