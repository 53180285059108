import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';
import { FC } from 'react';

import { useOrganization } from 'contexts/organizations/OrganizationContext/hooks/useOrganization';

import ProtectedRoute from 'routes/ProtectedRoute/ProtectedRoute';

import DefaultNodeRoute from 'components/Routing/DefaultNodeRoute';

const AuthenticatedRoute: FC = () => {
  const { isAuthenticated } = useAuthentication();
  const organization = useOrganization();
  // show loading if isAuthenticated is undefined or is routing node as redirect is taking place
  if (isAuthenticated === undefined) {
    return <LoadRetry cover isLoading testIdPrefix="AuthenticatedRoute" />;
  }
  /* If the orgNode is a routing node (an entry point for multiple types of users from a larger org) then redirect the user to their node. */
  if (organization.isRoutingNode && isAuthenticated) {
    return <DefaultNodeRoute />;
  }

  return <ProtectedRoute isAllowed={isAuthenticated} redirectPath="/login" />;
};

export default AuthenticatedRoute;
