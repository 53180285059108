import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export type AuthToken = {
  authorizationToken: string;
  timeCreated: Date;
};

export interface IGetNewAuthenticationToken {
  execute(present: IGetNewAuthenticationTokenPresenter): void;
}

export interface IGetNewAuthenticationTokenPresenter {
  notify(success: boolean, token?: AuthToken): void;
}

export default class GetNewAuthenticationToken implements IGetNewAuthenticationToken {
  public host: string;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = host;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: IGetNewAuthenticationTokenPresenter) {
    const url = new URL(this.host);

    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/authToken/new`;

    try {
      const response = await axios.get<AuthToken>(url.href, this.config.getAuthConfig());
      presenter.notify(true, response.data);
    } catch (e) {
      presenter.notify(false, undefined);
    }
  }
}
