import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAddForecastPlanProducts {
  execute(
    forecastPlanId: number,
    addForecastPlanProductsRequest: IAddForecastPlanProductsRequest,
    presenter: IAddForecastPlanProductsPresenter,
  ): Promise<void>;
}

export interface IAddForecastPlanProductsRequest {
  productIds: number[];
}

export interface IAddForecastPlanProductsPresenter {
  forecastPlanProductsAdded(forecastPlan: IAddForecastPlanProductsResponse): void;
  forecastPlanProductsNotAdded(): void;
}

export interface IAddForecastPlanProductsResponse {
  id: string;
  errors: string[];
}

export default class AddForecastPlanProducts implements IAddForecastPlanProducts {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    forecastPlanId: number,
    forecastPlanRequest: IAddForecastPlanProductsRequest,
    presenter: IAddForecastPlanProductsPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `api/planning/${forecastPlanId}/products`;
    await axios
      .post(url.href, forecastPlanRequest, this.config.getAuthConfig())
      .then((response) => {
        presenter.forecastPlanProductsAdded(response.data);
      })
      .catch(() => {
        presenter.forecastPlanProductsNotAdded();
      });
  }
}
