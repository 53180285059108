import { determineOrgNodeCode } from '../../../utils/organizations/determineOrgNodeCode';
import ChangePassword from '../../UseCases/ChangePassword';

import { ENV_CONFIG_FRAGMENT_TYPE } from 'fragments/Config';

import { IBrandGateway } from 'deprecated/Boundary/BrandGateway';
import { IChangePassword } from 'deprecated/Boundary/ChangePassword';
import { ICreateUser } from 'deprecated/Boundary/CreateUser';
import { IDeactivateUser } from 'deprecated/Boundary/DeactivateUser';
import { IEditUser } from 'deprecated/Boundary/EditUser';
import { IEditUserProfile } from 'deprecated/Boundary/EditUserProfile';
import { IFilterValuesWatcher } from 'deprecated/Boundary/FilterValues/IFilterValuesWatcher';
import { IGetAllPackageConfigurationData } from 'deprecated/Boundary/GetAllPackageConfigurationData';
import { IGetAllRegulatoryData } from 'deprecated/Boundary/GetAllRegulatoryData';
import { IGetLocations } from 'deprecated/Boundary/GetLocations';
import { IGetOtherOrganizationDetails } from 'deprecated/Boundary/GetOtherOrganizationDetails';
import { IGetPlans } from 'deprecated/Boundary/GetPlans';
import { IGetPriceRulesNames } from 'deprecated/Boundary/GetPriceRulesNames';
import { IGetUser } from 'deprecated/Boundary/GetUser';
import { IGetUsers } from 'deprecated/Boundary/GetUsers';
import IGetOrganisation from 'deprecated/Boundary/IGetOrganisation';
import { ILocationApiGateway } from 'deprecated/Boundary/LocationApiGateway';
import { IGetOrganisationData } from 'deprecated/Boundary/OrgNodeGateway';
import { ISearchWatcher } from 'deprecated/Boundary/SearchWatcher/ISearchWatcher';
import BrandApiGateway from 'deprecated/Gateways/BrandApiGateway';
import LocationApiGateway from 'deprecated/Gateways/LocationApiGateway';
import OrgNodeGateway from 'deprecated/Gateways/OrgNodeGateway';
import OrganisationApiGateway, {
  IOrganisationApiGateway,
} from 'deprecated/Gateways/OrganisationApiGateway';
import PackageConfigurationDataApiGateway, {
  IPackageConfigurationDataApiGateway,
} from 'deprecated/Gateways/PackageConfigurationDataGateway';
import PlanningApiGateway, { IPlanningApiGateway } from 'deprecated/Gateways/PlanningApiGateway';
import ProductsApiGateway, { IProductsApiGateway } from 'deprecated/Gateways/ProductsApiGateway';
import IParameterStore from 'deprecated/Gateways/ProductsQueryGateway/IParameterStore';
import ISearchQueryStore from 'deprecated/Gateways/ProductsQueryGateway/ISearchQueryStore';
import ISortingParametersStore from 'deprecated/Gateways/ProductsQueryGateway/ISortingParametersStore';
import ProductsQueryGateway from 'deprecated/Gateways/ProductsQueryGateway/ProductsQueryGateway';
import RegulatoryDataGateway, {
  IRegulatoryDataGateway,
} from 'deprecated/Gateways/RegulatoryDataGateway';
import UploadGateway from 'deprecated/Gateways/UploadGateway';
import UserApiGateway, { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';
import ActivateProduct, { IActivateProduct } from 'deprecated/RemoteUseCases/ActivateProduct';
import AddForecastPlanProducts, {
  IAddForecastPlanProducts,
} from 'deprecated/RemoteUseCases/AddForecastPlanProducts';
import {
  AddGroupMember,
  IAddGroupMember,
} from 'deprecated/RemoteUseCases/AddGroupMember/AddGroupMember';
import { AddGroupToPolicy, IAddGroupToPolicy } from 'deprecated/RemoteUseCases/AddGroupToPolicy';
import {
  AddOrganizationAliases,
  IAddOrganizationAliases,
} from 'deprecated/RemoteUseCases/AddOrganizationAliases/AddOrganizationAliases';
import {
  AddOrganizationToPolicy,
  IAddOrganizationToPolicy,
} from 'deprecated/RemoteUseCases/AddOrganizationToPolicy';
import AddSegmentToPriceList, {
  IAddSegmentToPriceList,
} from 'deprecated/RemoteUseCases/AddSegmentToPriceList';
import AddSegmentToProgram, {
  IAddSegmentToProgram,
} from 'deprecated/RemoteUseCases/AddSegmentToProgram';
import { GetAlert, IGetAlert } from 'deprecated/RemoteUseCases/Alerts/GetAlert';
import {
  GetAlertPurchasesUnlinkedCounterParties,
  IGetAlertPurchasesUnlinkedCounterParties,
} from 'deprecated/RemoteUseCases/Alerts/GetAlertPurchasesUnlinkedCounterParties';
import {
  GetAlertRetailSaleToEndUserSalesUnlinkedCounterParties,
  IGetAlertRetailSaleToEndUserSalesUnlinkedCounterParties,
} from 'deprecated/RemoteUseCases/Alerts/GetAlertRetailSaleToEndUserSalesUnlinkedCounterParties';
import {
  GetAlertUnlinkedProducts,
  IGetAlertUnlinkedProducts,
} from 'deprecated/RemoteUseCases/Alerts/GetAlertUnlinkedProducts';
import {
  GetAlertWholesaleSalesUnlinkedCounterParties,
  IGetAlertWholesaleSalesUnlinkedCounterParties,
} from 'deprecated/RemoteUseCases/Alerts/GetAlertWholesaleSalesUnlinkedCounterParties';
import AssignTypeToOrganisation, {
  IAssignTypeToOrganisation,
} from 'deprecated/RemoteUseCases/AssignTypeToOrganisation';
import UploadFile, { IUploadFile } from 'deprecated/RemoteUseCases/AzureUploadDocument';
import UploadThemeAsset, {
  IUploadThemeAsset,
} from 'deprecated/RemoteUseCases/AzureUploadImageDocument';
import BulkUpdateProducts, {
  IBulkUpdateProducts,
} from 'deprecated/RemoteUseCases/BulkUpdateProducts';
import CopyProgram, { ICopyProgram } from 'deprecated/RemoteUseCases/CopyProgram';
import CreateAssetToken, { ICreateAssetToken } from 'deprecated/RemoteUseCases/CreateAssetToken';
import CreateCashRebatePercentage, {
  ICreateCashRebatePercentage,
} from 'deprecated/RemoteUseCases/CreateCashRebatePercentage';
import CreateForecastPlan, {
  ICreateForecastPlan,
} from 'deprecated/RemoteUseCases/CreateForecastPlan';
import { CreateGroup, ICreateGroup } from 'deprecated/RemoteUseCases/CreateGroup';
import CreateOrUpdateCustomerServiceContactPoint, {
  ICreateOrUpdateCustomerServiceContactPoint,
} from 'deprecated/RemoteUseCases/CreateOrUpdateCustomerServiceContactPoint';
import CreateOrUpdateNetworkGroup, {
  ICreateOrUpdateNetworkGroup,
} from 'deprecated/RemoteUseCases/CreateOrUpdateNetworkGroup';
import CreateOrUpdateProductFeatureLabel, {
  ICreateOrUpdateProductFeatureLabel,
} from 'deprecated/RemoteUseCases/CreateOrUpdateProductFeatureLabel';
import CreateOrUpdateProgramPayment, {
  ICreateOrUpdateProgramPayment,
} from 'deprecated/RemoteUseCases/CreateOrUpdateProgramPayment';
import CreatePackageConfiguration, {
  ICreatePackageConfiguration,
} from 'deprecated/RemoteUseCases/CreatePackageConfiguration';
import CreatePriceRule, { ICreatePriceRule } from 'deprecated/RemoteUseCases/CreatePriceRule';
import CreateProgram, { ICreateProgram } from 'deprecated/RemoteUseCases/CreateProgram';
import CreateSegment, { ICreateSegment } from 'deprecated/RemoteUseCases/CreateSegment';
import CreateTerritory, { ICreateTerritory } from 'deprecated/RemoteUseCases/CreateTerritory';
import DeactivateProduct, { IDeactivateProduct } from 'deprecated/RemoteUseCases/DeactivateProduct';
import DeleteProgram, { IDeleteProgram } from 'deprecated/RemoteUseCases/DeleteAProgram';
import DeleteCanonicalProduct, {
  IDeleteCanonicalProduct,
} from 'deprecated/RemoteUseCases/DeleteCanonicalProduct';
import { DeleteGroup, IDeleteGroup } from 'deprecated/RemoteUseCases/DeleteGroup/DeleteGroup';
import DeleteImageAsset, { IDeleteImageAsset } from 'deprecated/RemoteUseCases/DeleteImageAsset';
import DeleteNetworkGroup, {
  IDeleteNetworkGroup,
} from 'deprecated/RemoteUseCases/DeleteNetworkGroup';
import {
  DeleteOrganizationAlias,
  IDeleteOrganizationAlias,
} from 'deprecated/RemoteUseCases/DeleteOrganizationAlias/DeleteOrganizationAlias';
import DeletePackageConfiguration, {
  IDeletePackageConfiguration,
} from 'deprecated/RemoteUseCases/DeletePackageConfiguration';
import DeletePlan, { IDeletePlan } from 'deprecated/RemoteUseCases/DeletePlan';
import {
  DeletePriceRuleExpectedNetCostInclusion,
  IDeletePriceRuleExpectedNetCostInclusion,
} from 'deprecated/RemoteUseCases/DeletePriceRuleExpectedNetCostInclusion';
import DeleteProductFeatureLabel, {
  IDeleteProductFeatureLabel,
} from 'deprecated/RemoteUseCases/DeleteProductFeatureLabel';
import {
  DeleteProgramPayment,
  IDeleteProgramPayment,
} from 'deprecated/RemoteUseCases/DeleteProgramPayment';
import { DeleteSegment, IDeleteSegment } from 'deprecated/RemoteUseCases/DeleteSegment';
import DeleteSegmentFromPriceList, {
  IDeleteSegmentFromPriceList,
} from 'deprecated/RemoteUseCases/DeleteSegmentFromPriceList';
import DeleteSegmentFromProgram, {
  IDeleteSegmentFromProgram,
} from 'deprecated/RemoteUseCases/DeleteSegmentFromProgram';
import { DeleteTerritory, IDeleteTerritory } from 'deprecated/RemoteUseCases/DeleteTerritory';
import {
  EditFeatureLabel,
  IEditFeatureLabel,
} from 'deprecated/RemoteUseCases/EditFeatureLabel/EditFeatureLabel';
import {
  EditProgramGroup,
  IEditProgramGroup,
} from 'deprecated/RemoteUseCases/EditProgramGroup/EditProgramGroup';
import EditTerritory, { IEditTerritory } from 'deprecated/RemoteUseCases/EditTerritory';
import GetActiveIngredientsForAvailableProducts, {
  IGetActiveIngredientsForAvailableProducts,
} from 'deprecated/RemoteUseCases/GetActiveIngredientsForAvailableProducts';
import GetAllCostLists, {
  IGetAllCostLists,
} from 'deprecated/RemoteUseCases/GetAllCostListsForOrganisation';
import {
  GetAllCostListsForUser,
  IGetAllCostListsForUser,
} from 'deprecated/RemoteUseCases/GetAllCostListsForUser';
import {
  GetAllGroupPolicies,
  IGetAllGroupPolicies,
} from 'deprecated/RemoteUseCases/GetAllGroupPolicies';
import GetAllManufacturers, {
  IGetAllManufacturers,
} from 'deprecated/RemoteUseCases/GetAllManufacturers';
import GetAllNetworkGroupsForOrg, {
  IGetAllNetworkGroupsForOrg,
} from 'deprecated/RemoteUseCases/GetAllNetworkGroupsForOrg';
import {
  GetAllOrganizationHeadquarters,
  IGetAllOrganizationHeadquarters,
} from 'deprecated/RemoteUseCases/GetAllOrganizationHeadquarters/GetAllOrganizationHeadquarters';
import {
  GetAllOrganizationPolicies,
  IGetAllOrganizationPolicies,
} from 'deprecated/RemoteUseCases/GetAllOrganizationPolicies';
import GetAllSegmentsForOrg, {
  IGetAllSegmentsForOrg,
} from 'deprecated/RemoteUseCases/GetAllSegmentsForOrg';
import GetAllSharedTerritories, {
  IGetAllSharedTerritories,
} from 'deprecated/RemoteUseCases/GetAllSharedTerritories';
import GetAllTerritoriesForOrg, {
  IGetAllTerritoriesForOrg,
} from 'deprecated/RemoteUseCases/GetAllTerritoriesForOrg';
import {
  GetAvailableUnitsOfMeasure,
  IGetAvailableUnitsOfMeasure,
} from 'deprecated/RemoteUseCases/GetAvailableUnitsOfMeasure';
import GetAzureDownloadToken, {
  IGetAzureDownloadTokens,
} from 'deprecated/RemoteUseCases/GetAzureDownloadTokens';
import GetCashRebatePercentage, {
  IGetCashRebatePercentage,
} from 'deprecated/RemoteUseCases/GetCashRebatePercentage';
import GetCostsForCostList, {
  IGetCostsForCostList,
} from 'deprecated/RemoteUseCases/GetCostsForCostList';
import {
  GetDefinedVolumeFormulas,
  IGetDefinedVolumeFormulas,
} from 'deprecated/RemoteUseCases/GetDefinedVolumeFormulas/GetDefinedVolumeFormulas';
import {
  GetDocumentContainerWithBrandDocuments,
  IGetDocumentContainerWithBrandDocuments,
} from 'deprecated/RemoteUseCases/GetDocumentContainerWithBrandDocuments';
import GetDownloadInfoById, {
  IGetDownloadInfoById,
} from 'deprecated/RemoteUseCases/GetDownloadInfoById';
import GetDvfNamesForOrganisation, {
  IGetDvfNamesForOrganisation,
} from 'deprecated/RemoteUseCases/GetDvfNamesForOrganisation';
import GetExistingAuthenticationToken, {
  IGetExistingAuthenticationToken,
} from 'deprecated/RemoteUseCases/GetExistingAuthenticationToken';
import {
  GetFeatureLabels,
  IGetFeatureLabels,
} from 'deprecated/RemoteUseCases/GetFeatureLabels/GetFeatureLabels';
import GetFeatureToggles, { IGetFeatureToggles } from 'deprecated/RemoteUseCases/GetFeatureToggles';
import GetFipsCodes, { IGetFipsCodes } from 'deprecated/RemoteUseCases/GetFipsCodes';
import {
  GetGeneralSettings,
  IGetGeneralSettings,
} from 'deprecated/RemoteUseCases/GetGeneralSettings';
import {
  GetGroupAssignableUsers,
  IGetGroupAssignableUsers,
} from 'deprecated/RemoteUseCases/GetGroupAssignableUsers';
import { GetGroupMembers, IGetGroupMembers } from 'deprecated/RemoteUseCases/GetGroupMembers';
import {
  GetHeadquartersMatchesByAlias,
  IGetHeadquartersMatchesByAlias,
} from 'deprecated/RemoteUseCases/GetHeadquartersMatchesByAlias/GetHeadquartersMatchesByAlias';
import GetLocationsByOrganization, {
  IGetLocationsByOrganization,
} from 'deprecated/RemoteUseCases/GetLocationsByOrganization';
import GetLocationsSharedByOrganisation, {
  IGetLocationsSharedByOrganisation,
} from 'deprecated/RemoteUseCases/GetLocationsSharedByOrganisation';
import {
  GetNetworkGroupsAndOrganizationsByPolicy,
  IGetNetworkGroupsAndOrganizationsByPolicy,
} from 'deprecated/RemoteUseCases/GetNetworkGroupsAndOrganizationsByPolicy';
import {
  GetNetworkGroupsByNetworkGroupIds,
  IGetNetworkGroupsByNetworkGroupIds,
} from 'deprecated/RemoteUseCases/GetNetworkGroupsByNetworkGroupIds';
import GetNetworkGroupsWithTaggedOrganisations, {
  IGetNetworkGroupsWithTaggedOrganisations,
} from 'deprecated/RemoteUseCases/GetNetworkGroupsWithTaggedOrganisations';
import GetNewAuthenticationToken, {
  IGetNewAuthenticationToken,
} from 'deprecated/RemoteUseCases/GetNewAuthenticationToken';
import GetOrganisation from 'deprecated/RemoteUseCases/GetOrganisation';
import {
  GetOrganizationAliases,
  IGetOrganizationAliases,
} from 'deprecated/RemoteUseCases/GetOrganizationAliases';
import {
  GetPoliciesForGroup,
  IGetPoliciesForGroup,
} from 'deprecated/RemoteUseCases/GetPoliciesForGroup';
import {
  GetPoliciesForOrganization,
  IGetPoliciesForOrganization,
} from 'deprecated/RemoteUseCases/GetPoliciesForOrganization';
import GetPriceLists, { IGetPriceLists } from 'deprecated/RemoteUseCases/GetPriceLists';
import GetPriceRuleNetBilledPriceListSources, {
  IGetPriceRuleNetBilledPriceListSources,
} from 'deprecated/RemoteUseCases/GetPriceRuleNetBilledPriceListSources';
import GetPriceRulePriceListTypes, {
  IGetPriceRulePriceListTypes,
} from 'deprecated/RemoteUseCases/GetPriceRulePriceListTypes';
import GetPriceRulesNames from 'deprecated/RemoteUseCases/GetPriceRulesNames';
import {
  GetPriceRulesWithCostListInclusions,
  IGetPriceRulesWithCostListInclusions,
} from 'deprecated/RemoteUseCases/GetPriceRulesWithCostListInclusion';
import {
  GetPriceRulesWithRelatedProductsByProgramAndProductIds,
  IGetPriceRulesWithRelatedProductsByProgramAndProductIds,
} from 'deprecated/RemoteUseCases/GetPriceRulesWithRelatedProductsByProgramAndProductIds/GetPriceRulesWithRelatedProductsByProgramAndProductIds';
import GetProductFilters, { IGetProductFilters } from 'deprecated/RemoteUseCases/GetProductFilters';
import GetProductListing, { IGetProductListing } from 'deprecated/RemoteUseCases/GetProductListing';
import {
  GetSuggestedProducts,
  IGetSuggestedProducts,
} from 'deprecated/RemoteUseCases/GetProductSuggestions/GetProductSuggestions';
import GetProgramPayments, {
  IGetProgramPayments,
} from 'deprecated/RemoteUseCases/GetProgramPayments';
import GetPreviousYearPrograms, {
  IGetPreviousYearPrograms,
} from 'deprecated/RemoteUseCases/GetPrograms/getPreviousYearPrograms';
import GetSimplifiedPrograms, {
  IGetSimplifiedPrograms,
} from 'deprecated/RemoteUseCases/GetPrograms/getSimplifiedPrograms';
import IGetPublisherProgramGroupName, {
  GetPublisherProgramGroupName,
} from 'deprecated/RemoteUseCases/GetPublisherProgramGroup';
import GetSegmentsForAvailableProducts, {
  IGetSegmentsForAvailableProducts,
} from 'deprecated/RemoteUseCases/GetSegmentsForAvailableProducts';
import GetSharedPriceLists, {
  IGetSharedPriceLists,
} from 'deprecated/RemoteUseCases/GetSharedPriceLists';
import GetTerritoryTreeForOrg, {
  IGetTerritoryTreeForOrg,
} from 'deprecated/RemoteUseCases/GetTerritoryTreeForOrg';
import GetTerritoryTreeForUser, {
  IGetTerritoryTreeForUser,
} from 'deprecated/RemoteUseCases/GetTerritoryTreeForUser';
import {
  GetUnitOfMeasureConversionFactors,
  IGetUnitOfMeasureConversionFactors,
} from 'deprecated/RemoteUseCases/GetUnitOfMeasureConversionFactors/GetUnitOfMeasureConversionFactors';
import LogPriceRuleChangeReason, {
  ILogPriceRuleChangeReason,
} from 'deprecated/RemoteUseCases/LogPriceRuleChangeReason';
import {
  IManagePriceRulesInclusionForCostList,
  ManagePriceRulesInclusionForCostList,
} from 'deprecated/RemoteUseCases/ManagePriceRulesInclusionForCostList';
import OrganisationsSharingProgramsTo, {
  IOrganisationsSharingProgramsTo,
} from 'deprecated/RemoteUseCases/OrganisationsSharingProgramsTo';
import PriceRulesCostListInclusions, {
  IPriceRulesCostListInclusions,
} from 'deprecated/RemoteUseCases/PriceRulesCostListInclusions';
import {
  IProcessProductDocuments,
  ProcessProductDocuments,
} from 'deprecated/RemoteUseCases/ProcessProductDocuments/index';
import GetProgramGroupsForOrganization, {
  IGetProgramGroupsForOrganization,
} from 'deprecated/RemoteUseCases/ProgramGroups/GetProgramGroupsForOrganization';
import RecalculateForecast, {
  IRecalculateForecast,
} from 'deprecated/RemoteUseCases/RecalculateForecast';
import RecalculateForecastPlan, {
  IRecalculateForecastPlan,
} from 'deprecated/RemoteUseCases/RecalculateForecastPlans';
import {
  IRemoveGroupFromAllPoliciesInType,
  RemoveGroupFromAllPoliciesInType,
} from 'deprecated/RemoteUseCases/RemoveGroupFromAllPoliciesInType';
import {
  IRemoveGroupMember,
  RemoveGroupMember,
} from 'deprecated/RemoteUseCases/RemoveGroupMember/RemoveGroupMember';
import {
  IRemoveOrganizationFromAllPoliciesInType,
  RemoveOrganizationFromAllPoliciesInType,
} from 'deprecated/RemoteUseCases/RemoveOrganizationFromAllPoliciesInType';
import RevokeApiTokens, { IRevokeApiTokens } from 'deprecated/RemoteUseCases/RevokeApiTokens';
import SaveFilter, { ISaveFilter } from 'deprecated/RemoteUseCases/SaveFilter';
import SendActivationEmail, {
  ISendActivationEmail,
} from 'deprecated/RemoteUseCases/SendActivationEmail';
import UnassignTypeFromOrganisation, {
  IUnassignTypeFromOrganisation,
} from 'deprecated/RemoteUseCases/UnassignTypeFromOrganisation';
import {
  IUpdateDefinedVolumeFormula,
  UpdateDefinedVolumeFormula,
} from 'deprecated/RemoteUseCases/UpdateDefinedVolumeFormula/UpdateDefinedVolumeFormula';
import {
  IUpdateGeneralSettings,
  UpdateGeneralSettings,
} from 'deprecated/RemoteUseCases/UpdateGeneralSettings';
import { IUpdateGroup, UpdateGroup } from 'deprecated/RemoteUseCases/UpdateGroup';
import UpdatePackageConfiguration, {
  IPackageConfigurationUpdater,
} from 'deprecated/RemoteUseCases/UpdatePackageConfiguration';
import UpdatePriceListItem, {
  IUpdatePriceListItem,
} from 'deprecated/RemoteUseCases/UpdatePriceList';
import UpdatePriceRules, { IUpdatePriceRules } from 'deprecated/RemoteUseCases/UpdatePriceRule';
import {
  IUpdatePriceRuleExpectedNetCostInclusion,
  UpdatePriceRuleExpectedNetCostInclusion,
} from 'deprecated/RemoteUseCases/UpdatePriceRuleExpectedNetCostInclusion';
import UpdatePriceRuleRolloverStatus, {
  IUpdatePriceRuleRolloverStatus,
} from 'deprecated/RemoteUseCases/UpdatePriceRuleRolloverStatus';
import UpdateProduct, { IUpdateProduct } from 'deprecated/RemoteUseCases/UpdateProduct';
import UpdatePrograms, { IUpdateProgram } from 'deprecated/RemoteUseCases/UpdateProgram';
import UpdateProgramRolloverStatus, {
  IUpdateProgramRolloverStatus,
} from 'deprecated/RemoteUseCases/UpdateProgramRolloverStatus';
import UpdateSegment, { IUpdateSegment } from 'deprecated/RemoteUseCases/UpdateSegment';
import UploadLocationCsv, { IUploadLocationCsv } from 'deprecated/RemoteUseCases/UploadLocationCsv';
import UploadPriceListCsv, {
  IUploadPriceListCsv,
} from 'deprecated/RemoteUseCases/UploadPriceListCsv';
import ForecastPlanService, { IForecastPlanService } from 'deprecated/Services/ForecastPlanService';
import { GroupsService, IGroupsService } from 'deprecated/Services/GroupsService';
import {
  IUserAttributesService,
  IUserCostListsService,
  IUserLocalesService,
  IUserOrganizationsService,
  IUserPriceListsService,
  IUserSegmentsService,
  UserAttributesService,
} from 'deprecated/Services/UserAttributesService';
import ApplyFilter from 'deprecated/UseCases/ApplyFilter/ApplyFilter';
import IApplyFilter from 'deprecated/UseCases/ApplyFilter/IApplyFilter';
import AttachRawDataToBrand from 'deprecated/UseCases/AttachRawDataToBrand';
import { IAttachRawDataToBrand } from 'deprecated/UseCases/AttachRawDataToBrand/RemoteTypes';
import AttachRawDataToProduct from 'deprecated/UseCases/AttachRawDataToProduct';
import { IAttachRawDataToProduct } from 'deprecated/UseCases/AttachRawDataToProduct/RemoteTypes';
import CreateUser from 'deprecated/UseCases/CreateUser';
import DeactivateUser from 'deprecated/UseCases/DeactivateUser';
import DetachRawDataFromBrand from 'deprecated/UseCases/DetachRawDataFromBrand';
import { IDetachRawDataFromBrand } from 'deprecated/UseCases/DetachRawDataFromBrand/Boundary';
import DetachRawDataFromProduct from 'deprecated/UseCases/DetachRawDataFromProduct';
import { IDetachRawDataFromProduct } from 'deprecated/UseCases/DetachRawDataFromProduct/RemoteTypes';
import EditUser from 'deprecated/UseCases/EditUser';
import EditUserProfile from 'deprecated/UseCases/EditUserProfile';
import GetAllPackageConfigurationData from 'deprecated/UseCases/GetAllPackageConfigurationData';
import GetAllRegulatoryData from 'deprecated/UseCases/GetAllRegulatoryData';
import GetLocations from 'deprecated/UseCases/GetLocations';
import GetOrganisationData from 'deprecated/UseCases/GetOrganisationData';
import GetOtherOrganizationDetails from 'deprecated/UseCases/GetOtherOrganizationDetails';
import GetPlanning from 'deprecated/UseCases/GetPlans';
import GetProductDetail, { IGetProductDetail } from 'deprecated/UseCases/GetProductDetail';
import GetSearchQueryParameter from 'deprecated/UseCases/GetSearchQueryParameter/GetSearchQueryParameter';
import IGetSearchQueryParameter from 'deprecated/UseCases/GetSearchQueryParameter/IGetSearchQueryParameter';
import GetUser from 'deprecated/UseCases/GetUser';
import GetUsers from 'deprecated/UseCases/GetUsers';
import GetSortingParameters, {
  IGetSortingParameters,
} from 'deprecated/UseCases/IGetSortingParameters';
import GetQuote, { IGetQuote } from 'deprecated/UseCases/Quotes/GetQuote';
import UploadProductCsv, { IUploadProductCsv } from 'deprecated/UseCases/UploadProductCsv';
import UploadMessage, { IUploadMessage } from 'deprecated/UseCases/UploadProductCsv/UploadMessage';
import FilterValuesWatcher from 'deprecated/Utilities/FilterValuesWatcher';
import GetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';
import SearchWatcher from 'deprecated/Utilities/SearchWatcher';
import { IServiceContainer } from 'deprecated/Utilities/ServiceContainer';

interface IConfigureServicesArgs {
  container: IServiceContainer;
  hostname: string;
  envConfig: ENV_CONFIG_FRAGMENT_TYPE;
}

export function getBaseApiUrl(envConfig: ENV_CONFIG_FRAGMENT_TYPE) {
  return envConfig.api_url || 'http://localhost:5000/';
}

export default function configureServices({
  container,
  hostname,
  envConfig,
}: IConfigureServicesArgs) {
  const baseApiUrl = getBaseApiUrl(envConfig);
  const orgNodeCode = determineOrgNodeCode(hostname);

  const getAuthHeaderConfig = new GetTokenFromLocalStorage();
  const orgNodeGateway = new OrgNodeGateway(baseApiUrl, getAuthHeaderConfig);
  const uploadGateway = new UploadGateway(baseApiUrl, getAuthHeaderConfig);

  container.register<ENV_CONFIG_FRAGMENT_TYPE>('IEnvironmentConfiguration', envConfig);

  container.register<IPlanningApiGateway>(
    'IPlanningApiGateway',
    new PlanningApiGateway(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IProductsApiGateway>(
    'IProductsApiGateway',
    new ProductsApiGateway(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IOrganisationApiGateway>(
    'IOrganisationApiGateway',
    new OrganisationApiGateway(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IRegulatoryDataGateway>(
    'IRegulatoryDataGateway',
    new RegulatoryDataGateway(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IBrandGateway>(
    'IBrandGateway',
    new BrandApiGateway(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAllRegulatoryData>(
    'IGetAllRegulatoryData',
    new GetAllRegulatoryData(container.get<IRegulatoryDataGateway>('IRegulatoryDataGateway')),
  );

  container.register<IAttachRawDataToBrand>(
    'IAttachRawDataToBrand',
    new AttachRawDataToBrand(container.get<IBrandGateway>('IBrandGateway')),
  );

  container.register<IAttachRawDataToProduct>(
    'IAttachRawDataToProduct',
    new AttachRawDataToProduct(container.get<IProductsApiGateway>('IProductsApiGateway')),
  );

  container.register<IDetachRawDataFromProduct>(
    'IDetachRawDataFromProduct',
    new DetachRawDataFromProduct(container.get<IProductsApiGateway>('IProductsApiGateway')),
  );

  container.register<IGetProductDetail>(
    'IGetProductDetail',
    new GetProductDetail(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetQuote>('IGetQuote', new GetQuote(baseApiUrl, getAuthHeaderConfig));

  container.register<IGetOtherOrganizationDetails>(
    'IGetOtherOrganizationDetails',
    new GetOtherOrganizationDetails(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetOrganisationData>(
    'IGetOrganisationData',
    new GetOrganisationData(orgNodeGateway),
  );

  container.register<IUploadProductCsv>('IUploadProductCsv', new UploadProductCsv(uploadGateway));

  container.register<IUploadLocationCsv>(
    'IUploadLocationCsv',
    new UploadLocationCsv(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IUploadPriceListCsv>(
    'IUploadPriceListCsv',
    new UploadPriceListCsv(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IFilterValuesWatcher>('IFilterValuesWatcher', new FilterValuesWatcher());

  container.register<ISearchWatcher>('ISearchWatcher', new SearchWatcher());

  const productsQueryGateway = new ProductsQueryGateway();
  container.register<ISearchQueryStore>('ISearchQueryStore', productsQueryGateway);

  container.register<IParameterStore>('IParameterStore', productsQueryGateway);

  container.register<ISortingParametersStore>('ISortingParametersStore', productsQueryGateway);

  container.register<IGetSearchQueryParameter>(
    'IGetSearchQueryParameter',
    new GetSearchQueryParameter(container.get<ISearchQueryStore>('ISearchQueryStore')),
  );

  container.register<IGetFeatureToggles>(
    'IGetFeatureToggles',
    new GetFeatureToggles(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetSortingParameters>(
    'IGetSortingParameters',
    new GetSortingParameters(container.get<ISortingParametersStore>('ISortingParametersStore')),
  );

  container.register<IGetPlans>(
    'IGetPlans',
    new GetPlanning(
      container.get<IPlanningApiGateway>('IPlanningApiGateway'),
      container.get<IGetSearchQueryParameter>('IGetSearchQueryParameter'),
      container.get<IGetSortingParameters>('IGetSortingParameters'),
    ),
  );

  container.register<IApplyFilter>(
    'IApplyFilter',
    new ApplyFilter(
      container.get<IParameterStore>('IParameterStore'),
      container.get<IFilterValuesWatcher>('IFilterValuesWatcher'),
    ),
  );

  container.register<IDetachRawDataFromBrand>(
    'IDetachRawDataFromBrand',
    new DetachRawDataFromBrand(container.get<IBrandGateway>('IBrandGateway')),
  );

  container.register<IPackageConfigurationDataApiGateway>(
    'IPackageConfigurationDataApiGateway',
    new PackageConfigurationDataApiGateway(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetAllPackageConfigurationData>(
    'IGetAllPackageConfigurationData',
    new GetAllPackageConfigurationData(
      container.get<IPackageConfigurationDataApiGateway>('IPackageConfigurationDataApiGateway'),
    ),
  );

  container.register<IUploadMessage>('IUploadMessage', new UploadMessage());

  container.register<ILocationApiGateway>(
    'ILocationApiGateway',
    new LocationApiGateway(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetLocations>(
    'IGetLocations',
    new GetLocations(orgNodeCode, container.get<ILocationApiGateway>('ILocationApiGateway')),
  );

  container.register<IGetPriceLists>(
    'IGetPriceLists',
    new GetPriceLists(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetSharedPriceLists>(
    'IGetSharedPriceLists',
    new GetSharedPriceLists(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUpdatePriceListItem>(
    'IUpdatePriceListItem',
    new UpdatePriceListItem(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetOrganisation>(
    'IGetOrganisation',
    new GetOrganisation(new URL(baseApiUrl), getAuthHeaderConfig),
  );

  container.register<IGetAllNetworkGroupsForOrg>(
    'IGetAllNetworkGroupsForOrg',
    new GetAllNetworkGroupsForOrg(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICreateOrUpdateNetworkGroup>(
    'ICreateOrUpdateNetworkGroup',
    new CreateOrUpdateNetworkGroup(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICreateAssetToken>(
    'ICreateAssetToken',
    new CreateAssetToken(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeleteNetworkGroup>(
    'IDeleteNetworkGroup',
    new DeleteNetworkGroup(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IAssignTypeToOrganisation>(
    'IAssignTypeToOrganisation',
    new AssignTypeToOrganisation(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUnassignTypeFromOrganisation>(
    'IUnassignTypeFromOrganisation',
    new UnassignTypeFromOrganisation(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetNewAuthenticationToken>(
    'IGetNewAuthenticationToken',
    new GetNewAuthenticationToken(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetExistingAuthenticationToken>(
    'IGetExistingAuthenticationToken',
    new GetExistingAuthenticationToken(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IRevokeApiTokens>(
    'IRevokeApiTokens',
    new RevokeApiTokens(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICreatePriceRule>(
    'ICreatePriceRule',
    new CreatePriceRule(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<ICreateProgram>(
    'ICreateProgram',
    new CreateProgram(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUpdatePriceRules>(
    'IUpdatePriceRules',
    new UpdatePriceRules(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUpdateProgram>(
    'IUpdateProgram',
    new UpdatePrograms(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeletePlan>('IDeletePlan', new DeletePlan(baseApiUrl, getAuthHeaderConfig));

  container.register<IDeleteCanonicalProduct>(
    'IDeleteCanonicalProduct',
    new DeleteCanonicalProduct(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<string>('AssetBaseUrl', `${envConfig.azure_assets_domain}${orgNodeCode}`);

  container.register<ISaveFilter>('ISaveFilter', new SaveFilter(baseApiUrl, getAuthHeaderConfig));

  container.register<IGetProductListing>(
    'IGetProductListing',
    new GetProductListing(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IOrganisationsSharingProgramsTo>(
    'IOrganisationsSharingProgramsTo',
    new OrganisationsSharingProgramsTo(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetActiveIngredientsForAvailableProducts>(
    'IGetActiveIngredientsForAvailableProducts',
    new GetActiveIngredientsForAvailableProducts(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetNetworkGroupsWithTaggedOrganisations>(
    'IGetNetworkGroupsWithTaggedOrganisations',
    new GetNetworkGroupsWithTaggedOrganisations(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetDvfNamesForOrganisation>(
    'IGetDvfNamesForOrganisation',
    new GetDvfNamesForOrganisation(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetPriceRulePriceListTypes>(
    'IGetPriceRulePriceListTypes',
    new GetPriceRulePriceListTypes(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetPriceRuleNetBilledPriceListSources>(
    'IGetPriceRuleNetBilledPriceListSources',
    new GetPriceRuleNetBilledPriceListSources(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAllTerritoriesForOrg>(
    'IGetAllTerritoriesForOrg',
    new GetAllTerritoriesForOrg(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<ICreateTerritory>(
    'ICreateTerritory',
    new CreateTerritory(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetFipsCodes>(
    'IGetFipsCodes',
    new GetFipsCodes(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAllSharedTerritories>(
    'IGetAllSharedTerritories',
    new GetAllSharedTerritories(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IEditTerritory>(
    'IEditTerritory',
    new EditTerritory(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetAzureDownloadTokens>(
    'IGetAzureDownloadTokens',
    new GetAzureDownloadToken(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeleteProgram>(
    'IDeleteProgram',
    new DeleteProgram(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUploadFile>('IUploadFile', new UploadFile());

  container.register<IUploadThemeAsset>('IUploadThemeAsset', new UploadThemeAsset());

  container.register<IDeleteImageAsset>(
    'IDeleteImageAsset',
    new DeleteImageAsset(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUpdateProduct>('IUpdateProduct', new UpdateProduct());

  container.register<IGetLocationsByOrganization>(
    'IGetLocationsByOrganization',
    new GetLocationsByOrganization(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetLocationsSharedByOrganisation>(
    'IGetLocationsSharedByOrganisation',
    new GetLocationsSharedByOrganisation(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IActivateProduct>(
    'IActivateProduct',
    new ActivateProduct(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeactivateProduct>(
    'IDeactivateProduct',
    new DeactivateProduct(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetPriceRulesNames>(
    'GetPriceRulesNames',
    new GetPriceRulesNames(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IPriceRulesCostListInclusions>(
    'IPriceRulesCostListInclusions',
    new PriceRulesCostListInclusions(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAllCostLists>(
    'IGetAllCostLists',
    new GetAllCostLists(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<ICreateForecastPlan>(
    'ICreateForecastPlan',
    new CreateForecastPlan(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IAddForecastPlanProducts>(
    'IAddForecastPlanProducts',
    new AddForecastPlanProducts(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetCashRebatePercentage>(
    'IGetCashRebatePercentage',
    new GetCashRebatePercentage(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICreateCashRebatePercentage>(
    'ICreateCashRebatePercentage',
    new CreateCashRebatePercentage(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetTerritoryTreeForOrg>(
    'IGetTerritoryTreeForOrg',
    new GetTerritoryTreeForOrg(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetTerritoryTreeForUser>(
    'IGetTerritoryTreeForUser',
    new GetTerritoryTreeForUser(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IDeleteTerritory>(
    'IDeleteTerritory',
    new DeleteTerritory(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetAllSegmentsForOrg>(
    'IGetAllSegmentsForOrg',
    new GetAllSegmentsForOrg(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<ICreateSegment>(
    'ICreateSegment',
    new CreateSegment(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IUpdateSegment>(
    'IUpdateSegment',
    new UpdateSegment(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IDeleteSegment>(
    'IDeleteSegment',
    new DeleteSegment(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IAddSegmentToProgram>(
    'IAddSegmentToProgram',
    new AddSegmentToProgram(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeleteSegmentFromProgram>(
    'IDeleteSegmentFromProgram',
    new DeleteSegmentFromProgram(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetSegmentsForAvailableProducts>(
    'IGetSegmentsForAvailableProducts',
    new GetSegmentsForAvailableProducts(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<ICreateOrUpdateProgramPayment>(
    'ICreateOrUpdateProgramPayment',
    new CreateOrUpdateProgramPayment(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetProgramPayments>(
    'IGetProgramPayments',
    new GetProgramPayments(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeleteProgramPayment>(
    'IDeleteProgramPayment',
    new DeleteProgramPayment(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUserApiGateway>(
    'IUserApiGateway',
    new UserApiGateway(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetUsers>(
    'IGetUsers',
    new GetUsers(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<IGetUser>(
    'IGetUser',
    new GetUser(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<ICreateUser>(
    'ICreateUser',
    new CreateUser(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<IEditUser>(
    'IEditUser',
    new EditUser(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<IEditUserProfile>(
    'IEditUserProfile',
    new EditUserProfile(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<IChangePassword>(
    'IChangePassword',
    new ChangePassword(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<IDeactivateUser>(
    'IDeactivateUser',
    new DeactivateUser(container.get<IUserApiGateway>('IUserApiGateway')),
  );

  container.register<ISendActivationEmail>(
    'ISendActivationEmail',
    new SendActivationEmail(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetAllManufacturers>(
    'IGetAllManufacturers',
    new GetAllManufacturers(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAlert>(
    'IGetAlert',
    new GetAlert(orgNodeCode, baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAlertUnlinkedProducts>(
    'IGetAlertUnlinkedProducts',
    new GetAlertUnlinkedProducts(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAlertWholesaleSalesUnlinkedCounterParties>(
    'IGetAlertWholesaleSalesUnlinkedCounterParties',
    new GetAlertWholesaleSalesUnlinkedCounterParties(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAlertRetailSaleToEndUserSalesUnlinkedCounterParties>(
    'IGetAlertRetailSaleToEndUserSalesUnlinkedCounterParties',
    new GetAlertRetailSaleToEndUserSalesUnlinkedCounterParties(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAlertPurchasesUnlinkedCounterParties>(
    'IGetAlertPurchasesUnlinkedCounterParties',
    new GetAlertPurchasesUnlinkedCounterParties(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUpdatePriceRuleRolloverStatus>(
    'IUpdatePriceRuleRolloverStatus',
    new UpdatePriceRuleRolloverStatus(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IUpdateProgramRolloverStatus>(
    'IUpdateProgramRolloverStatus',
    new UpdateProgramRolloverStatus(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );
  container.register<IGetGeneralSettings>(
    'IGetGeneralSettings',
    new GetGeneralSettings(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IUpdateGeneralSettings>(
    'IUpdateGeneralSettings',
    new UpdateGeneralSettings(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetCostsForCostList>(
    'IGetCostsForCostList',
    new GetCostsForCostList(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IRecalculateForecast>(
    'IRecalculateForecast',
    new RecalculateForecast(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IRecalculateForecastPlan>(
    'IRecalculateForecastPlan',
    new RecalculateForecastPlan(baseApiUrl, getAuthHeaderConfig, orgNodeCode),
  );

  container.register<IGetUnitOfMeasureConversionFactors>(
    'IGetUnitOfMeasureConversionFactors',
    new GetUnitOfMeasureConversionFactors(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGroupsService>(
    'IGroupsService',
    new GroupsService(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeleteGroup>(
    'IDeleteGroup',
    new DeleteGroup(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IAddGroupMember>(
    'IAddGroupMember',
    new AddGroupMember(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IRemoveGroupMember>(
    'IRemoveGroupMember',
    new RemoveGroupMember(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetAllCostListsForUser>(
    'IGetAllCostListsForUser',
    new GetAllCostListsForUser(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  const userAttributesService = new UserAttributesService(baseApiUrl, getAuthHeaderConfig);

  container.register<IUserAttributesService>('IUserAttributesService', userAttributesService);
  container.register<IUserSegmentsService>('IUserSegmentsService', userAttributesService);
  container.register<IUserCostListsService>('IUserCostListsService', userAttributesService);
  container.register<IUserPriceListsService>('IUserPriceListsService', userAttributesService);
  container.register<IUserLocalesService>('IUserLocalesService', userAttributesService);
  container.register<IUserOrganizationsService>('IUserOrganizationsService', userAttributesService);
  container.register<IGetAllGroupPolicies>(
    'IGetAllGroupPolicies',
    new GetAllGroupPolicies(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetAllOrganizationPolicies>(
    'IGetAllOrganizationPolicies',
    new GetAllOrganizationPolicies(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IAddGroupToPolicy>(
    'IAddGroupToPolicy',
    new AddGroupToPolicy(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IRemoveGroupFromAllPoliciesInType>(
    'IRemoveGroupFromAllPoliciesInType',
    new RemoveGroupFromAllPoliciesInType(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICreateGroup>(
    'ICreateGroup',
    new CreateGroup(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IUpdateGroup>(
    'IUpdateGroup',
    new UpdateGroup(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetPoliciesForGroup>(
    'IGetPoliciesForGroup',
    new GetPoliciesForGroup(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetGroupMembers>(
    'IGetGroupMembers',
    new GetGroupMembers(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IUpdatePriceRuleExpectedNetCostInclusion>(
    'IUpdatePriceRuleExpectedNetCostInclusion',
    new UpdatePriceRuleExpectedNetCostInclusion(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IDeletePriceRuleExpectedNetCostInclusion>(
    'IDeletePriceRuleExpectedNetCostInclusion',
    new DeletePriceRuleExpectedNetCostInclusion(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetPriceRulesWithCostListInclusions>(
    'IGetPriceRulesWithCostListInclusions',
    new GetPriceRulesWithCostListInclusions(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IManagePriceRulesInclusionForCostList>(
    'IManagePriceRulesInclusionForCostList',
    new ManagePriceRulesInclusionForCostList(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICopyProgram>(
    'ICopyProgram',
    new CopyProgram(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<ICreateOrUpdateCustomerServiceContactPoint>(
    'ICreateOrUpdateCustomerServiceContactPoint',
    new CreateOrUpdateCustomerServiceContactPoint(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetFeatureLabels>(
    'IGetFeatureLabels',
    new GetFeatureLabels(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IEditFeatureLabel>(
    'IEditFeatureLabel',
    new EditFeatureLabel(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IEditProgramGroup>(
    'IEditProgramGroup',
    new EditProgramGroup(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<ICreateOrUpdateProductFeatureLabel>(
    'ICreateOrUpdateProductFeatureLabel',
    new CreateOrUpdateProductFeatureLabel(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeleteProductFeatureLabel>(
    'IDeleteProductFeatureLabel',
    new DeleteProductFeatureLabel(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<ILogPriceRuleChangeReason>(
    'ILogPriceRuleChangeReason',
    new LogPriceRuleChangeReason(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetPriceRulesWithRelatedProductsByProgramAndProductIds>(
    'IGetPriceRulesWithRelatedProductsByProgramAndProductIds',
    new GetPriceRulesWithRelatedProductsByProgramAndProductIds(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IAddOrganizationToPolicy>(
    'IAddOrganizationToPolicy',
    new AddOrganizationToPolicy(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IRemoveOrganizationFromAllPoliciesInType>(
    'IRemoveOrganizationFromAllPoliciesInType',
    new RemoveOrganizationFromAllPoliciesInType(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetPoliciesForOrganization>(
    'IGetPoliciesForOrganization',
    new GetPoliciesForOrganization(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IPackageConfigurationUpdater>(
    'IPackageConfigurationUpdater',
    new UpdatePackageConfiguration(baseApiUrl, getAuthHeaderConfig),
  );
  container.register<IGetProductFilters>(
    'IGetProductFilters',
    new GetProductFilters(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );
  container.register<IGetDefinedVolumeFormulas>(
    'IGetDefinedVolumeFormulas',
    new GetDefinedVolumeFormulas(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IUpdateDefinedVolumeFormula>(
    'IUpdateDefinedVolumeFormula',
    new UpdateDefinedVolumeFormula(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetNetworkGroupsAndOrganizationsByPolicy>(
    'IGetNetworkGroupsAndOrganizationsByPolicy',
    new GetNetworkGroupsAndOrganizationsByPolicy(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetNetworkGroupsByNetworkGroupIds>(
    'IGetNetworkGroupsByNetworkGroupIds',
    new GetNetworkGroupsByNetworkGroupIds(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetProductFilters>(
    'IGetProductFilters',
    new GetProductFilters(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<ICreatePackageConfiguration>(
    'ICreatePackageConfiguration',
    new CreatePackageConfiguration(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IDeletePackageConfiguration>(
    'IDeletePackageConfiguration',
    new DeletePackageConfiguration(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAvailableUnitsOfMeasure>(
    'IGetAvailableUnitsOfMeasure',
    new GetAvailableUnitsOfMeasure(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetOrganizationAliases>(
    'IGetOrganizationAliases',
    new GetOrganizationAliases(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IDeleteOrganizationAlias>(
    'IDeleteOrganizationAlias',
    new DeleteOrganizationAlias(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IAddOrganizationAliases>(
    'IAddOrganizationAliases',
    new AddOrganizationAliases(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetDownloadInfoById>(
    'IGetDownloadInfoById',
    new GetDownloadInfoById(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetHeadquartersMatchesByAlias>(
    'IGetHeadquartersMatchesByAlias',
    new GetHeadquartersMatchesByAlias(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetAllOrganizationHeadquarters>(
    'IGetAllOrganizationHeadquarters',
    new GetAllOrganizationHeadquarters(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IForecastPlanService>(
    'IForecastPlanService',
    new ForecastPlanService(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetSuggestedProducts>(
    'IGetSuggestedProducts',
    new GetSuggestedProducts(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IAddSegmentToPriceList>(
    'IAddSegmentToPriceList',
    new AddSegmentToPriceList(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IDeleteSegmentFromPriceList>(
    'IDeleteSegmentFromPriceList',
    new DeleteSegmentFromPriceList(baseApiUrl, orgNodeCode, getAuthHeaderConfig),
  );

  container.register<IGetProgramGroupsForOrganization>(
    'IGetProgramGroupsForOrganization',
    new GetProgramGroupsForOrganization(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetPublisherProgramGroupName>(
    'IGetPublisherProgramGroupName',
    new GetPublisherProgramGroupName(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IBulkUpdateProducts>(
    'IBulkUpdateProducts',
    new BulkUpdateProducts(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetSimplifiedPrograms>(
    'IGetSimplifiedPrograms',
    new GetSimplifiedPrograms(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetPreviousYearPrograms>(
    'IGetPreviousYearPrograms',
    new GetPreviousYearPrograms(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetGroupAssignableUsers>(
    'IGetGroupAssignableUsers',
    new GetGroupAssignableUsers(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IGetDocumentContainerWithBrandDocuments>(
    'GetDocumentContainerWithBrandDocuments',
    new GetDocumentContainerWithBrandDocuments(baseApiUrl, getAuthHeaderConfig),
  );

  container.register<IProcessProductDocuments>(
    'ProcessProductDocuments',
    new ProcessProductDocuments(baseApiUrl, getAuthHeaderConfig),
  );

  return container;
}
