import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { RcFile } from 'antd/lib/upload/interface';

import ThemeImageType from 'deprecated/Constants/ThemeImageTypes';
import { IAssetResponse } from 'deprecated/Domain/IAsset';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateAssetToken {
  execute(
    presenter: ICreateAssetPresenter,
    themeImageType: ThemeImageType,
    fileUpload: RcFile,
  ): void;
}

export interface ICreateAssetPresenter {
  sasTokenCreated: (assetResponse: IAssetResponse, fileUpload: RcFile) => void;
  sasTokenNotCreated: (errors?: string) => void;
}

export default class CreateAssetToken implements ICreateAssetToken {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    presenter: ICreateAssetPresenter,
    themeImageType: ThemeImageType,
    fileUpload: RcFile,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `/api/settingsTheme/sasToken/${themeImageType}/${fileUpload.name}`;

    const headers = this.config.getAuthConfig();

    try {
      const response = await axios.get(url.href, headers);
      presenter.sasTokenCreated(response.data, fileUpload);
    } catch (error) {
      if (isAxiosError(error)) {
        presenter.sasTokenNotCreated(error.response?.data?.errors);
      }
    }
  }
}
