/* eslint-disable class-methods-use-this */
import axios from '@smartwyre/utils/lib/authenticatedAxios';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';

import { isNullOrWhitespace } from 'utils/string';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

interface Response {
  readonly expiresUtc: string;
  readonly fipsCodes: IFipsCode[];
}

export interface IGetFipsCodes {
  execute(presenter: IGetFipsCodesPresenter): void;
}

export interface IGetFipsCodesPresenter {
  populateFipsCodes(fipsCodes: IFipsCode[]): void;
}

export interface IFipsCode {
  code: string;
  county: string;
  state: string;
  stateCode: string;
  country: string;
  countryCode: string;
}

const CACHE_KEY = 'fipsCodes';

export default class GetFipsCodes implements IGetFipsCodes {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  public useCache: boolean;

  constructor(host: string, config: IGetTokenFromLocalStorage, useCache = true) {
    this.host = new URL(host);
    this.config = config;
    this.useCache = useCache;
  }

  public execute = async (presenter: IGetFipsCodesPresenter) => {
    try {
      if (this.useCache) {
        const cachedFipsCodes = this.getFromCache();

        if (!isNil(cachedFipsCodes)) {
          presenter.populateFipsCodes(cachedFipsCodes);

          return;
        }
      }

      const url = new URL(this.host.href);

      url.pathname = 'api/system/referenceData/fipsCodes';

      const response = await axios.get(url.href, this.config.getAuthConfig());

      if (response.status === 200) {
        if (this.useCache) {
          this.setInCache(response.data.data);
        }

        presenter.populateFipsCodes(response.data.data.fipsCodes ?? []);
      }

      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  private getFromCache = (): IFipsCode[] | null => {
    const item = localStorage.getItem(CACHE_KEY);

    if (isNullOrWhitespace(item)) {
      return null;
    }

    const cachedResponse: Response = JSON.parse(item ?? '');

    if (
      !isNullOrWhitespace(cachedResponse?.expiresUtc) &&
      dayjs(cachedResponse.expiresUtc).isBefore(dayjs.utc())
    ) {
      localStorage.removeItem(CACHE_KEY);

      return null;
    }

    if (!isNil(cachedResponse?.fipsCodes) && cachedResponse.fipsCodes.length === 0) {
      return null;
    }

    return cachedResponse?.fipsCodes;
  };

  private setInCache = (response: Response | null) => {
    if (
      isNil(response) ||
      (!isNullOrWhitespace(response.expiresUtc) && dayjs(response.expiresUtc).isBefore(dayjs.utc()))
    ) {
      localStorage.removeItem(CACHE_KEY);

      return;
    }

    const json = JSON.stringify(response);

    localStorage.setItem(CACHE_KEY, json);
  };
}
