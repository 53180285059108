import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import alphanumericSorter from '@smartwyre/utils/lib/sorter/alphanumericSorter';

import { IProductType } from 'deprecated/Domain/Product/IProductType';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

import { getProductFilters, GetProductFiltersResponse } from './getProductFilters';

export interface IFilterValue {
  value: string;
  displayValue: string;
}

export interface IDisableableFilter {
  disabled: boolean;
  filter: IFilter;
}

export interface IFilter {
  name: string;
  queryParam: string;
  filterValues: IFilterValue[];
}

export interface IGetProductFilters {
  getProductTypesFilter(): Promise<IProductType[]>;
  execute(): Promise<IFilter[]>;
}

export default class GetProductFilters extends RemoteUseCaseBase implements IGetProductFilters {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, `api/node/${orgNodeCode}/products/filters`, orgNodeCode, config);
  }

  public async execute(): Promise<IFilter[]> {
    if (this.orgNodeCode) {
      const response = await getProductFilters();
      return buildFilters(response.data);
    }

    return [];
  }

  public async getProductTypesFilter() {
    const response = await authenticatedAxios.get<IProductType[]>(
      `${this.url.href}/product-types`,
      this.config.getAuthConfig(),
    );
    return response.data.sort((a, b) => alphanumericSorter(a.name, b.name));
  }
}

function buildFilters(data: GetProductFiltersResponse['data'] | null | undefined): IFilter[] {
  if (data == null || data.filters == null) {
    return [];
  }
  const filters = data.filters;

  const productTypesFilter: IFilter = {
    name: 'Product Types',
    queryParam: 'productTypes',
    filterValues: filters.productTypes.map((x) => ({
      value: String(x.id),
      displayValue: x.name,
    })),
  };

  const segmentsFilter: IFilter = {
    name: '[ORG_NAME] Segments',
    queryParam: 'segments',
    filterValues: filters.segments.map((x) => ({
      value: String(x.id),
      displayValue: x.name,
    })),
  };

  const brandFamilies: IFilter = {
    name: 'Brand Families',
    queryParam: 'brandFamilies',
    filterValues: filters.brandFamilies.map((x) => ({
      value: String(x.id),
      displayValue: x.name,
    })),
  };

  const productOwners: IFilter = {
    name: 'Product Owners',
    queryParam: 'productOwners',
    filterValues: filters.productOwners.map((x) => ({
      value: String(x.id),
      displayValue: x.name,
    })),
  };

  const productSubTypes: IFilter = {
    name: 'Types',
    queryParam: 'types',
    filterValues: filters.productSubTypes.map((x) => ({
      value: String(x.id),
      displayValue: x.name,
    })),
  };

  const activeIngredients: IFilter = {
    name: 'Active Ingredients',
    queryParam: 'activeIngredients',
    filterValues: filters.activeIngredients.map((x) => ({
      value: x.id,
      displayValue: x.name,
    })),
  };

  const crops: IFilter = {
    name: 'Crops',
    queryParam: 'crops',
    filterValues: filters.crops.map((x) => ({
      value: x.id,
      displayValue: x.name,
    })),
  };

  const pests: IFilter = {
    name: 'Pests',
    queryParam: 'pests',
    filterValues: filters.pests.map((x) => ({
      value: x.id,
      displayValue: x.name,
    })),
  };

  const modesOfAction: IFilter = {
    name: 'Modes Of Action',
    queryParam: 'modesOfAction',
    filterValues: filters.modesOfAction.map((x) => ({
      value: x.id,
      displayValue: x.name,
    })),
  };

  const featureLabels: IFilter = {
    name: 'Feature Labels',
    queryParam: 'featureLabels',
    filterValues: filters.featureLabels.map((x) => ({
      value: String(x.id),
      displayValue: x.name,
    })),
  };

  return [
    productTypesFilter,
    segmentsFilter,
    brandFamilies,
    productOwners,
    productSubTypes,
    activeIngredients,
    crops,
    pests,
    modesOfAction,
    featureLabels,
  ];
}
