import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteSegmentFromPriceList {
  execute(
    priceListId: number,
    segmentId: number,
    presenter: IDeleteSegmentFromPriceListPresenter,
  ): void;
}

export interface IDeleteSegmentFromPriceListPresenter {
  priceListSegmentDeleted(): void;
  priceListSegmentNotDeleted(): void;
}

export default class AddSegmentToPriceList implements IDeleteSegmentFromPriceList {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(
    priceListId: number,
    segmentId: number,
    presenter: IDeleteSegmentFromPriceListPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(
      this.orgNodeCode,
    )}/sellerPriceLists/${priceListId}/segments/${segmentId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());
      presenter.priceListSegmentDeleted();
    } catch {
      presenter.priceListSegmentNotDeleted();
    }
  }
}
