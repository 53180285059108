import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IActiveIngredient from 'deprecated/Domain/IActiveIngredient';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetActiveIngredientsForAvailableProducts {
  execute(ignoreProductSharing?: boolean | null | undefined): Promise<IActiveIngredient[]>;
}

export default class GetActiveIngredientsForAvailableProducts
  implements IGetActiveIngredientsForAvailableProducts
{
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(ignoreProductSharing?: boolean | null): Promise<IActiveIngredient[]> {
    const url = new URL(this.hostname);
    url.pathname = '/api/regulatoryData/activeIngredients';
    url.search = `ignoreProductSharing=${!!ignoreProductSharing}`;

    const response = await axios.get(url.href, this.config.getAuthConfig());
    return response.data.activeIngredients;
  }

  public executePromise(): Promise<IActiveIngredient[]> {
    const url = new URL(this.hostname);
    url.pathname = '/api/regulatoryData/activeIngredients';

    return axios
      .get<{ activeIngredients: IActiveIngredient[] }>(url.href, this.config.getAuthConfig())
      .then((response) => response.data.activeIngredients);
  }
}
