import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';

import { EnvironmentType } from 'fragments/Config';

import { useOrganization } from 'contexts/organizations/OrganizationContext/hooks/useOrganization';

import { useEnvConfig } from './EnvConfigContext/hooks/useEnvConfig';

export interface PageTitleContextProps {
  pushTitle: (title: string, id: string) => void;
  popTitle: (id: string) => void;
  setDisableOrgName: (setTo: boolean) => void;
}

const PageTitleContext = createContext<PageTitleContextProps | undefined>(undefined);

const getPageTitlePrefix = (envName: EnvironmentType): string => {
  switch (envName) {
    case EnvironmentType.Production:
      return 'Smartwyre';
    case EnvironmentType.Staging:
      return 'Staging';
    case EnvironmentType.Demo:
      return 'Demo';
    case EnvironmentType.Pilot:
      return 'Pilot';
    case EnvironmentType.Dts:
      return 'Smartwyre Data';
    case EnvironmentType.Sandbox:
      return 'Sandbox';
    case EnvironmentType.Local:
      return 'Smartwyre Dev';
    default:
      return 'Smartwyre';
  }
};

interface TitleEntry {
  id: string;
  title: string;
}

const PageTitleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [orgNameDisabled, setOrgNameDisabled] = useState<boolean>(false);
  const { name: organizationName } = useOrganization();
  const envConfigContext = useEnvConfig();

  const pageTitlePrefix = useMemo(
    () => getPageTitlePrefix(envConfigContext.env_name),
    [envConfigContext.env_name],
  );

  const [titleStack, setTitleStack] = useState<TitleEntry[]>([]);

  const pushTitle = useCallback((title: string, id: string) => {
    setTitleStack((prevStack) => [...prevStack, { id, title }]);
  }, []);

  const popTitle = useCallback((id: string) => {
    setTitleStack((prevStack) => prevStack.filter((entry) => entry.id !== id));
  }, []);

  const setDisableOrgName = useCallback((setTo: boolean) => {
    setOrgNameDisabled(setTo);
  }, []);

  const orgName = useMemo(() => {
    return organizationName.length === 0 || orgNameDisabled ? '' : ` | ${organizationName}`;
  }, [orgNameDisabled, organizationName]);

  const currentTitle = useMemo(
    () => (titleStack.length > 0 ? titleStack.map((entry) => entry.title).join(' | ') : ''),
    [titleStack],
  );

  const contextValue = useMemo(
    () => ({
      pushTitle,
      popTitle,
      setDisableOrgName,
    }),
    [pushTitle, popTitle, setDisableOrgName],
  );

  return (
    <PageTitleContext.Provider value={contextValue}>
      <head>
        <title>
          {`${pageTitlePrefix} ${currentTitle.length > 0 ? '|' : ''} ${currentTitle} ${orgName}`}
        </title>
      </head>
      {children}
    </PageTitleContext.Provider>
  );
};

const usePageTitleContext = (): PageTitleContextProps => {
  const context = useContext(PageTitleContext);
  if (!context) {
    throw new Error('usePageTitleContext must be used within a PageTitleContext');
  }
  return context;
};

export { PageTitleProvider, usePageTitleContext };
