import AntSelect, { SelectProps as AntSelectProps } from 'antd/lib/select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectProps<T = any> = AntSelectProps<T>;

export default function Select(props: SelectProps) {
  return <AntSelect {...props} />;
}

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;
