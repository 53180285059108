/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { HttpClient, RequestParams } from 'utils/axios/http-client';

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';

import {
  ApiResponseQueriesGetRequestAccommodationNotificationGetRequestAccommodationNotificationQuery,
  FrameworkWebResponsesApiResponse,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsAccommodationRequestDeniedNotificationsDetail
   * @request GET:/api/notifications/accommodation-request-denied-notifications/{notificationId}
   */
  notificationsAccommodationRequestDeniedNotificationsDetail = async (variables: {
    notificationId: number;
    requestParams?: RequestParams;
  }) => {
    const { notificationId } = variables;
    const { data } = await this.request<
      ApiResponseQueriesGetRequestAccommodationNotificationGetRequestAccommodationNotificationQuery,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications/accommodation-request-denied-notifications/${notificationId}`,
      method: 'GET',
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const notificationsAccommodationRequestDeniedNotificationsDetailQueryCacheKey = (variables: {
  notificationId: number;
  requestParams?: RequestParams;
}) => {
  const { notificationId } = variables;
  return [
    `/api/notifications/accommodation-request-denied-notifications/${notificationId}`,
    variables.notificationId,
    variables.requestParams,
  ].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useNotificationsAccommodationRequestDeniedNotificationsDetailQuery = (
  variables: { notificationId: number; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseQueriesGetRequestAccommodationNotificationGetRequestAccommodationNotificationQuery,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    notificationsAccommodationRequestDeniedNotificationsDetailQueryCacheKey(variables),
    () => new Api().notificationsAccommodationRequestDeniedNotificationsDetail(variables),
    options,
  );
};

/**
  Mutation Hooks
 */
