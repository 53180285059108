import flattenSharedProgramNotificationProducts from '../../utils/flattenSharedProgramNotificationProducts';
import sharedProgramNotificationColumns from '../../utils/sharedProgramNotificationColumns';
import Table from '@smartwyre/components/lib/Table';
import PolicyTypes from '@smartwyre/hooks/lib/useAuthentication/fragments/PolicyTypes';
import { useLocation, useNavigate } from 'react-router-dom';

import { QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryProduct } from '__generated__/api/notifications/shared-program-notifications/{notificationId}/data-contracts';

import { usePermissions } from 'deprecated/CustomHooks/usePermissions';

import styles from './SharedProgramNotificationTable.module.scss';

export interface ISharedProgramNotificationTableProps {
  products: QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryProduct[];
  showNets: boolean;
  viewAsNets: boolean;
}

export const SharedProgramNotificationTable = ({
  products,
  showNets,
  viewAsNets,
}: ISharedProgramNotificationTableProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { checkPermission } = usePermissions();
  const canViewCosts = checkPermission(PolicyTypes.canViewCosts);
  const canViewAdvancedCosts = checkPermission(PolicyTypes.canViewAdvancedCosts);

  const rows = flattenSharedProgramNotificationProducts(products);

  function onRowEvent(
    record: QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryProduct,
  ) {
    navigate({
      pathname: `/products/${record.productId}/commercial`,
    });
  }

  return (
    <Table
      rowKey={(row) => row.productId?.toString() ?? `${row.productName}-${row.packageSize}`}
      onRow={(record) => {
        return {
          onClick: () => {
            onRowEvent(record);
          },
        };
      }}
      columns={sharedProgramNotificationColumns(
        canViewCosts,
        canViewAdvancedCosts,
        showNets,
        viewAsNets,
      )}
      dataSource={rows}
      pagination={false}
      tableWrapClass={styles.tableWrap}
      searchParams={location.search}
    />
  );
};

export default SharedProgramNotificationTable;
