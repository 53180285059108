/* eslint-disable class-methods-use-this */
import { RcFile } from 'antd/lib/upload/interface';
import axios from 'axios';

export interface IUploadThemeAsset {
  execute(presenter: IUploadFilePresenter, sasToken: string, file: RcFile): Promise<void>;
}

export interface IUploadFilePresenter {
  fileUploaded: () => void;
  fileNotUploaded: () => void;
}

export default class UploadThemeAsset implements IUploadThemeAsset {
  public async execute(presenter: IUploadFilePresenter, sasUrl: string, file: RcFile) {
    const url = new URL(sasUrl);
    const headers = {
      headers: {
        'x-ms-date': `${new Date().toUTCString()}`,
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-blob-content': 'image/png',
        'Content-Type': 'image/png',
        'Cache-Control': 'no-cache',
      },
    };
    try {
      await axios.put(url.href, file, headers);
      presenter.fileUploaded();
    } catch (error) {
      presenter.fileNotUploaded();
    }
  }
}
