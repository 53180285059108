import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { AuthToken } from 'deprecated/RemoteUseCases/GetNewAuthenticationToken';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetExistingAuthenticationToken {
  execute(): Promise<AuthToken | null>;
}

export default class GetExistingAuthenticationToken implements IGetExistingAuthenticationToken {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute() {
    const url = new URL(this.host.href);

    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/authToken`;

    const response = await axios.get(url.href, this.config.getAuthConfig());
    return response.data;
  }
}
