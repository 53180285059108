import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import AuthenticatorInitializer from '@smartwyre/components/lib/AuthenticatorInitializer';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOrganization } from 'contexts/organizations/OrganizationContext/hooks/useOrganization';

interface InitAuthenticationProps {
  children: React.ReactNode;
}

function InitAuthentication({ children }: InitAuthenticationProps) {
  const organization = useOrganization();
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
    },
    [navigate],
  );

  return (
    <AuthenticatorInitializer
      redirectUri={`${window.location.origin}/implicit/callback`}
      clientId={organization.authClientId}
      issuer={organization.authIssuer}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </AuthenticatorInitializer>
  );
}

export default InitAuthentication;
