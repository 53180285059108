import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IdNamePair } from 'deprecated/Domain/IdNamePair';
import { ICostListInclusion } from 'deprecated/Domain/PriceRule/IPriceRuleResponse';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPriceRuleWithCostListInclusions extends IdNamePair<number> {
  costListInclusions: ICostListInclusion[];
}

export interface IGetPriceRulesWithCostListInclusions {
  execute(programId: string): Promise<IPriceRuleWithCostListInclusions[]>;
}

export class GetPriceRulesWithCostListInclusions
  extends RemoteUseCaseBase
  implements IGetPriceRulesWithCostListInclusions
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/price-rules', orgNodeCode, config);
  }

  public async execute(programId: string): Promise<IPriceRuleWithCostListInclusions[]> {
    const response = await axios.get<IPriceRuleWithCostListInclusions[]>(
      `${this.url.href}/${programId}/cost-list-inclusion`,
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
