import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeletePlan {
  execute(planId: number, presenter: IDeletePlanPresenter, orgNode: number): Promise<void>;
}

export interface IDeletePlanPresenter {
  deletePlanResult(success: boolean): void;
}

export default class DeletePlan implements IDeletePlan {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(planId: number, presenter: IDeletePlanPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/planning/${planId}`;

    const requestConfig = this.config.getAuthConfig();

    try {
      await axios.delete(url.href, requestConfig).then((response) => {
        if (response.status === 200) {
          presenter.deletePlanResult(true);
        } else {
          presenter.deletePlanResult(false);
        }
      });
    } catch (e) {
      // TODO: Error Logging
      presenter.deletePlanResult(false);
    }
  }
}
