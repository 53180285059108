import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import { IProduct } from 'deprecated/Boundary/GetProductDetails';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetProductPresenter {
  getProductSuccess(product: IProduct): void;
  getProductFailed(error: AxiosError): void;
}

export interface IGetProductDetail {
  execute(presenter: IGetProductPresenter, productId: number): Promise<void>;
}

export default class GetProductDetail {
  public config: IGetTokenFromLocalStorage;

  private hostname: URL;

  private orgNodeCode: string;

  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = new URL(hostname);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: IGetProductPresenter, productId: number) {
    const getProductDetailUrl = new URL(this.hostname.href);
    getProductDetailUrl.pathname = `api/canonicalproduct/${encodeURIComponent(productId)}`;
    getProductDetailUrl.searchParams.set('orgNodeCode', this.orgNodeCode);

    try {
      const response = await axios.get<IProduct>(
        getProductDetailUrl.href,
        this.config.getAuthConfig(),
      );

      presenter.getProductSuccess(response.data);
    } catch (e) {
      if (isAxiosError(e) && presenter?.getProductFailed) {
        presenter.getProductFailed(e);
      }
    }
  }
}
