import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IdNamePair } from 'deprecated/Domain/IdNamePair';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetPriceRuleNetBilledPriceListSources {
  execute(): Promise<IdNamePair<string>[]>;
}

export default class GetPriceRuleNetBilledPriceListSources
  implements IGetPriceRuleNetBilledPriceListSources
{
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(): Promise<IdNamePair<string>[]> {
    const url = new URL(this.hostname);
    url.pathname = '/api/system/referenceData/priceRuleNetBilledPriceListSources';

    const response = await axios.get(url.href, this.config.getAuthConfig());

    return response.data;
  }
}
