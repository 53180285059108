import React from 'react';

import { ENV_CONFIG_FRAGMENT_TYPE, EnvironmentType } from 'fragments/Config';

const EnvConfigContext = React.createContext<ENV_CONFIG_FRAGMENT_TYPE>({
  api_url: '',
  ai_instrumentation_key: '',
  blog_storage_url: '',
  azure_file_domain: '',
  azure_assets_domain: '',
  azure_downloads_domain: '',
  node_env: 'development',
  env_name: EnvironmentType.Local,
  sentryDsn: '',
  sentryEnvironment: '',
  sentryTracesSampleRate: 0,
  releaseName: '',
  mapBoxApiKey: '',
  mapBoxStyle: '',
  reportIdMargin: undefined,
});

export default EnvConfigContext;

const EnvConfigProvider = EnvConfigContext.Provider;
const EnvConfigConsumer = EnvConfigContext.Consumer;

export { EnvConfigProvider, EnvConfigConsumer };
