import {
  QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryListPrice,
  QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryProduct,
} from '__generated__/api/notifications/shared-program-notifications/{notificationId}/data-contracts';

export interface IFlattenedSharedProgramNotificationProduct {
  productId?: number;
  productName?: string;
  packageSize?: string;
  isRepack?: boolean;
  supplierId?: number;
  supplierName?: string;
  unitOfMeasure?: string;
  onInvoiceCost?: number;
  potentialNetCost?: number;
  expectedNetCost?: number;
  programOnInvoiceRebateAmount?: number;
  programPotentialRebateAmount?: number;
  programExpectedRebateAmount?: number;
  listPrice?: QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryListPrice;
}

export default function flattenSharedProgramNotificationProducts(
  products: QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryProduct[],
) {
  const result: QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryProduct[] = [];

  // For each product: top row has base product data + supplier nets, remaining rows have supplier nets only.
  for (const product of products) {
    const topRow: IFlattenedSharedProgramNotificationProduct = {
      productId: product.productId,
      productName: product.productName,
      packageSize: product.packageSize,
      isRepack: product.isRepack,
    };

    result.push(topRow);

    if (!(product.netCosts && product.netCosts.length > 0)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (product.netCosts && product.netCosts.length > 0) {
      topRow.supplierId = product.netCosts[0].supplier?.id;
      topRow.supplierName = product.netCosts[0].supplier?.name;
      topRow.unitOfMeasure = product.netCosts[0].unitOfMeasure;
      topRow.listPrice = product.netCosts[0].listPrice;
      topRow.onInvoiceCost = product.netCosts[0].onInvoiceAdjustedPrice;
      topRow.potentialNetCost = product.netCosts[0].potentialAdjustedPrice;
      topRow.expectedNetCost = product.netCosts[0].expectedAdjustedPrice;
      topRow.programOnInvoiceRebateAmount = product.netCosts[0].programOnInvoiceRebateAmount;
      topRow.programPotentialRebateAmount = product.netCosts[0].programPotentialRebateAmount;
      topRow.programExpectedRebateAmount = product.netCosts[0].programExpectedRebateAmount;
    }

    for (let i = 1; i < product.netCosts.length; i++) {
      const nets = product.netCosts[i];
      const flattenedProduct: IFlattenedSharedProgramNotificationProduct = {
        supplierId: nets.supplier?.id,
        supplierName: nets.supplier?.name,
        unitOfMeasure: nets.unitOfMeasure,
        listPrice: nets.listPrice,
        onInvoiceCost: nets.onInvoiceAdjustedPrice,
        potentialNetCost: nets.potentialAdjustedPrice,
        expectedNetCost: nets.expectedAdjustedPrice,
        programOnInvoiceRebateAmount: nets.programOnInvoiceRebateAmount,
        programPotentialRebateAmount: nets.programPotentialRebateAmount,
        programExpectedRebateAmount: nets.programExpectedRebateAmount,
      };

      result.push(flattenedProduct);
    }
  }

  return result;
}
