import { renderTooltip } from '../../InternalProgramNotification/utils/renderTooltip';
import ListPriceColumn from '../../columns/ListPriceColumn/ListPriceColumn';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';

import { formatPriceWithUom } from 'utils/format/priceWithUom';

import { IFlattenedSharedProgramNotificationProduct } from './flattenSharedProgramNotificationProducts';

export default function sharedProgramNotificationColumns(
  canViewCosts: boolean,
  canViewAdvancedCosts: boolean,
  showNets: boolean,
  viewGlobalNets: boolean,
): ColumnsType<IFlattenedSharedProgramNotificationProduct> {
  const columns: ColumnType<IFlattenedSharedProgramNotificationProduct>[] = [
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Product Name',
      width: 140,
      render: (_, row) => {
        return row.productName;
      },
    },
    {
      dataIndex: 'packageSizeLevel',
      key: 'packageSizeLevel',
      title: 'Package Size Level',
      width: 70,
      render: (_, row) => {
        return `${row.packageSize}${row.isRepack ? ' REPACK' : ''}`;
      },
    },
    {
      dataIndex: 'supplier',
      key: 'supplier',
      title: 'Supplier',
      width: 100,
      render: (_, row) => {
        return row.supplierName ?? 'N/A';
      },
    },
    {
      dataIndex: 'listPrice',
      key: 'listPrice',
      title: 'List Price',
      width: 70,
      render: (_, row) => {
        return (
          <ListPriceColumn
            notificationType="SharedProgram"
            listPrice={row.listPrice?.price}
            unitOfMeasure={row.unitOfMeasure}
            hasNoPrice={row.listPrice?.hasNoPrice}
            validTo={dayjs(row?.listPrice?.validTo).utc().toDate()}
          />
        );
      },
    },
    {
      dataIndex: 'onInvoice',
      key: 'onInvoice',
      title: () => {
        if (viewGlobalNets) {
          return <span>On Invoice Cost</span>;
        }

        return <span>On Invoice Discount {renderTooltip()}</span>;
      },
      width: 100,
      render: (_, row) => {
        if (viewGlobalNets) {
          return formatPriceWithUom(row.onInvoiceCost, row.unitOfMeasure, true, true);
        }

        return formatPriceWithUom(row.programOnInvoiceRebateAmount, row.unitOfMeasure, true, true);
      },
    },
    {
      dataIndex: 'potential',
      key: 'potential',
      title: () => {
        if (viewGlobalNets) {
          return <span>Potential Net Cost</span>;
        }

        return <span>Potential Rebate Amount {renderTooltip()}</span>;
      },
      width: 100,
      render: (_, row) => {
        if (viewGlobalNets) {
          return formatPriceWithUom(row.potentialNetCost, row.unitOfMeasure, true, true);
        }

        return formatPriceWithUom(row.programPotentialRebateAmount, row.unitOfMeasure, true, true);
      },
    },
    {
      dataIndex: 'expected',
      key: 'expected',
      title: () => {
        if (viewGlobalNets) {
          return <span>Expected Net Cost</span>;
        }

        return <span>Expected Rebate Amount {renderTooltip()}</span>;
      },
      width: 100,
      render: (_, row) => {
        if (viewGlobalNets) {
          return formatPriceWithUom(row.expectedNetCost, row.unitOfMeasure, true, true);
        }

        return formatPriceWithUom(row.programExpectedRebateAmount, row.unitOfMeasure, true, true);
      },
    },
  ];

  return filterColumns(columns, canViewCosts, canViewAdvancedCosts, showNets);
}

const filterColumns = (
  columns: ColumnsType<IFlattenedSharedProgramNotificationProduct>,
  canViewCosts: boolean,
  canViewAdvancedCosts: boolean,
  showNets: boolean,
) => {
  const result = columns;

  return result.filter(
    (c) =>
      ((showNets && canViewCosts) || c.key !== 'supplier') &&
      ((showNets && canViewCosts) || c.key !== 'listPrice') &&
      ((showNets && canViewCosts) || c.key !== 'onInvoice') &&
      ((showNets && canViewCosts) || c.key !== 'expected') &&
      ((showNets && canViewCosts && canViewAdvancedCosts) || c.key !== 'potential'),
  );
};
