import { LoginError } from '../LoginError';
import { useOktaAuth } from '@okta/okta-react';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import useSearchParams from '@smartwyre/hooks/lib/useSearchParams';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useNotificationSuppression from 'hooks/notifications/useNotificationSuppression';

const CustomLoginCallback = () => {
  const { oktaAuth } = useOktaAuth();
  const location = useLocation();
  const params = useSearchParams<{
    error_description: string | undefined;
  }>(location.search);

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
        await oktaAuth.handleLoginRedirect();
      } catch (error) {
        await oktaAuth.signOut();
      }
    };
    if (!params.error_description) {
      handleAuthentication();
    }
  }, [oktaAuth, params.error_description]);

  const { update } = useNotificationSuppression();

  useEffect(() => {
    update(false);
  }, [update]);

  // If an error is present from the authorize call then send the user to an error page instead of back to login without context.
  if (params.error_description) {
    return <LoginError error={params.error_description} />;
  }

  return <LoadRetry isLoading cover />;
};

export default CustomLoginCallback;
