import { prettyDateFormatter } from 'utils/dateFormats/dateFormats';

import styles from './PriceListDetails.module.scss';

interface IPriceListDetails {
  validFrom?: string;
  expiryDate?: string;
}

const PriceListDetails = ({ validFrom, expiryDate }: IPriceListDetails) => {
  return (
    <div className={styles.detailsContainer}>
      {!!validFrom && (
        <div>
          <strong>Valid From: </strong>
          <span>{prettyDateFormatter(validFrom)}</span>
        </div>
      )}
      {!!expiryDate && (
        <div>
          <strong>Expiry Date: </strong>
          <span>{prettyDateFormatter(expiryDate)}</span>
        </div>
      )}
    </div>
  );
};

export default PriceListDetails;
