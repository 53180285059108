import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { lazy, Suspense } from 'react';

const Password = lazy(() => import('components/Authentication/StartResetPassword'));

const PasswordContainer = () => (
  <Suspense fallback={<LoadRetry isLoading cover />}>
    <Password />
  </Suspense>
);

export default PasswordContainer;
