import { ThemeContext } from '../ThemeContext';
import InlineLoadRetry from '@smartwyre/components/lib/InlineLoadRetry';
import { useMemo } from 'react';

import { useSettingsThemeDetailQuery } from '__generated__/api/{orgNodeCode}/settings/theme/Api';

import { determineOrgNodeCode } from 'utils/organizations/determineOrgNodeCode';

import { useOrganization } from 'contexts/organizations/OrganizationContext/hooks/useOrganization';
import { initFullTheme } from 'contexts/organizations/ThemeContext/utils/initFullTheme';

export interface InitThemeContextProps {
  children: React.ReactNode;
}

const InitThemeContext = ({ children }: InitThemeContextProps) => {
  const orgNodeCode = determineOrgNodeCode();
  const orgNode = useOrganization();
  const queryResult = useSettingsThemeDetailQuery(
    { orgNodeCode },
    {
      retry: 3,
      staleTime: Infinity, // theme is always fresh unless current user updates, other users will see the change on another session
      cacheTime: Infinity,
    },
  );

  const fullTheme = useMemo(() => {
    /**
     * The response types are generated and without editing api code they are nullable
     * This is a guard clause to ensure that the data is not null
     */
    if (
      !queryResult.data?.data ||
      !queryResult.data?.data.headerLogo ||
      !queryResult.data?.data.loginLogo ||
      !queryResult.data?.data.loginBackground ||
      !queryResult.data?.data.primaryColor ||
      !queryResult.data?.data.secondaryColor ||
      !queryResult.data?.data.tertiaryColor ||
      !queryResult.data?.data.headerTextColor ||
      !queryResult.data?.data.loginTextColor
    ) {
      return null;
    }

    return initFullTheme(
      {
        primaryColor: queryResult.data.data.primaryColor,
        secondaryColor: queryResult.data.data.secondaryColor,
        tertiaryColor: queryResult.data.data.tertiaryColor,
        headerTextColor: queryResult.data.data.headerTextColor,
        loginTextColor: queryResult.data.data.loginTextColor,
        useLoginBottomGradient: queryResult.data.data.useLoginBottomGradient,
        headerLogo: queryResult.data.data.headerLogo,
        loginLogo: queryResult.data.data.loginLogo,
        loginBackground: queryResult.data.data.loginBackground,
      },
      orgNode,
    );
  }, [orgNode, queryResult.data?.data]);

  return (
    <InlineLoadRetry
      queryResult={queryResult}
      dataAccessor={() => fullTheme}
      loadRetryProps={{ cover: true }}
    >
      {(fullThemeNonNull) => {
        return <ThemeContext.Provider value={fullThemeNonNull}>{children}</ThemeContext.Provider>;
      }}
    </InlineLoadRetry>
  );
};

export default InitThemeContext;
