import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { lazy, Suspense } from 'react';

import PageTitle from 'utils/pageTitle/PageTitle';

const Privacy = lazy(() => import('./index'));

const PrivacyContainer = () => (
  <Suspense fallback={<LoadRetry isLoading cover />}>
    <PageTitle disableOrgName>Privacy</PageTitle>
    <Privacy />
  </Suspense>
);

export default PrivacyContainer;
