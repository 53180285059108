import SelectOutlined from '@ant-design/icons/SelectOutlined';
import Card from '@smartwyre/components/lib/Card';
import Tag from 'antd/lib/tag';
import React from 'react';

import { QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryCostList } from '__generated__/api/notifications/shared-program-notifications/{notificationId}/data-contracts';

export interface ISharedProgramNotificationCostListsProps {
  costLists: QueriesGetSharedProgramNotificationGetSharedProgramNotificationQueryCostList[];
  isEligible: boolean;
}

export const SharedProgramNotificationCostLists = ({
  costLists,
  isEligible,
}: ISharedProgramNotificationCostListsProps) => {
  if (!isEligible) {
    return (
      <Card>
        This program is shared with you, but you are not eligible to it. This update will not affect
        your product nets.
      </Card>
    );
  }

  if (costLists.length === 0) {
    return <Card>No cost lists available to display nets for.</Card>;
  }

  return (
    <div>
      <h5>
        <strong>Affected Cost Lists:</strong>
      </h5>
      {costLists.map((cl) => (
        <Tag>
          <a href={`/cost-lists?id=${cl.id}`} target="_blank" rel="noopener noreferrer">
            {`${cl.name} ` ?? ''}
            <SelectOutlined rotate={90} />
          </a>
        </Tag>
      ))}
    </div>
  );
};

export default SharedProgramNotificationCostLists;
