import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import { IQuote } from 'deprecated/Boundary/GetQuote';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetQuote {
  execute(id: number): Promise<AxiosResponse<IQuote>>;
}

export default class GetQuote implements IGetQuote {
  public config: IGetTokenFromLocalStorage;

  private hostname: URL;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = new URL(hostname);
    this.config = config;
  }

  public async execute(id: number) {
    const url = new URL(this.hostname.href);
    url.pathname = `api/quotes/${encodeURIComponent(id)}`;

    return axios.get<IQuote>(url.href, this.config.getAuthConfig());
  }
}
