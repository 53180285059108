import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAddSegmentToProgram {
  execute: (
    programId: number,
    segmentId: number | null,
    presenter: IAddSegmentToProgramPresenter,
  ) => void;
}

export interface IAddSegmentToProgramPresenter {
  programSegmentSaved: () => void;
  programSegmentNotSaved: () => void;
}

export default class AddSegmentToProgram implements IAddSegmentToProgram {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (
    programId: number,
    segmentId: number | null,
    presenter: IAddSegmentToProgramPresenter,
  ) => {
    const url = new URL(this.hostname);

    url.pathname = `/api/programs/${programId}/segments/${segmentId}`;

    try {
      await axios.post(url.href, {}, this.config.getAuthConfig());

      presenter.programSegmentSaved();
    } catch (error) {
      presenter.programSegmentNotSaved();
    }
  };
}
