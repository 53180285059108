import axios from '@smartwyre/utils/lib/authenticatedAxios';
import queryString from 'query-string';

import ApiResponse from 'fragments/common/ApiResponse';

import { UnmappedAlert } from 'deprecated/Boundary/GetAlerts';
import { IAlertFilterRequest } from 'deprecated/Boundary/GetAlerts/IAlertFilter';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAlertUnlinkedProducts {
  execute(id: number, filter?: IAlertFilterRequest | null): Promise<ApiResponse<UnmappedAlert>>;
}

export class GetAlertUnlinkedProducts implements IGetAlertUnlinkedProducts {
  private readonly hostname: string;

  private readonly config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute(
    id: number,
    filter?: IAlertFilterRequest | null,
  ): Promise<ApiResponse<UnmappedAlert>> {
    const url = new URL(this.hostname);
    url.pathname = `api/alerts/${id}/unlinked/transactions/products`;

    const urlWithQuery = queryString.stringifyUrl({
      url: url.toString(),
      query: { ...filter },
    });

    return axios
      .get<ApiResponse<UnmappedAlert>>(urlWithQuery, this.config.getAuthConfig())
      .then((response) => {
        return response.data;
      });
  }
}
