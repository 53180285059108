import axios from 'axios';
import queryString from 'query-string';

import ApiResponse from 'fragments/common/ApiResponse';

import { PurchaseUnlinkedCounterPartiesAlert } from 'deprecated/Boundary/GetAlerts';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAlertPurchasesUnlinkedCounterParties {
  execute(
    id: number,
    offset: number,
    limit: number,
  ): Promise<ApiResponse<PurchaseUnlinkedCounterPartiesAlert>>;
}

export class GetAlertPurchasesUnlinkedCounterParties
  implements IGetAlertPurchasesUnlinkedCounterParties
{
  private readonly hostname: string;

  private readonly config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute(
    id: number,
    offset: number,
    limit: number,
  ): Promise<ApiResponse<PurchaseUnlinkedCounterPartiesAlert>> {
    const url = new URL(this.hostname);
    url.pathname = `api/alerts/${id}/unlinked/transactions/purchases/counter-parties`;
    url.searchParams.append('offset', offset.toString());
    url.searchParams.append('limit', limit.toString());

    const urlWithQuery = queryString.stringifyUrl({
      url: url.toString(),
    });

    return axios
      .get<ApiResponse<PurchaseUnlinkedCounterPartiesAlert>>(
        urlWithQuery,
        this.config.getAuthConfig(),
      )
      .then((response) => {
        return response.data;
      });
  }
}
