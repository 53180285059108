import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { INetworkGroup } from 'deprecated/Components/SettingsList/Preferences/NetworkGroups';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllNetworkGroupsForOrg {
  execute(presenter: IGetAllNetworkGroupsForOrgPresenter): void;
}

export interface IGetAllNetworkGroupsForOrgPresenter {
  setLabels(labels: INetworkGroup[]): void;
}

export default class GetAllNetworkGroupsForOrg implements IGetAllNetworkGroupsForOrg {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: IGetAllNetworkGroupsForOrgPresenter) {
    const url = new URL(this.host.href);

    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/network-groups`;

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());
      presenter.setLabels(response.data.networkGroups);
    } catch (error) {
      console.error(error);
    }
  }
}
