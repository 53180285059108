import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IUnitOfMeasureConversionFactor } from 'deprecated/Domain/IUnitOfMeasureConversionFactor';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetUnitOfMeasureConversionFactors {
  execute(): Promise<IUnitOfMeasureConversionFactor[]>;
}

export class GetUnitOfMeasureConversionFactors
  extends RemoteUseCaseBase
  implements IGetUnitOfMeasureConversionFactors
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/unitOfMeasures/conversions', orgNodeCode, config);
  }

  public execute(): Promise<IUnitOfMeasureConversionFactor[]> {
    return axios
      .get<IUnitOfMeasureConversionFactor[]>(this.url.href, this.config.getAuthConfig())
      .then((response) => response.data);
  }
}
