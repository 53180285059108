import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import {
  ForecastPlanTransactionSummary,
  ValidTransactionType,
} from 'deprecated/Components/Forecast/TransactionEditor/types';
import SalesType from 'deprecated/Constants/SalesType';
import { IdNamePair } from 'deprecated/Domain/IdNamePair';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export type ForecastPlanEditor = {
  forecastPeriodStart: Date;
  forecastPeriodEnd: Date;
  forecastCutoverDate: Date;
  products: ProductRow[];
  counterParties: IdNamePair<number>[];
  locations: IdNamePair<number>[];
};

export type ProductRowPriorYearSummary = {
  averagePrice: number;
  value: number;
  volume: number;
};

export type ProductRow = {
  brandId: number;
  productOwnerId: number;
  productTypeId: number;

  productId: number;
  organizationProductIdentifier: string;
  transactionUom: string;
  description: string;

  counterPartyNames: string[];
  locationNames: string[];

  salesType: SalesType | null;
  beginningInventory: number;
  forecastSales: number;

  priorYearProductSummary: ProductRowPriorYearSummary;

  transactionGroups: TransactionGroups[];
};

export type ForecastMonth = {
  transactionId: number;
  month: number;
  year: number;
  quantity: number | null;
  price: number | null;
};

type TransactionGroups = {
  orgNodeIdentifierForCounterParty: string | null;
  counterPartyId: number | null;
  counterPartyName: string | null;
  locationId: number;
  locationName: string;
  months: ForecastMonth[];
  beginningInventory: number;
  forecastSales: number;
};

export type CreateTransaction = {
  productId: number;
  uom: string;
  month: number;
  year: number;
  counterPartyId: number | null;
  locationId: number;
  organizationProductIdentifier: string;
  orgNodeIdentifierForCounterParty: string | null;
  salesType: SalesType | null;
};

export type UpdateTransaction = {
  id: number;
  quantity: number;
  price: number;
};

export type UpsertTransactions = {
  transactionsToCreate: (CreateTransaction & { quantity: number; price: number })[];
  transactionsToUpdate: UpdateTransaction[];
};

export type RecommendedPriceRequest = {
  counterPartyId: number;
  year: number;
  productId: number;
  transactionType: string;
  uom: string;
};

export type WholesaleCustomerList = {
  wholesaleCustomers: IdNamePair<number>[];
};

export type SupplierList = {
  suppliers: IdNamePair<number>[];
};

export interface IForecastPlanTransactionSummaryResponse {
  priorPeriodDateFrom: Date;
  priorPeriodDateTo: Date;
  forecastPeriodDateFrom: Date;
  forecastPeriodDateTo: Date;
  forecastPlanTransactionSummaries: ForecastPlanTransactionSummary[];
}

export type ProgramOwnerEarning = {
  programOwner: string;
  forecastEarnings: number;
  priorYearDelta: number;
};

export interface IEarningsByProgramOwnerResponse {
  earningsByProgramOwner: ProgramOwnerEarning[];
}

export interface IForecastPlanService {
  getForecastTransactions(
    forecastPlanId: number,
    transactionType: ValidTransactionType,
    salesType?: SalesType,
    counterPartyId?: number | null,
    locationId?: number | null,
  ): Promise<ForecastPlanEditor>;
  upsertForecastTransactions(
    forecastPlanId: number,
    transactionType: ValidTransactionType,
    upsertTransactions: UpsertTransactions,
  ): Promise<void>;
  getRecommendedPrice(request: RecommendedPriceRequest): Promise<number | null>;
  getWholesaleSaleCustomers(forecastPlanId: number): Promise<WholesaleCustomerList>;
  getPurchaseForecastSuppliers(forecastPlanId: number): Promise<SupplierList>;
  getForecastPlanSummary(
    forecastPlanId: number,
    transactionType: string | null,
  ): Promise<IForecastPlanTransactionSummaryResponse>;
  getForecastEarningsByProgramOwner(
    forecastPlanId: number,
  ): Promise<IEarningsByProgramOwnerResponse>;
}

export default class ForecastPlanService implements IForecastPlanService {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async getForecastTransactions(
    forecastPlanId: number,
    transactionType: string,
    salesType?: SalesType,
    counterPartyId?: number,
    locationId?: number,
  ): Promise<ForecastPlanEditor> {
    const url = new URL(this.host.href);
    url.pathname = `api/planning/forecast-transactions/${forecastPlanId}/${transactionType}`;

    const requestConfig = {
      ...this.config.getAuthConfig(),
      params: {
        transactionTypeOfSale: salesType,
        counterPartyId,
        locationId,
      },
    };

    try {
      const response = await axios.get<ForecastPlanEditor>(url.href, requestConfig);
      return Promise.resolve(response.data);
    } catch (e) {
      const error = e as AxiosError<ForecastPlanEditor>;
      return Promise.reject(error);
    }
  }

  public async upsertForecastTransactions(
    forecastPlanId: number,
    transactionType: string,
    upsertTransactions: UpsertTransactions,
  ): Promise<void> {
    const url = new URL(this.host.href);
    url.pathname = `api/planning/forecast-transactions/${forecastPlanId}/${transactionType}`;

    try {
      await axios.post(url.href, upsertTransactions, this.config.getAuthConfig());
      return Promise.resolve();
    } catch (e) {
      const error = e as AxiosError<ForecastPlanEditor>;
      return Promise.reject(error);
    }
  }

  public async getRecommendedPrice(request: RecommendedPriceRequest): Promise<number | null> {
    const url = new URL(this.host.href);
    url.pathname = 'api/planning/recommended-price';
    for (const key in request) {
      const value = request[key as keyof RecommendedPriceRequest];
      url.searchParams.set(key, value.toString());
    }

    try {
      const { data } = await axios.get<number | null>(url.href, this.config.getAuthConfig());
      return Promise.resolve(data);
    } catch (e) {
      const error = e as AxiosError<ForecastPlanEditor>;
      return Promise.reject(error);
    }
  }

  public async getWholesaleSaleCustomers(forecastPlanId: number): Promise<WholesaleCustomerList> {
    const url = new URL(this.host.href);
    url.pathname = `api/planning/${forecastPlanId}/wholesale-customers`;

    try {
      const response = await axios.get<WholesaleCustomerList>(
        url.href,
        this.config.getAuthConfig(),
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const error = e as AxiosError<WholesaleCustomerList>;
      return Promise.reject(error);
    }
  }

  public async getPurchaseForecastSuppliers(forecastPlanId: number): Promise<SupplierList> {
    const url = new URL(this.host.href);
    url.pathname = `api/planning/${forecastPlanId}/suppliers`;

    try {
      const response = await axios.get<SupplierList>(url.href, this.config.getAuthConfig());
      return Promise.resolve(response.data);
    } catch (e) {
      const error = e as AxiosError<SupplierList>;
      return Promise.reject(error);
    }
  }

  public async getForecastPlanSummary(
    forecastPlanId: number,
    transactionType: string | null,
  ): Promise<IForecastPlanTransactionSummaryResponse> {
    const url = new URL(this.host.href);
    url.pathname = `api/planning/${forecastPlanId}/summary`;

    const requestConfig = {
      ...this.config.getAuthConfig(),
      params: {
        transactionType,
      },
    };

    try {
      const response = await axios.get<IForecastPlanTransactionSummaryResponse>(
        url.href,
        requestConfig,
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const error = e as AxiosError;
      return Promise.reject(error);
    }
  }

  public async getForecastEarningsByProgramOwner(
    forecastPlanId: number,
  ): Promise<IEarningsByProgramOwnerResponse> {
    const url = new URL(this.host.href);
    url.pathname = `api/planning/${forecastPlanId}/summary/earnings`;

    try {
      const response = await axios.get<IEarningsByProgramOwnerResponse>(
        url.href,
        this.config.getAuthConfig(),
      );
      return Promise.resolve(response.data);
    } catch (e) {
      const error = e as AxiosError<IEarningsByProgramOwnerResponse>;
      return Promise.reject(error);
    }
  }
}
