import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ISimplifiedPriceRule } from 'deprecated/Domain/PriceRule/ISimplifiedPriceRule';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetPriceRulesWithRelatedProductsByProgramAndProductIdsPresenter {
  onSuccess: (priceRules: ISimplifiedPriceRule[]) => void;
  onFailure: () => void;
}

export interface IGetPriceRulesWithRelatedProductsByProgramAndProductIds {
  execute: (
    presenter: IGetPriceRulesWithRelatedProductsByProgramAndProductIdsPresenter,
    programId: string,
    productIds: number[],
  ) => void;
}

export class GetPriceRulesWithRelatedProductsByProgramAndProductIds
  implements IGetPriceRulesWithRelatedProductsByProgramAndProductIds
{
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    presenter: IGetPriceRulesWithRelatedProductsByProgramAndProductIdsPresenter,
    programId: string,
    productIds: number[],
  ) {
    const url = new URL(this.hostname);
    url.pathname = `/api/price-rules/related-products/${programId}`;
    const headers = this.config.getAuthConfig().headers;

    try {
      const response = await axios.post<{
        priceRules: ISimplifiedPriceRule[];
      }>(url.href, productIds, { headers });
      presenter.onSuccess(response.data.priceRules);
    } catch (error) {
      presenter.onFailure();
    }
  }
}
