import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';

import { useUsersDefaultNodesListQuery } from '__generated__/api/users/default-nodes/Api';

import { redirectToDefaultOrgNode } from 'utils/routing/redirectToDefaultOrgNode';

import UnconfiguredDefaultNodeRoute from './UnconfiguredDefaultNodeRoute';

const DefaultNodeRoute = () => {
  const { smartwyreUserClaims } = useAuthentication();
  const userDefaultNodes = useUsersDefaultNodesListQuery({});

  if (userDefaultNodes.isError || !userDefaultNodes?.data?.data?.defaultNodes) {
    return <LoadRetry {...userDefaultNodes} cover />;
  }

  if (smartwyreUserClaims?.organizationid) {
    // fetch routing nodes and match routing node to current node
    const nodeCode = userDefaultNodes.data.data.defaultNodes.find(
      (x) => x.routingNodeId === smartwyreUserClaims?.organizationid,
    )?.defaultNodeCode;
    // if a default node exists then redirect
    if (nodeCode) {
      redirectToDefaultOrgNode(nodeCode);
    }
    // user has been misconfigured, render UnconfiguredDefaultNodeRoute for fallback
    else {
      return <UnconfiguredDefaultNodeRoute />;
    }
  }

  return <LoadRetry isLoading cover />;
};

export default DefaultNodeRoute;
