import IPriceListNotificationType from '../../types/IPriceListNotificationType';
import { IPriceListNotificationFilters } from '../InternalPriceListNotification/InternalPriceListNotification';
import NewPriceColumn from '../columns/NewPriceColumn/NewPriceColumn';
import { PriceChangeColumn } from '../columns/PriceChangeColumn/PriceChangeColumn';
import Table from '@smartwyre/components/lib/Table';
import type { PaginationFragment } from '@smartwyre/components/lib/Table/fragments/Pagination';
import getPaginationOffset from '@smartwyre/utils/lib/getPaginationOffset';
import getPaginationPage from '@smartwyre/utils/lib/getPaginationPage';
import { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { QueriesGetInternalPriceListNotificationGetInternalPriceListNotificationQueryPriceChange } from '__generated__/api/notifications/internal-price-list-notifications/{notificationId}/data-contracts';
import { QueriesGetSharedPriceListNotificationGetSharedPriceListNotificationQueryPriceChange } from '__generated__/api/notifications/shared-price-list-notifications/{notificationId}/data-contracts';

import { formatPriceWithUom } from 'utils/format/priceWithUom';

import styles from './PriceListNotificationTable.module.scss';

export type NotificationPriceRow =
  | QueriesGetSharedPriceListNotificationGetSharedPriceListNotificationQueryPriceChange
  | QueriesGetInternalPriceListNotificationGetInternalPriceListNotificationQueryPriceChange;

interface IPriceListNotificationTable extends IPriceListNotificationType {
  prices: NotificationPriceRow[];
  totalCount: number;
  loading: boolean;
  selectedFilters: IPriceListNotificationFilters;
  setSelectedFilters: Dispatch<SetStateAction<IPriceListNotificationFilters>>;
}

const PriceListNotificationTable = ({
  prices,
  totalCount,
  loading,
  priceListNotificationType,
  selectedFilters,
  setSelectedFilters,
}: IPriceListNotificationTable) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Table
      dataSource={prices}
      rowKey={(r) => r.productId ?? `${r.productName}-${r.myProductIdentifier}-${r.packageSize}`}
      loading={loading}
      pagination={{
        current: getPaginationPage(Number(selectedFilters.offset), Number(selectedFilters.limit)),
        pageSize: Number(selectedFilters.limit),
        showSizeChanger: true,
        total: totalCount,
        onChange: (page, onChangeLimit) => {
          const newPagination: PaginationFragment = { offset: '0', limit: undefined };
          const newOffset = getPaginationOffset(page, onChangeLimit);

          if (newOffset > 1) {
            newPagination.offset = String(newOffset);
          }

          if (onChangeLimit !== Number(Table.DEFAULT_LIMIT_SIZE)) {
            newPagination.limit = String(onChangeLimit);
          }

          setSelectedFilters({
            ...selectedFilters,
            offset:
              newPagination.offset === undefined
                ? selectedFilters.offset
                : Number(newPagination.offset),
            limit:
              newPagination.limit === undefined
                ? selectedFilters.limit
                : Number(newPagination.limit),
          });
        },
      }}
      className={styles.priceListNotificationTable}
      rowClassName={(row: NotificationPriceRow) => {
        return !row.productId ? styles.notHoverableRow : '';
      }}
      onRow={(row) => ({
        onClick: () => {
          if (row.productId) {
            navigate(`/products/${row.productId}/commercial`);
          }
        },
      })}
      searchParams={location.search}
    >
      <Table.Column<NotificationPriceRow>
        title="Product Name"
        render={(_, item) => {
          return item.productName;
        }}
      />
      <Table.Column<NotificationPriceRow>
        title="Package Size Level"
        render={(_, item) => {
          return item.packageSize;
        }}
      />
      <Table.Column<NotificationPriceRow>
        title="Product Owner"
        render={(_, item) => {
          return item.productOwner;
        }}
      />
      <Table.Column<NotificationPriceRow>
        title="My Product Identifier"
        render={(_, item) => {
          return item.myProductIdentifier;
        }}
      />
      <Table.Column<NotificationPriceRow>
        title="Last Price"
        render={(_, item) => formatPriceWithUom(item.oldPrice, item.unitOfMeasure, true)}
      />
      <Table.Column<NotificationPriceRow>
        title="New Price"
        render={(_, item) => (
          <NewPriceColumn row={item} notificationType={priceListNotificationType} />
        )}
      />
      <Table.Column<NotificationPriceRow>
        title="Change in New Price vs Last Price"
        render={(_, item) => <PriceChangeColumn item={item} showAs={selectedFilters.showAs} />}
      />
      {priceListNotificationType === 'SharedPriceList' && (
        <Table.Column<QueriesGetSharedPriceListNotificationGetSharedPriceListNotificationQueryPriceChange>
          title="Current Net Cost"
          render={(_, item) =>
            formatPriceWithUom(item.currentNetCost, item.unitOfMeasure, true, true)
          }
        />
      )}
    </Table>
  );
};

export default PriceListNotificationTable;
