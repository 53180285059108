import { IProductsApiGateway } from 'deprecated/Gateways/ProductsApiGateway';

import { IAttachRawDataToProduct } from './RemoteTypes';

export default class AttachRawDataToBrand implements IAttachRawDataToProduct {
  private readonly productGateway: IProductsApiGateway;

  public constructor(brandGateway: IProductsApiGateway) {
    this.productGateway = brandGateway;
  }

  public async execute(productId: string, rawDataId: string) {
    await this.productGateway.attachRawDataToProduct(productId, rawDataId);
  }
}
