import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ICashRebatePercentage } from 'deprecated/RemoteUseCases/GetCashRebatePercentage';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateCashRebatePercentage {
  execute(cashRebatePercentage: ICashRebatePercentage): Promise<void>;
}

export default class CreateCashRebatePercentage implements ICreateCashRebatePercentage {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(host: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.host = new URL(host);
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(cashRebatePercentage: ICashRebatePercentage): Promise<void> {
    const url = new URL(this.host.href);
    url.pathname = `api/price-rules/${cashRebatePercentage.priceRuleId}/cash-rebate-percentage`;

    try {
      await axios.post(
        url.href,
        { rebatePercentage: cashRebatePercentage.rebatePercentage.toString() },
        this.config.getAuthConfig(),
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
}
