import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IActivateProduct {
  execute: (productId: string, presenter: IPresentActivation) => void;
}

export interface IPresentActivation {
  active: () => void;
  inactive: () => void;
}

export default class ActivateProduct implements IActivateProduct {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(productId: string, presenter: IPresentActivation) {
    const url = new URL(this.hostname);
    url.pathname = `/api/canonicalProduct/${productId}/activate`;

    try {
      await axios.put(url.href, '', this.config.getAuthConfig());
      presenter.active();
    } catch {
      presenter.inactive();
    }
  }
}
