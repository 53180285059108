import { INotificationContent } from '../../../../NotificationContent';
import { IPriceListNotificationFilters } from '../InternalPriceListNotification/InternalPriceListNotification';
import PriceListDetails from '../PriceListDetails/PriceListDetails';
import PriceListNotificationsActions from '../PriceListNotificationActions/PriceListNotificationActions';
import PriceListNotificationFilters from '../PriceListNotificationFilters/PriceListNotificationFilters';
import PriceListNotificationTable from '../PriceListNotificationTable/PriceListNotificationTable';
import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import Table from '@smartwyre/components/lib/Table';
import { useState } from 'react';

import { useNotificationsSharedPriceListNotificationsDetailQuery } from '__generated__/api/notifications/shared-price-list-notifications/{notificationId}/Api';

const SharedPriceListNotification = ({ notification }: INotificationContent) => {
  const [selectedFilters, setSelectedFilters] = useState<IPriceListNotificationFilters>({
    limit: Number(Table.DEFAULT_LIMIT_SIZE),
    offset: 0,
    onlyPriceChanges: true,
    priceProductStatus: 'All',
    showAs: '$',
  });

  const notificationId = parseInt(`${notification.id}`, 10);
  const query = useNotificationsSharedPriceListNotificationsDetailQuery({
    query: {
      notificationId,
      ...selectedFilters,
    },
  });

  return (
    <div>
      <PriceListDetails
        validFrom={query.data?.data?.validFrom}
        expiryDate={query.data?.data?.expiryDate}
      />
      <PriceListNotificationFilters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      {query.isError || !query.data?.data ? (
        <Card>
          <LoadRetry {...query} />
        </Card>
      ) : (
        <PriceListNotificationTable
          prices={query.data.data.prices ?? []}
          totalCount={query.data.data.totalPriceCount ?? 0}
          loading={query.isLoading}
          priceListNotificationType="SharedPriceList"
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
      <PriceListNotificationsActions
        notification={notification}
        priceListNotificationType="SharedPriceList"
      />
    </div>
  );
};

export default SharedPriceListNotification;
