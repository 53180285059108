import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { NetworkGroupWithOrganizations } from 'deprecated/Boundary/NetworkGroups';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetNetworkGroupsByNetworkGroupIds {
  execute(ids: number[]): Promise<NetworkGroupWithOrganizations[]>;
}

export class GetNetworkGroupsByNetworkGroupIds implements IGetNetworkGroupsByNetworkGroupIds {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(ids: number[]): Promise<NetworkGroupWithOrganizations[]> {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${this.orgNodeCode}/network-groups/by-id`;
    ids.forEach((id) => url.searchParams.append('id', id.toString()));

    const { data } = await axios.get<NetworkGroupWithOrganizations[]>(
      url.href,
      this.config.getAuthConfig(),
    );

    return data;
  }
}
