import {
  IChangePassword,
  IChangePasswordPayload,
  IChangePasswordPresenter,
} from '../../Boundary/ChangePassword';

import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class ChangePassword implements IChangePassword {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute(payload: IChangePasswordPayload, presenter: IChangePasswordPresenter) {
    try {
      const response = await this.gateway.changePassword(payload);
      presenter.success(response);
    } catch (error) {
      console.error(error);
      presenter.failure(error);
    }
  }
}
