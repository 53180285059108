import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ILocationWithFipsCode } from 'deprecated/Domain/ILocation';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetLocationsSharedByOrganisation {
  execute(
    presenter: IGetLocationsSharedByOrganisationPresenter,
    organisationIds: (string | number)[],
  ): void;
}

export interface IGetLocationsSharedByOrganisationPresenter {
  presentSharedLocations(locations: ILocationWithFipsCode[]): void;
}

export default class GetLocationsSharedByOrganisation implements IGetLocationsSharedByOrganisation {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    presenter: IGetLocationsSharedByOrganisationPresenter,
    organisationIds: (string | number)[],
  ) {
    const url = new URL(this.hostname);
    url.pathname = '/api/location/shared';
    organisationIds.forEach((x) =>
      url.searchParams.append('organisationIds', typeof x === 'string' ? x : x.toString()),
    );

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());
      presenter.presentSharedLocations(response.data.locations);
    } catch {}
  }
}
