import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IGetOrganisation from 'deprecated/Boundary/IGetOrganisation';
import IOrganisation from 'deprecated/Domain/IOrganisation';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IOrganisationApiResponse {
  organisation: IOrganisation | null;
}

export default class GetOrganisation implements IGetOrganisation {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private hostname: URL,
    public config: IGetTokenFromLocalStorage, // eslint-disable-next-line no-empty-function
  ) {}

  public async execute(orgNodeCode: string) {
    const url = new URL(this.hostname.href);
    url.pathname = `api/organizations/${orgNodeCode}/details`;

    const requestConfig = this.config.getAuthConfig();

    const response = await axios.get<IOrganisationApiResponse>(url.href, requestConfig);

    return response.data.organisation;
  }
}
