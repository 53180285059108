import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import IDvfName from 'deprecated/Domain/IDvfName';
import { handleAxiosError } from 'deprecated/Utilities/Errors';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetDvfNamesForOrganisation {
  execute(presenter: IGetDvfNamesForOrganisationPresenter, ownerId?: string): Promise<void>;
}

export interface IGetDvfNamesForOrganisationPresenter {
  dvfNames(dvfNames: IDvfName[]): void;
}

export default class GetDvfNamesForOrganisation implements IGetDvfNamesForOrganisation {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(presenter: IGetDvfNamesForOrganisationPresenter, ownerId?: string) {
    try {
      const url = new URL(this.hostname);
      url.pathname = '/api/price-rules/defined-volume-formula-names';
      let config = null;
      if (ownerId) {
        config = {
          headers: this.config.getAuthConfig().headers,
          params: {
            priceRuleOwnerId: Number(ownerId),
          },
        };
      } else {
        config = {
          headers: this.config.getAuthConfig().headers,
        };
      }

      const response = await axios.get(url.href, config);
      presenter.dvfNames(response.data.dvfNames);
    } catch (e) {
      handleAxiosError(e as AxiosError, 'Something went wrong, please reload the page');
      presenter.dvfNames([]);
    }
  }
}
