import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import Button, { ButtonTypes } from '@smartwyre/components/lib/Button/Button';
import useSearchParams from '@smartwyre/hooks/lib/useSearchParams';
import errorHandler from '@smartwyre/utils/lib/errorHandler';
import antdNotification from 'antd/lib/notification';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { notificationsListQueryCacheKey } from '__generated__/api/notifications/Api';
import { NotificationsListParams } from '__generated__/api/notifications/data-contracts';
import { notificationsDetailQueryCacheKey } from '__generated__/api/notifications/{notificationId}/Api';
import { useNotificationsResolutionUpdateMutation } from '__generated__/api/notifications/{notificationId}/resolution/Api';

import styles from './ResolveNotificationButton.module.scss';

export interface IResolveNotificationButton {
  notification: {
    id?: number;
    resolvedAt?: string | null | undefined;
  };
  styleType: ButtonTypes | 'ANCHOR';
}

const ResolveNotificationButton = ({ notification, styleType }: IResolveNotificationButton) => {
  const location = useLocation();
  const { offset, limit, isRead, isResolved, notificationType } =
    useSearchParams<NotificationsListParams>(location.search);
  const queryClient = useQueryClient();

  const resolveNotificationMutation = useNotificationsResolutionUpdateMutation({
    onSuccess: (res, variables) => {
      if (!notification.id) return;

      queryClient.invalidateQueries(
        notificationsDetailQueryCacheKey({ notificationId: notification.id }),
      );
      queryClient.invalidateQueries(
        notificationsListQueryCacheKey({
          query: { offset, limit, isRead, isResolved, notificationType },
        }),
      );
      antdNotification.success({
        message: `Successfully marked notification as ${variables.query.status?.toLocaleLowerCase()}.`,
      });
    },
    onError: (e, variables) => {
      errorHandler(
        e,
        `Failed to ${
          variables.query.status === 'Resolved' ? 'resolve' : 'un-resolve'
        } notification, please try again.`,
      );
    },
  });

  const resolveNotification = useCallback(() => {
    if (!notification.id) return;

    resolveNotificationMutation.mutate({
      query: {
        notificationId: notification.id,
        status: notification.resolvedAt ? 'Unresolved' : 'Resolved',
      },
    });
  }, [resolveNotificationMutation, notification]);

  const content = useMemo(() => {
    return notification.resolvedAt ? 'Mark as unresolved' : 'Mark as resolved';
  }, [notification.resolvedAt]);

  if (styleType === ButtonTypes.DEFAULT || styleType === ButtonTypes.PRIMARY) {
    return (
      <Button
        className={styles.button}
        onClick={(e) => {
          e.stopPropagation();
          resolveNotification();
        }}
        icon={<CheckCircleOutlined />}
        styleType={styleType}
      >
        {content}
      </Button>
    );
  }

  return (
    <button
      className={styles.anchor}
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        resolveNotification();
      }}
    >
      <CheckCircleOutlined /> {content}
    </button>
  );
};

export default ResolveNotificationButton;
