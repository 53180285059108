import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ISegmentListing from 'deprecated/Domain/ISegmentListing';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllSegmentsForOrg {
  execute(presenter: IGetAllSegmentsForOrgPresenter, orgId?: number): void;
}

export interface IGetAllSegmentsForOrgPresenter {
  setSegments(segments: ISegmentListing[]): void;
}

export default class GetAllSegmentsForOrg implements IGetAllSegmentsForOrg {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(presenter: IGetAllSegmentsForOrgPresenter, orgId?: number) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/segments/${orgId || ''}`;

    try {
      const response = await axios.get<{ segments: ISegmentListing[] }>(
        url.href,
        this.config.getAuthConfig(),
      );
      presenter.setSegments(response.data.segments);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
}
