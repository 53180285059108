import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { lazy, Suspense } from 'react';

import PageTitle from 'utils/pageTitle/PageTitle';

const ResetPassword = lazy(() => import('components/Authentication/ResetPassword'));

const ResetPasswordContainer = () => (
  <Suspense fallback={<LoadRetry isLoading cover />}>
    <PageTitle disableOrgName>Reset Password</PageTitle>
    <ResetPassword />
  </Suspense>
);

export default ResetPasswordContainer;
