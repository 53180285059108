import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IDocument from 'deprecated/Domain/IDocument';
import { IBrandDocumentRequest } from 'deprecated/Domain/Product/IBrandDocument';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IProcessProductDocuments {
  execute(
    brandId: number,
    orgNodeCode: string,
    request: IProcessProductDocumentsRequest,
  ): Promise<ResponseResult<IProcessProductDocumentsResponse>>;
}

export interface IProcessProductDocumentsRequest {
  documentContainerId: number;
  brandDocuments: IBrandDocumentRequest[];
  documentIdToDelete: number;
}

export interface IProcessProductDocumentsResponse {
  documents: IDocument[];
}

export class ProcessProductDocuments implements IProcessProductDocuments {
  private hostName: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostName = hostname;
    this.config = config;
  }

  public async execute(
    brandId: number,
    orgNodeCode: string,
    request: IProcessProductDocumentsRequest,
  ): Promise<ResponseResult<IProcessProductDocumentsResponse>> {
    try {
      const url = new URL(this.hostName);
      url.pathname = `api/node/${orgNodeCode}/products/documents/${brandId}`;

      const response = await axios.post<IProcessProductDocumentsResponse>(
        url.href,
        request,
        this.config.getAuthConfig(),
      );

      return Result.success(response.data);
    } catch (e) {
      return Result.failed(e);
    }
  }
}
