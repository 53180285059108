import { IDeactivateUserPresenter, IDeactivateUser } from 'deprecated/Boundary/DeactivateUser';
import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class DeactivateUser implements IDeactivateUser {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute(id: string, presenter: IDeactivateUserPresenter) {
    try {
      await this.gateway.deactivateUser(id);
      presenter.deactivated();
    } catch (error) {
      console.error(error);
      presenter.notDeactivated(error);
    }
  }
}
