import React from 'react';

import { IOrganization } from 'fragments/organization/IOrganization';

export const OrganizationContext = React.createContext<IOrganization>({
  id: 0,
  name: '',
  shortName: '',
  authIssuer: '',
  authClientId: '',
  code: '',
  isSmartwyreManaged: false,
  isExternalIdentityProvider: false,
  isRoutingNode: false,
  incorporatingParentOrganizationId: null,
  marketingRightsOwner: '',
});

export const OrganizationProvider = OrganizationContext.Provider;
