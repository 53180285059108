import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import { ForecastPlanStatus } from 'deprecated/Constants/ForecastPlanStatus';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateForecastPlan {
  execute(
    forecastPlanRequest: IForecastPlan,
    presenter: ICreateForecastPlanPresenter,
    orgNode: number,
  ): Promise<void>;
}

export interface IForecastPlan {
  id: number;
  name: string;
  identifier: string;
  dateFrom: Date;
  dateTo: Date;
  forecastPlanStatus: ForecastPlanStatus;
  cutoverDate: Date;
}

export interface ICreateForecastPlanPresenter {
  forecastPlanCreated(forecastPlan: ICreateForecastPlanResponse): void;
  forecastPlanNotCreated(): void;
}

export interface ICreateForecastPlanResponse {
  id: string;
  name: string;
  identifier: string;
  dateFrom: Date;
  dateTo: Date;
  forecastPlanStatus: ForecastPlanStatus;
  cutoverDate: Date;
}

export default class CreateForecastPlan implements ICreateForecastPlan {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    forecastPlanRequest: IForecastPlan,
    presenter: ICreateForecastPlanPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = 'api/planning/';
    await axios
      .post(url.href, forecastPlanRequest, this.config.getAuthConfig())
      .then((response) => {
        presenter.forecastPlanCreated(response.data.data);
      })
      .catch((error: AxiosError) => {
        presenter.forecastPlanNotCreated();
        throw error.response;
      });
  }
}
