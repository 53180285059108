import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IHeadquarters } from 'deprecated/Domain/ILocation';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllOrganizationHeadquarters {
  execute(search?: string): Promise<IHeadquarters[]>;
}

export class GetAllOrganizationHeadquarters
  extends RemoteUseCaseBase
  implements IGetAllOrganizationHeadquarters
{
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/location/headquarters', undefined, config);
  }

  public async execute(search?: string): Promise<IHeadquarters[]> {
    const params = search ? { search } : {};
    const { data } = await axios.get(`${this.url.href}/`, {
      ...this.config.getAuthConfig(),
      params,
    });
    return data.organizationHeadquarters;
  }
}
