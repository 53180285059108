import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IOrganizationSettings from 'deprecated/Domain/IOrganizationSettings';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGeneralSettings {
  programYearStartMonth?: number;
  programYearStartDay?: number;
  organizationSettings?: IOrganizationSettings;
}

export interface IGetGeneralSettings {
  execute(): Promise<IGeneralSettings>;
}

export class GetGeneralSettings extends RemoteUseCaseBase implements IGetGeneralSettings {
  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    super(hostname, 'api/settings/general', orgNodeCode, config);
  }

  public execute = async (): Promise<IGeneralSettings> => {
    const response = await axios.get<IGeneralSettings>(this.url.href, this.config.getAuthConfig());
    return response.data;
  };
}
