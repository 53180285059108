import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteProductFeatureLabel {
  execute(productId: number, presenter: IDeleteProductFeatureLabelPresenter): void;
}

export interface IDeleteProductFeatureLabelPresenter {
  featureLabelDeleted(): void;
  featureLabelNotDeleted(): void;
}

export default class DeleteProductFeatureLabel implements IDeleteProductFeatureLabel {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(productId: number, presenter: IDeleteProductFeatureLabelPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/feature-labels/${productId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());
      presenter.featureLabelDeleted();
    } catch {
      presenter.featureLabelNotDeleted();
    }
  }
}
