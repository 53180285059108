import { IChangePasswordPayload, IChangePasswordResponse } from '../../Boundary/ChangePassword';
import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ICreateUserItem } from 'deprecated/Boundary/CreateUser';
import { IUserApiResponse } from 'deprecated/Boundary/GetUser';
import { IVerifyRecoveryApiResponse } from 'deprecated/Boundary/VerifyRecovery';
import {
  IUser,
  IUserBase,
  IUserManagementUser,
} from 'deprecated/Components/SettingsList/Security/GroupManagement/GroupTypes';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IUserApiGateway {
  createUser: (user: ICreateUserItem) => Promise<ResponseResult<IUserBase>>;
  editUser: (user: IUser) => Promise<IUser>;
  editUserProfile: (userProfile: IUser) => Promise<IUser>;
  changePassword: (payload: IChangePasswordPayload) => Promise<IChangePasswordResponse>;
  deactivateUser: (id: string) => Promise<void>;
  getUser: (id: number) => Promise<IUser>;
  getUserManagementUsers: () => Promise<IUserManagementUser[]>;
  getCurrentUser: () => Promise<IUserBase>;
  verifyRecovery: (recoveryToken: string) => Promise<IVerifyRecoveryApiResponse>;
}

export default class UserApiGateway implements IUserApiGateway {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async createUser(user: ICreateUserItem): Promise<ResponseResult<IUserBase>> {
    const createUserUrl = new URL(this.host.href);
    createUserUrl.pathname = 'api/users';

    try {
      const response = await axios.post<IUserApiResponse>(
        createUserUrl.href,
        user,
        this.config.getAuthConfig(),
      );

      return Result.success(response.data.user);
    } catch (e) {
      return Result.failed(e, 'There was an error creating the user.');
    }
  }

  public async editUser(user: IUser): Promise<IUser> {
    const editUserUrl = new URL(this.host.href);
    editUserUrl.pathname = `api/users/${encodeURIComponent(user.id)}`;

    const response = await axios.put<IUserApiResponse>(
      editUserUrl.href,
      user,
      this.config.getAuthConfig(),
    );

    return response.data.user;
  }

  public async editUserProfile(userProfile: IUser): Promise<IUser> {
    const editUserProfileUrl = new URL(this.host.href);
    editUserProfileUrl.pathname = 'api/users/user-profile';

    const response = await axios.put<IUser>(
      editUserProfileUrl.href,
      userProfile,
      this.config.getAuthConfig(),
    );

    return response.data;
  }

  public async deactivateUser(id: string): Promise<void> {
    const deactivateUserUrl = new URL(this.host.href);
    deactivateUserUrl.pathname = `api/users/${encodeURIComponent(id)}/deactivate`;

    await axios.post(deactivateUserUrl.href, {}, this.config.getAuthConfig());
  }

  public async getUser(id: number): Promise<IUser> {
    const getUserUrl = new URL(this.host.href);
    getUserUrl.pathname = `api/users/${encodeURIComponent(id)}`;

    const response = await axios.get<IUser>(getUserUrl.href, this.config.getAuthConfig());

    return response.data;
  }

  public async getUserManagementUsers(): Promise<IUserManagementUser[]> {
    try {
      const getUsersUrl = new URL(this.host.href);
      getUsersUrl.pathname = 'api/users';

      const response = await axios.get<IUser[]>(getUsersUrl.href, this.config.getAuthConfig());

      return response.data;
    } catch (error) {
      return [];
    }
  }

  public async getCurrentUser(): Promise<IUserBase> {
    const url = new URL(this.host.href);
    url.pathname = 'api/users/user-profile';

    const response = await axios.get<IUser>(url.href, this.config.getAuthConfig());

    return response.data;
  }

  public async verifyRecovery(recoveryToken: string): Promise<IVerifyRecoveryApiResponse> {
    const verifyRecoveryUrl = new URL(this.host.href);
    verifyRecoveryUrl.pathname = 'api/users/verify-recovery';

    const response = await axios.post<IVerifyRecoveryApiResponse>(verifyRecoveryUrl.href, {
      token: recoveryToken,
    });

    return response.data;
  }

  public async changePassword(payload: IChangePasswordPayload): Promise<IChangePasswordResponse> {
    const changePasswordUrl = new URL(this.host.href);

    changePasswordUrl.pathname = 'api/users/me/change-password';

    const response = await axios.post<IChangePasswordResponse>(
      changePasswordUrl.href,
      payload,
      this.config.getAuthConfig(),
    );

    return response.data;
  }
}
