import { NotificationPriceRow } from '../../PriceListNotificationTable/PriceListNotificationTable';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';
import formatPercentage from '@smartwyre/utils/lib/format/formatPercentage';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { useMemo } from 'react';

import { formatPriceWithUom } from 'utils/format/priceWithUom';

import classes from './PriceChangeColumn.module.scss';

export interface PriceChangeColumnProps {
  item: NotificationPriceRow;
  showAs: '$' | '%';
}

export function PriceChangeColumn({ showAs, item }: PriceChangeColumnProps) {
  const [formattedValue, hasIncreased, shouldDisplayIcon] = useMemo(() => {
    let value = 0;
    switch (showAs) {
      case '%':
        value = item.percentageDifference || 0;
        return [
          item.percentageDifference != null ? formatPercentage(value) : 'N/A',
          value > 0,
          item.percentageDifference != null && item.percentageDifference !== 0,
        ];
      case '$':
        value = item.valueDifference || 0;
        return [
          formatPriceWithUom(item.valueDifference, item.unitOfMeasure, true, true),
          value > 0,
          item.valueDifference != null && item.valueDifference !== 0,
        ];
      default:
        return ['N/A', false];
    }
  }, [item.percentageDifference, item.unitOfMeasure, item.valueDifference, showAs]);

  return (
    <Row style={{ width: '70%', margin: 'auto' }} align="middle" justify="space-between">
      <Col span={12}>{formattedValue}</Col>
      <Col
        offset={8}
        className={hasIncreased ? classes.priceIncreased : classes.priceDecreased}
        span={4}
      >
        {shouldDisplayIcon &&
          (hasIncreased ? (
            <CaretUpOutlined data-testid="PriceChangeColumn:CaretUpOutlined" />
          ) : (
            <CaretDownOutlined data-testid="PriceChangeColumn:CaretDownOutlined" />
          ))}
      </Col>
    </Row>
  );
}
