import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IRemoveGroupFromAllPoliciesInType {
  execute(policyTypeId: number, groupId: number): Promise<void>;
}

export class RemoveGroupFromAllPoliciesInType
  extends RemoteUseCaseBase
  implements IRemoveGroupFromAllPoliciesInType
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/organizationpolicies', orgNodeCode, config);
  }

  public async execute(policyTypeId: number, groupId: number): Promise<void> {
    await axios.delete(
      `${this.url.href}/${policyTypeId}/groups/${groupId}`,
      this.config.getAuthConfig(),
    );
  }
}
