import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ICostList from 'deprecated/Domain/ICostList';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllCostListsForUser {
  execute(): Promise<ICostList[]>;
}

export class GetAllCostListsForUser extends RemoteUseCaseBase implements IGetAllCostListsForUser {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, `api/node/${orgNodeCode}/cost-list/user`, orgNodeCode, config);
  }

  public async execute(): Promise<ICostList[]> {
    const response = await axios.get<{ costListResponses: ICostList[] }>(
      this.url.href,
      this.config.getAuthConfig(),
    );
    return response.data.costListResponses;
  }
}
