import { NotificationTypeApplicationEnum } from '__generated__/api/notifications/data-contracts';

const createNotificationModalTitle = (
  resourceName?: string,
  notificationType?: NotificationTypeApplicationEnum,
) => {
  if (!resourceName) {
    return 'Notification Details';
  }

  switch (notificationType) {
    case 'InternalProgram':
      return `Internal Update to ${resourceName}`;
    default:
      return resourceName;
  }
};

export default createNotificationModalTitle;
