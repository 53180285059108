import PolicyTypes from '@smartwyre/hooks/lib/useAuthentication/fragments/PolicyTypes';
import React from 'react';

import { FEATURE_TOGGLE_TYPE } from 'fragments/FeatureToggle';

export type PermissionTypes = PolicyTypes | FEATURE_TOGGLE_TYPE;

const PermissionsContext = React.createContext<PermissionTypes[]>([]);

export default PermissionsContext;
