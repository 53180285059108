import axios from '@smartwyre/utils/lib/authenticatedAxios';
import notification from 'antd/lib/notification';

import { IPriceListItem } from 'deprecated/Boundary/GetPriceLists';
import { IResourceFilterSelection } from 'deprecated/Domain/IResourceFilter';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { SUPPORT_EMAIL } from 'deprecated/Utilities/SystemStrings';

export interface IGetPriceLists {
  execute(
    orgNodeCode: string,
    searchText?: string,
    filters?: IResourceFilterSelection[],
    offset?: number,
    limit?: number,
  ): Promise<PriceListResponse>;
}

export interface PriceListResponse {
  sellerPriceLists: IPriceListItem[];
  totalCount: number;
}

export default class GetPriceLists implements IGetPriceLists {
  public config: IGetTokenFromLocalStorage;

  public host: URL;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async execute(
    orgNodeCode: string,
    searchText?: string,
    filters?: IResourceFilterSelection[],
    offset?: number,
    limit?: number,
  ): Promise<PriceListResponse> {
    const url = new URL(this.host.href);

    url.pathname = `api/node/${encodeURIComponent(orgNodeCode)}/sellerPriceLists`;

    if (searchText && /\S/.test(searchText)) url.searchParams.set('searchText', searchText);

    if (filters) {
      filters.forEach((filter) => {
        if (
          filter.value !== 'All' &&
          filter.value &&
          !Array.isArray(filter.value) &&
          /\S/.test(filter.value)
        ) {
          // TODO: This is clearly a typing problem and should be addressed. This might already be a bug
          url.searchParams.set(filter.name, filter.value);
        }
      });
    }

    if (offset !== undefined) {
      url.searchParams.set('offset', offset.toString());
    }

    if (limit !== undefined) {
      url.searchParams.set('limit', limit.toString());
    }

    try {
      const response = await axios.get<PriceListResponse>(url.href, this.config.getAuthConfig());
      return response.data;
    } catch (error) {
      notification.open({
        type: 'error',
        message: `There was an issue retrieving price lists, please contact ${SUPPORT_EMAIL}.`,
        duration: 0,
      });
      return {
        sellerPriceLists: [],
        totalCount: 0,
      };
    }
  }
}
