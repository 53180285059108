import { useContext, useMemo } from 'react';

import PermissionsContext, { PermissionTypes } from 'contexts/Permissions/PermissionsContext';

export interface PermissionsHookResponse {
  loading: boolean;
  checkPermission: (permissionName: PermissionTypes) => boolean;
  checkAllPermissions: (permissionNames: PermissionTypes[]) => boolean;
  checkAnyPermissions: (permissionNames: PermissionTypes[]) => boolean;
  checkAnyPermissionsReturnsBooleanArray: (permissionNames: PermissionTypes[]) => boolean[];
}

export function usePermissions(): PermissionsHookResponse {
  const permissions = useContext(PermissionsContext);

  return useMemo(() => {
    function checkPermission(permissionName: PermissionTypes): boolean {
      return permissions?.includes(permissionName) || false;
    }
    function checkAllPermissions(permissionNames: PermissionTypes[]) {
      return permissionNames.every((permission) => permissions?.includes(permission));
    }
    function checkAnyPermissions(permissionNames: PermissionTypes[]) {
      return permissionNames.some((permission) => permissions?.includes(permission));
    }
    function checkAnyPermissionsReturnsBooleanArray(permissionNames: PermissionTypes[]) {
      return permissionNames.map((permission) => permissions?.includes(permission) ?? false);
    }

    return {
      loading: !permissions,
      checkPermission,
      checkAllPermissions,
      checkAnyPermissions,
      checkAnyPermissionsReturnsBooleanArray,
    };
  }, [permissions]);
}
