import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IPriceRuleResponse } from 'deprecated/Domain/PriceRule/IPriceRuleResponse';
import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IUpdatePriceRules {
  execute: (
    priceRule: IPriceRuleResponse,
  ) => Promise<ResponseResult<IPriceRuleResponse | undefined>>;
}

export default class UpdatePriceRules implements IUpdatePriceRules {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    priceRule: IPriceRuleResponse,
  ): Promise<ResponseResult<IPriceRuleResponse | undefined>> {
    const url = new URL(this.hostname);
    url.pathname = `/api/price-rules/${priceRule.id}`;

    try {
      const response = await axios.patch<IPriceRuleResponse>(
        url.href,
        priceRule,
        this.config.getAuthConfig(),
      );

      return Result.success(response.data);
    } catch (e) {
      return Result.failed(e, 'There was an error saving the price rule.');
    }
  }
}
