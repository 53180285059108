export const getTableRowHoverColor = (orgId: number): string => tableRowHoverColors[orgId];

export const getTableHighlightBackgroundColor = (orgId: number): string =>
  tableHighlightBackgroundColors[orgId];

const tableRowHoverColors: { [key: number]: string } = {
  18: '#D8D8D8',
  23: '#fff4da',
  68: '#D8D8D8',
  74: '#d7f0db',
  92: '#DBDCD9',
  105: '#D8D8D8',
  1312: '#DBDCD9',
  1313: '#DBDCD9',
  1314: '#DBDCD9',
  1315: '#DBDCD9',
  1316: '#DBDCD9',
  1317: '#DBDCD9',
  1318: '#DBDCD9',
  1319: '#DBDCD9',
};

const tableHighlightBackgroundColors: { [key: number]: string } = {
  18: '#68AA61',
  23: '#63666A',
  68: '#474747',
  74: '#5A5A5A',
  92: '#B9B6B6',
  105: '#424d64',
  301: '#CECEB3',
  1312: '#B9B6B6',
  1313: '#B9B6B6',
  1314: '#B9B6B6',
  1315: '#B9B6B6',
  1316: '#B9B6B6',
  1317: '#B9B6B6',
  1318: '#B9B6B6',
  1319: '#B9B6B6',
};
