import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';

import ThemeImageType from 'deprecated/Constants/ThemeImageTypes';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteImageAsset {
  execute(presenter: IDeleteAssetPresenter, themeImageType: ThemeImageType): void;
}

export interface IDeleteAssetPresenter {
  imageAssetDeleted: (success: string) => void;
  imageAssetNotDeleted: (errors?: string) => void;
}

export default class DeleteImageAsset implements IDeleteImageAsset {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(presenter: IDeleteAssetPresenter, themeImageType: ThemeImageType) {
    const url = new URL(this.hostname);
    url.pathname = `/api/settingsTheme/asset/${themeImageType}`;

    const headers = this.config.getAuthConfig();

    try {
      await axios.delete(url.href, headers);
      presenter.imageAssetDeleted('Your image has been removed successfully.');
    } catch (error) {
      if (isAxiosError(error)) {
        presenter.imageAssetNotDeleted(error.response?.data?.errors);
      }
    }
  }
}
