import { IProductsApiGateway } from 'deprecated/Gateways/ProductsApiGateway';

import { IDetachRawDataFromProduct } from './RemoteTypes';

export default class DetachRawDataFromProduct implements IDetachRawDataFromProduct {
  private readonly productGateway: IProductsApiGateway;

  public constructor(brandGateway: IProductsApiGateway) {
    this.productGateway = brandGateway;
  }

  public async execute(productId: string, rawDataId: string) {
    await this.productGateway.detachRawDataFromProduct(productId, rawDataId);
  }
}
