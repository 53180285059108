import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import FilterTypes from 'deprecated/Constants/FilterTypes';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ISaveFilter {
  execute(saveFilterRequest: ICreateFilter, presenter: ICreateFilterPresenter): Promise<void>;
}

export interface ICreateFilter {
  filter: IFilterCollection;
}

export interface IFilterCollection {
  id?: number;
  name: string;
  activeIngredientsNames: string[];
  activeIngredientsOnlySelectedMode: boolean;
  costListId: number;
  offset: number;
  searchText: string;
  standardUOM: boolean;
  date?: string | null;
  programYear?: number | null;
  manufacturerId?: number;
  supplierId?: number;
  territoryId?: number;
  locationId?: number;
  featureLabelId?: number;
  retrieveFilters?: boolean;
  productType?: string;
  segmentId?: number;
  filterType: FilterTypes;
  validPricesToday: boolean;
  usePriorYearToDetermineNets: boolean;
}

export interface ICreateFilterPresenter {
  filterCreated(forecastPlan: IFilterResponse): void;
  filterNotCreated(): void;
}

export interface IFilterResponse {
  filter: IFilterCollection;
}

export default class SaveFilter implements ISaveFilter {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(filterRequest: ICreateFilter, presenter: ICreateFilterPresenter) {
    const url = new URL(this.hostname);
    url.pathname = 'api/filters/save-filter';
    await axios
      .post(url.href, filterRequest, this.config.getAuthConfig())
      .then((response) => {
        presenter.filterCreated(response.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.data) {
          throw error;
        } else {
          presenter.filterNotCreated();
        }
      });
  }
}
