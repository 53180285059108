import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IDefinedVolumeFormula from 'deprecated/Domain/IDefinedVolumeFormula';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetDefinedVolumeFormulas {
  execute(): Promise<IDefinedVolumeFormula[]>;
}

export class GetDefinedVolumeFormulas
  extends RemoteUseCaseBase
  implements IGetDefinedVolumeFormulas
{
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/defined-volume-formulas', undefined, config);
  }

  public async execute(): Promise<IDefinedVolumeFormula[]> {
    const { data } = await axios.get(`${this.url.href}/`, this.config.getAuthConfig());
    return data;
  }
}
