import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAddSegmentToPriceList {
  execute(priceListId: number, segmentId: number, presenter: IAddSegmentToPriceListPresenter): void;
}

export interface IAddSegmentToPriceListPresenter {
  priceListSegmentSaved(): void;
  priceListSegmentNotSaved(): void;
}

export default class AddSegmentToPriceList implements IAddSegmentToPriceList {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(
    priceListId: number,
    segmentId: number,
    presenter: IAddSegmentToPriceListPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(
      this.orgNodeCode,
    )}/sellerPriceLists/${priceListId}/segments/${segmentId}`;

    try {
      await axios.post(url.href, {}, this.config.getAuthConfig());
      presenter.priceListSegmentSaved();
    } catch {
      presenter.priceListSegmentNotSaved();
    }
  }
}
