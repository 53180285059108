import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import formatCurrency from '@smartwyre/utils/lib/format/formatCurrency';
import { FC } from 'react';

import { useProgramsAccommodationRequestDetailQuery } from '__generated__/api/programs/accommodation-request/{id}/Api';

import { prettyDateTimeFormatter } from 'utils/dateFormats/dateFormats';

import { useOrganization } from 'contexts/organizations/OrganizationContext/hooks/useOrganization';

import styles from './AccommodationRequestNotificationDetail.module.scss';

interface AccommodationRequestNotificationDetailProps {
  id?: number;
}

export const AccommodationRequestNotificationDetail: FC<
  AccommodationRequestNotificationDetailProps
> = ({ id }) => {
  const { name: organizationName } = useOrganization();

  const requestDetailQuery = useProgramsAccommodationRequestDetailQuery(
    {
      id: id as number,
    },
    {
      enabled: !!id,
    },
  );

  if (
    requestDetailQuery.isLoading ||
    requestDetailQuery.isError ||
    !requestDetailQuery.data?.data
  ) {
    return (
      <Card>
        <LoadRetry {...requestDetailQuery} cover />
      </Card>
    );
  }

  const data = requestDetailQuery.data.data;

  return (
    <div className={styles.containerData}>
      <div className={styles.column}>
        <div>
          <p>
            Requested Date: <span>{prettyDateTimeFormatter(data.createdAt || '')}</span>
          </p>
        </div>
        <div>
          <p>
            Customer: <span>{data.customerName}</span>
          </p>
        </div>
        <div>
          <p>
            Reason: <span>{data.reason}</span>
          </p>
        </div>
        <div>
          <p>
            Detail: <span>{data.detail}</span>
          </p>
        </div>
        <div>
          <p>
            Accommodation Value: <span>{formatCurrency(data.accommodationValue as number)}</span>
          </p>
        </div>
      </div>
      <div className={styles.column}>
        <div>
          <p>
            Requested by: <span>{data.requestedBy}</span>
          </p>
        </div>
        <div>
          <p>
            Brand Family: <span>{(data.brandFamilies ?? []).map((b) => b.name).join(', ')}</span>
          </p>
        </div>
        <div>
          <p>
            Description: <span>{data.description}</span>
          </p>
        </div>
        <div>
          <p>
            Support for {organizationName} <span>{data.support}</span>
          </p>
        </div>
        <div>
          <p>
            Manager: <span>{data.managerName}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
