import {
  SortableProductsProperties,
  OrderingOptions,
} from 'deprecated/Domain/SortableProductsProperties';
import { ParamaterType, ParameterCollection } from 'deprecated/Utilities/paramaterTypes';

import IParameterStore from './IParameterStore';
import ISearchQueryStore from './ISearchQueryStore';
import ISortingParametersStore from './ISortingParametersStore';

export default class ProductsQueryGateway
  implements ISearchQueryStore, IParameterStore, ISortingParametersStore
{
  private parameterStore: ParameterCollection = {};

  private searchQuery = '';

  private sortingParameters: { sortBy?: SortableProductsProperties; order?: OrderingOptions } = {};

  public getSearchQuery() {
    return this.searchQuery;
  }

  public getParameters() {
    return this.parameterStore;
  }

  public get filtersAreEmpty() {
    return Object.keys(this.parameterStore).length === 0;
  }

  public setSearchQuery(query: string) {
    this.searchQuery = query;
  }

  public set(parameterName: string, parameterValue: ParamaterType) {
    this.parameterStore[parameterName] = parameterValue;
  }

  public reset() {
    this.parameterStore = {};
  }

  public getSortingParameters() {
    return this.sortingParameters;
  }

  public setSortingParameters(parameters: {
    sortBy?: SortableProductsProperties;
    order?: OrderingOptions;
  }) {
    this.sortingParameters = parameters;
  }
}
