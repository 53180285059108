import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';
import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useUsersAuthOrgNodesDetailQuery } from '__generated__/api/users/auth/{authId}/org-nodes/Api';
import {
  useUsersDefaultNodesUpdateMutation,
  usersDefaultNodesListQueryCacheKey,
} from '__generated__/api/users/default-nodes/Api';
import { EndpointsUsersUpdateUserDefaultNodesDefaultNode } from '__generated__/api/users/default-nodes/data-contracts';

const UnconfiguredDefaultNodeRoute = () => {
  const { smartwyreUserClaims, AuthId } = useAuthentication();
  const queryClient = useQueryClient();
  const userOrgNodes = useUsersAuthOrgNodesDetailQuery({
    query: {
      authId: AuthId,
      limit: 1,
      offset: 0,
      routingNodes: false,
      search: '',
    },
  });
  const defaultNodeMutation = useUsersDefaultNodesUpdateMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(usersDefaultNodesListQueryCacheKey({}));
    },
  });

  const createDefaultNode = useCallback(
    (defaultNodeId: number) => {
      const nodes = [
        {
          defaultNodeId,
          routingNodeId: smartwyreUserClaims?.organizationid,
        } as EndpointsUsersUpdateUserDefaultNodesDefaultNode,
      ];
      defaultNodeMutation.mutate({ data: { defaultNodes: nodes } });
    },
    [defaultNodeMutation, smartwyreUserClaims?.organizationid],
  );

  const orgNode = userOrgNodes?.data?.data?.userOrgNodes?.[0]?.orgId;
  useEffect(() => {
    if (orgNode) {
      createDefaultNode(orgNode);
    }
  }, [orgNode]); // eslint-disable-line

  if (userOrgNodes.isError || !userOrgNodes?.data?.data?.userOrgNodes) {
    return <LoadRetry {...userOrgNodes} cover />;
  }

  return <LoadRetry isLoading cover />;
};

export default UnconfiguredDefaultNodeRoute;
