import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IRecalculateForecast {
  execute(forecastId: number): Promise<boolean>;
}

export default class RecalculateForecast implements IRecalculateForecast {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(forecastId: number) {
    const url = new URL(this.hostname);
    url.pathname = `api/forecasts/${forecastId}/recalculate`;

    try {
      await axios.post(url.href, null, this.config.getAuthConfig());
      return true;
    } catch (e) {
      return false;
    }
  }
}
