import axios from '@smartwyre/utils/lib/authenticatedAxios';
import queryString from 'query-string';

import { IAlert } from 'deprecated/Boundary/GetAlerts';
import { IAlertFilterRequest } from 'deprecated/Boundary/GetAlerts/IAlertFilter';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAlert {
  execute(id: number, filter?: IAlertFilterRequest | null): Promise<IAlert>;
}

export class GetAlert implements IGetAlert {
  private readonly orgNodeCode: string;

  private readonly hostname: string;

  private readonly config: IGetTokenFromLocalStorage;

  constructor(orgNodeCode: string, hostname: string, config: IGetTokenFromLocalStorage) {
    this.orgNodeCode = orgNodeCode;
    this.hostname = hostname;
    this.config = config;
  }

  public execute(id: number, filter?: IAlertFilterRequest | null): Promise<IAlert> {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/alerts/${id}`;

    const urlWithQuery = queryString.stringifyUrl({
      url: url.toString(),
      query: { ...filter },
    });

    return axios.get<IAlert>(urlWithQuery, this.config.getAuthConfig()).then((response) => {
      return response.data;
    });
  }
}
