import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGeneralSettings } from 'deprecated/RemoteUseCases/GetGeneralSettings';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUpdateGeneralSettings {
  execute(settings: IGeneralSettings): Promise<void>;
}

export class UpdateGeneralSettings extends RemoteUseCaseBase implements IUpdateGeneralSettings {
  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    super(hostname, 'api/settings/general', orgNodeCode, config);
  }

  public async execute(settings: IGeneralSettings): Promise<void> {
    await axios.put(this.url.href, settings, this.config.getAuthConfig());
  }
}
