import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IdNamePair } from 'deprecated/Domain/IdNamePair';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetSharedPriceLists {
  getSharedToMe(programYear: number): Promise<Record<string, IdNamePair<number>[]>>;
  getSharedByMe(programYear: number, programId: number): Promise<IdNamePair<number>[]>;
}

export default class GetSharedPriceLists implements IGetSharedPriceLists {
  public config: IGetTokenFromLocalStorage;

  public host: URL;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async getSharedToMe(programYear: number): Promise<Record<string, IdNamePair<number>[]>> {
    const url = new URL(this.host.href);

    url.pathname = `api/shared-price-lists/shared-to-me/${programYear}`;

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());

      return response.data;
    } catch (error) {
      return Promise.reject();
    }
  }

  public async getSharedByMe(
    programYear: number,
    programId: number,
  ): Promise<IdNamePair<number>[]> {
    const url = new URL(this.host.href);

    url.pathname = `api/shared-price-lists/shared-by-me/${programYear}/${programId}`;

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());

      return response.data;
    } catch (error) {
      return Promise.reject();
    }
  }
}
