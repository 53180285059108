import axios from '@smartwyre/utils/lib/authenticatedAxios';

import {
  IGetLocationsResponse,
  ILocationApiGateway,
  IUpdateLocationRequest,
  Locations,
} from 'deprecated/Boundary/LocationApiGateway';
import { ILocationWithFipsCode } from 'deprecated/Domain/ILocation';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export default class LocationApiGateway implements ILocationApiGateway {
  public config: IGetTokenFromLocalStorage;

  private host: URL;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async getLocations(orgNodeCode: string): Promise<Locations> {
    const getLocationsUrl = new URL(this.host.href);
    getLocationsUrl.pathname = `api/node/${encodeURIComponent(orgNodeCode)}/locations`;

    const response = await axios.get<IGetLocationsResponse>(
      getLocationsUrl.href,
      this.config.getAuthConfig(),
    );

    return response.data.locations;
  }

  public async updateLocation(updateData: IUpdateLocationRequest): Promise<ILocationWithFipsCode> {
    const updateLocationUrl = new URL(this.host.href);
    updateLocationUrl.pathname = 'api/location';

    const response = await axios.post<ILocationWithFipsCode>(
      updateLocationUrl.href,
      updateData,
      this.config.getAuthConfig(),
    );

    return response.data;
  }

  public async deleteLocation(id: number): Promise<void> {
    const updateLocationUrl = new URL(this.host.href);
    updateLocationUrl.pathname = `api/location/${id}`;

    await axios.delete(updateLocationUrl.href, this.config.getAuthConfig());
  }
}
