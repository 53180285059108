import ResolveNotificationButton, {
  IResolveNotificationButton,
} from '../../../../../../../ResolveNotificationButton/ResolveNotificationButton';
import Button, { ButtonTypes } from '@smartwyre/components/lib/Button/Button';
import { useNavigate } from 'react-router-dom';

import styles from './ProgramNotificationFooter.module.scss';

export interface IProgramNotificationFooterProps {
  notification: IResolveNotificationButton['notification'];
  programId: number;
}

export default function ProgramNotificationFooter({
  notification,
  programId,
}: IProgramNotificationFooterProps) {
  const navigate = useNavigate();

  const handleGoToProgram = () => {
    navigate({
      pathname: `/programs/${programId}`,
    });
  };

  return (
    <div className={styles.footer}>
      <ResolveNotificationButton notification={notification} styleType={ButtonTypes.DEFAULT} />
      <Button className={styles.footerButton} onClick={handleGoToProgram}>
        Go to Program
      </Button>
    </div>
  );
}
