import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { lazy, Suspense } from 'react';

import PageTitle from 'utils/pageTitle/PageTitle';

const Verification = lazy(() => import('components/Authentication/VerifyUser'));

const VerificationContainer = () => (
  <Suspense fallback={<LoadRetry isLoading cover />}>
    <PageTitle>Login Verification</PageTitle>
    <Verification />
  </Suspense>
);

export default VerificationContainer;
