import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IPolicyCategory } from 'deprecated/Components/SettingsList/Security/GroupManagement/PolicyTypes';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllOrganizationPolicies {
  execute(): Promise<IPolicyCategory[]>;
}

export class GetAllOrganizationPolicies
  extends RemoteUseCaseBase
  implements IGetAllOrganizationPolicies
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/organizationpolicies', orgNodeCode, config);
  }

  public async execute(): Promise<IPolicyCategory[]> {
    const response = await axios.get<IPolicyCategory[]>(this.url.href, this.config.getAuthConfig());
    return response.data;
  }
}
