import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ICostList from 'deprecated/Domain/ICostList';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllCostLists {
  execute(presenter: IPresentCostLists): void;
}

export interface IPresentCostLists {
  costLists(costLists: ICostList[]): void;
  getCostListsUnsuccessful(): void;
}

export default class GetAllCostLists implements IGetAllCostLists {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(presenter: IPresentCostLists) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${this.orgNodeCode}/cost-list/organization`;

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());
      presenter.costLists(response.data.costListResponses);
    } catch {
      presenter.getCostListsUnsuccessful();
    }
  }
}
