import formatCurrency from '@smartwyre/utils/lib/format/formatCurrency';

/**
 * Format price and uom into dollars with 2 decimal places and correct thousand commas with / uom appended
 * @param price input price to format
 * @param uom input uom to format
 * @param returnNAWhenNull if price or uom is null returns N/A or empty
 * @returns string value that represents a compacted format for
 * @example 12829182.32, GA becomes $12,829,182.32 / GA
 */
export const formatPriceWithUom = (
  price: number | null | undefined,
  uom: string | null | undefined,
  returnNAWhenNull: boolean,
  allowZero = false,
) => {
  if ((price || (price === 0 && allowZero)) && uom) {
    return `${formatCurrency(price)} / ${uom}`;
  }

  return returnNAWhenNull ? 'N/A' : '';
};
