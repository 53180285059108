import axios from '@smartwyre/utils/lib/authenticatedAxios';

import {
  IOrganisationListItem,
  IOrganisationsApiResponse,
} from 'deprecated/Boundary/GetAllOrganisations';
import { IUserBase } from 'deprecated/Components/SettingsList/Security/GroupManagement/GroupTypes';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPolicyPreferences {
  name: string;
  active: boolean;
  hasAllSegments?: boolean;
  segmentIds?: number[];
  organizationIds?: number[];
  networkGroupIds?: number[];
  excludedProgramYears?: number[];
  canViewPriceOnThirdPartyTransactions?: boolean;
  canViewExtendedPriceOnThirdPartyTransactions?: boolean;
  canViewCounterPartyInformationOnThirdPartyTransactions?: boolean;
  isPublishedToAllOrganizations?: boolean;
  granteeProductsOnly?: boolean;
  productSegmentsOnly?: boolean;
  salesToPublish?: number;
  licenseReportingSelectedAggregatorIssuerIds?: number[];
  extendedNetworkPolicySegments?: ISegmentPolicyPreferences[];
}

export interface ISegmentPolicyPreferences {
  segmentId: number;
  canViewPriceOnThirdPartyTransactions?: boolean;
  canViewExtendedPriceOnThirdPartyTransactions?: boolean;
  canViewCounterPartyInformationOnThirdPartyTransactions?: boolean;
}

export type AccountManagers = {
  accountManagersAssignedToOrganization: IUserBase[];
  unassignedUsersFromMyOrganization: IUserBase[];
};

export interface IOrganisationApiGateway {
  getOrganisations: (orgNodeCode: string) => Promise<IOrganisationListItem[]>;
  updateDataExchange: (distributorId: number, policies: object) => Promise<void>;
  getAccountManagers(targetOrganizationId: number): Promise<AccountManagers>;
  addAccountManagerToOrganization(userId: number, orgId: number): Promise<void>;
  removeAccountManagerFromOrganization(userId: number, orgId: number): Promise<void>;
}

export default class OrganisationApiGateway implements IOrganisationApiGateway {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  /**
   * @Deprecated
   */
  public async getOrganisations(orgNodeCode: string): Promise<IOrganisationListItem[]> {
    try {
      const getOrganisationsUrl = new URL(this.host.href);
      getOrganisationsUrl.pathname = `api/node/${encodeURIComponent(orgNodeCode)}/organisations`;

      const response = await axios.get<IOrganisationsApiResponse>(
        getOrganisationsUrl.href,
        this.config.getAuthConfig(),
      );

      return response.data.organizations;
    } catch (error) {
      return [];
    }
  }

  public async updateDataExchange(distributorId: number, policies: object): Promise<void> {
    const updateDataExchangeUrl = new URL(this.host.href);
    updateDataExchangeUrl.pathname = `api/policies/${distributorId}`;

    await axios.patch(updateDataExchangeUrl.href, policies, this.config.getAuthConfig());
  }

  public async getAccountManagers(targetOrganizationId: number): Promise<AccountManagers> {
    const url = new URL(this.host.href);
    url.pathname = `api/orgnodes/${targetOrganizationId}/organisation/account-managers`;

    const response = await axios.get(url.href, this.config.getAuthConfig());

    return response.data;
  }

  public addAccountManagerToOrganization(userId: number, orgId: number): Promise<void> {
    const url = new URL(this.host.href);
    url.pathname = `api/orgnodes/${orgId}/organisation/account-managers/${userId}`;

    return axios.post(url.href, {}, this.config.getAuthConfig());
  }

  public removeAccountManagerFromOrganization(userId: number, orgId: number): Promise<void> {
    const url = new URL(this.host.href);
    url.pathname = `api/orgnodes/${orgId}/organisation/account-managers/${userId}`;

    return axios.delete(url.href, this.config.getAuthConfig());
  }
}
