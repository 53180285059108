import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteOrganizationAlias {
  execute(identifier: string): Promise<void>;
}

export class DeleteOrganizationAlias extends RemoteUseCaseBase implements IDeleteOrganizationAlias {
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/organization-alias', undefined, config);
  }

  public async execute(alias: string): Promise<void> {
    return axios.delete(`${this.url.href}/${alias}`, this.config.getAuthConfig());
  }
}
