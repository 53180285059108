import internalProgramNotificationColumns from '../../utils/internalProgramNotificationColumns';
import Table from '@smartwyre/components/lib/Table';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { QueriesGetInternalProgramNotificationGetInternalProgramNotificationQueryProduct } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/data-contracts';

import styles from './InternalProgramNotificationTable.module.scss';

export interface IInternalProgramNotificationTableProps {
  products: QueriesGetInternalProgramNotificationGetInternalProgramNotificationQueryProduct[];
  viewAsNets: boolean;
}

export const InternalProgramNotificationTable = ({
  products,
  viewAsNets,
}: IInternalProgramNotificationTableProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  function onRowEvent(
    record: QueriesGetInternalProgramNotificationGetInternalProgramNotificationQueryProduct,
  ) {
    navigate({
      pathname: `/products/${record.productId}/commercial`,
    });
  }

  return (
    <Table
      rowKey={(row) => row.productId?.toString() ?? `${row.productName}-${row.packageSize}`}
      onRow={(record) => {
        return {
          onClick: () => {
            onRowEvent(record);
          },
        };
      }}
      columns={internalProgramNotificationColumns(viewAsNets)}
      dataSource={products}
      pagination={false}
      tableWrapClass={styles.tableWrap}
      searchParams={location.search}
    />
  );
};

export default InternalProgramNotificationTable;
