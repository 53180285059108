import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IPackageConfigurationDataListItem } from 'deprecated/Boundary/GetAllPackageConfigurationData';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPackageConfigurationDataApiGateway {
  getPackageConfigurationData: () => Promise<IPackageConfigurationDataListItem[]>;
}

export default class PackageConfigurationDataApiGateway {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode.toString();
    this.config = config;
  }

  public async getPackageConfigurationData(): Promise<IPackageConfigurationDataListItem[]> {
    const url = new URL(this.host.href);
    url.pathname = `/api/node/${this.orgNodeCode}/packageSizeData`;
    try {
      const response = await axios.get<IPackageConfigurationDataListItem[]>(
        url.href,
        this.config.getAuthConfig(),
      );

      return response.data;
    } catch (error) {
      return [];
    }
  }
}
