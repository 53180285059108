import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { INetworkGroup } from 'deprecated/Components/SettingsList/Preferences/NetworkGroups';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateOrUpdateNetworkGroup {
  execute(presenter: ICreateOrUpdateNetworkGroupPresenter, label: INetworkGroup): void;
}

export interface ICreateOrUpdateNetworkGroupPresenter {
  updateNetworkGroupSuccess: (label: INetworkGroup) => void;
  updateNetworkGroupFailed: () => void;
}

export default class CreateOrUpdateNetworkGroup implements ICreateOrUpdateNetworkGroup {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: ICreateOrUpdateNetworkGroupPresenter, label: INetworkGroup) {
    const url = new URL(this.host.href);

    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/network-groups`;
    const headers = this.config.getAuthConfig();

    try {
      const response = await axios.post<{ id: number }>(url.href, label, headers);

      /**
       * @TODO - fix no-param-reassign
       */
      // eslint-disable-next-line no-param-reassign
      label.id = response.data.id.toString();
      presenter.updateNetworkGroupSuccess(label);
    } catch (error) {
      presenter.updateNetworkGroupFailed();
    }
  }
}
