import ProgramNotificationFooter from '../../../ProgramNotificationFooter/ProgramNotificationFooter';
import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import getPaginationOffset from '@smartwyre/utils/lib/getPaginationOffset';
import getPaginationPage from '@smartwyre/utils/lib/getPaginationPage';

import { useNotificationsInternalProgramNotificationsDetailQuery } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/Api';
import { NotificationsInternalProgramNotificationsDetailParams } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/data-contracts';

import { INotificationContent } from 'components/Notifications/components/NotificationModal/components/NotificationContent/NotificationContent';
import Pagination from 'ui/Pagination/Pagination';

import styles from './InternalProgramNotificationTable.module.scss';

import InternalProgramNotificationTable from './InternalProgramNotificationTable';

interface InternalProgramNotificationTableWrapperProps extends INotificationContent {
  queryParams: NotificationsInternalProgramNotificationsDetailParams;
  viewAsNets: boolean;
  onPaginate: (offset: number, limit: number) => void;
}

const InternalProgramNotificationTableWrapper = ({
  notification,
  queryParams,
  viewAsNets,
  onPaginate,
}: InternalProgramNotificationTableWrapperProps) => {
  const queryResult = useNotificationsInternalProgramNotificationsDetailQuery({
    query: queryParams,
  });

  if (
    queryResult.isLoading ||
    queryResult.isError ||
    !queryResult.data?.data ||
    !queryResult.data?.data?.products ||
    queryResult.data?.data?.totalProductCount === null ||
    queryResult.data?.data?.totalProductCount === undefined ||
    !queryResult.data?.data?.programId
  ) {
    return (
      <Card>
        <LoadRetry {...queryResult} testIdPrefix="InternalProgramNotification" cover />
      </Card>
    );
  }

  if (!queryParams.organizationId) {
    return (
      <>
        <Card className={styles.noOrganizationsCard}>
          No eligible orgs available to display nets for.
        </Card>
        <ProgramNotificationFooter
          notification={notification}
          programId={queryResult.data.data.programId}
        />
      </>
    );
  }

  return (
    <>
      <InternalProgramNotificationTable
        products={queryResult.data.data.products}
        viewAsNets={viewAsNets}
      />
      <Pagination
        className={styles.pagination}
        current={getPaginationPage(queryParams.offset, queryParams.limit)}
        pageSize={queryParams.limit ?? 10}
        showSizeChanger
        onChange={(page, newLimit) => {
          onPaginate(getPaginationOffset(page, newLimit), newLimit);
        }}
        total={queryResult.data.data.totalProductCount}
      />
      <ProgramNotificationFooter
        notification={notification}
        programId={queryResult.data.data.programId}
      />
    </>
  );
};

export default InternalProgramNotificationTableWrapper;
