import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import Modal from '@smartwyre/components/lib/Modal';
import moment from 'moment';

import { useNotificationsDetailQuery } from '__generated__/api/notifications/{notificationId}/Api';
import { QueriesGetNotificationGetNotificationQueryNotification } from '__generated__/api/notifications/{notificationId}/data-contracts';

import { prettyDateTimeFormatter } from 'utils/dateFormats/dateFormats';

import styles from './NotificationModal.module.scss';

import NotificationContent from './components/NotificationContent/NotificationContent';
import useReadNotification from './hooks/useReadNotification';
import createNotificationModalTitle from './utils/createNotificationModalTitle';

interface INotificationModalProps {
  notificationId: number;
  prefetchedNotificationDetails?: Omit<
    QueriesGetNotificationGetNotificationQueryNotification,
    'id'
  >;
  onClose: () => void;
}

const NotificationModal = ({
  notificationId,
  prefetchedNotificationDetails,
  onClose,
}: INotificationModalProps) => {
  const handleModalOnClose = () => {
    onClose();
  };

  const notificationDetailsQuery = useNotificationsDetailQuery(
    {
      notificationId,
    },
    {
      initialData: () => {
        if (!prefetchedNotificationDetails) {
          return undefined; // query will immediately trigger a fetch
        }

        return {
          data: {
            notification: prefetchedNotificationDetails,
          },
        };
      },
    },
  );
  const notificationDetails = notificationDetailsQuery.data?.data?.notification;

  useReadNotification(
    notificationId,
    !notificationDetails?.readAt &&
      !notificationDetailsQuery.isLoading &&
      !notificationDetailsQuery.isError,
  );

  return (
    <Modal
      title={
        <h2 data-testid="notification-modal-title">
          {createNotificationModalTitle(
            notificationDetails?.resourceName,
            notificationDetails?.notificationType,
          )}
        </h2>
      }
      footer={
        <div className={styles.footerContainer}>
          {notificationDetails?.raisedAt && moment(notificationDetails?.raisedAt).isValid() && (
            <span data-testid="notification-raised-at">
              Raised At: {prettyDateTimeFormatter(notificationDetails?.raisedAt)}
            </span>
          )}
          {notificationDetails?.readAt && moment(notificationDetails?.readAt).isValid() && (
            <span data-testid="notification-read-at">
              Read At: {prettyDateTimeFormatter(notificationDetails?.readAt)}
            </span>
          )}
          {notificationDetails?.resolvedAt && moment(notificationDetails?.resolvedAt).isValid() && (
            <span data-testid="notification-resolved-at">
              Resolved At: {prettyDateTimeFormatter(notificationDetails?.resolvedAt)}
            </span>
          )}
        </div>
      }
      open
      onCancel={handleModalOnClose}
      className={styles.notificationModal}
    >
      {notificationDetailsQuery.isLoading ||
      notificationDetailsQuery.isError ||
      !notificationDetails ? (
        <Card>
          <LoadRetry {...notificationDetailsQuery} />
        </Card>
      ) : (
        <NotificationContent notification={notificationDetails} />
      )}
    </Modal>
  );
};

export default NotificationModal;
