import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IManufacturer from 'deprecated/Domain/IManufacturer';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllManufacturers {
  execute(presenter: IGetAllManufacturersPresenter): void;
}

export interface IGetAllManufacturersPresenter {
  setManufacturers(manufacturers: IManufacturer[]): void;
}

export default class GetAllManufacturers implements IGetAllManufacturers {
  public config: IGetTokenFromLocalStorage;

  public host: URL;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  /**
   * @TODO - fix `consistent-return` rule
   */
  // eslint-disable-next-line consistent-return
  public async execute(presenter: IGetAllManufacturersPresenter) {
    const url = new URL(this.host.href);
    url.pathname = 'api/manufacturer';

    try {
      const response = await axios.get<IManufacturer[]>(url.href, this.config.getAuthConfig());
      presenter.setManufacturers(response.data);
    } catch (error) {
      return [];
    }
  }
}
