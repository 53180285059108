import { IUser } from '../../Components/SettingsList/Security/GroupManagement/GroupTypes';

import { IEditUserProfilePresenter, IEditUserProfile } from 'deprecated/Boundary/EditUserProfile';
import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class EditUserProfile implements IEditUserProfile {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute(user: IUser, presenter: IEditUserProfilePresenter) {
    try {
      const response = await this.gateway.editUserProfile(user);
      presenter.user(response);
    } catch (error) {
      console.error(error);
      presenter.notUser(error);
    }
  }
}
