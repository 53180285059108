import dayjs from 'dayjs';

export const prettyDateFormatter = (date: string) => dayjs.utc(date).format('MMM DD, YYYY');

export const prettyDateTimeFormatter = (date: string) =>
  dayjs.utc(date).local().format('MMM DD, YYYY h:mm A');

export function isBeforeToday(date: Date | string): boolean {
  return dayjs(date).utc().isBefore(dayjs(new Date()).utc(), 'day');
}
