import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ILogPriceRuleChangeReasonRequest {
  priceRuleId: string;
  priceRuleName: string;
  reasonForChange: string;
}

export interface ILogPriceRuleChangeReason {
  execute(request: ILogPriceRuleChangeReasonRequest): void;
}

export default class LogPriceRuleChangeReason implements ILogPriceRuleChangeReason {
  hostname: string;

  config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (request: ILogPriceRuleChangeReasonRequest) => {
    const url = new URL(this.hostname);
    url.pathname = '/api/price-rules/log-price-rule-change-reason';

    await axios.post(url.href, request, this.config.getAuthConfig());
  };
}
