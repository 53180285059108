import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAddOrganizationToPolicy {
  execute(policyId: number): Promise<void>;
}

export class AddOrganizationToPolicy extends RemoteUseCaseBase implements IAddOrganizationToPolicy {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/organizationpolicies', orgNodeCode, config);
  }

  public async execute(policyId: number): Promise<void> {
    await axios.put(`${this.url.href}/${policyId}/organization`, null, this.config.getAuthConfig());
  }
}
