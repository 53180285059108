import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IPolicy } from 'deprecated/Components/SettingsList/Security/GroupManagement/PolicyTypes';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetPoliciesForOrganization {
  execute(): Promise<IPolicy[]>;
}

export class GetPoliciesForOrganization
  extends RemoteUseCaseBase
  implements IGetPoliciesForOrganization
{
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/organizationpolicies', orgNodeCode, config);
  }

  public async execute(): Promise<IPolicy[]> {
    const response = await axios.get<IPolicy[]>(
      `${this.url.href}/organization`,
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
