import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IPolicy } from 'deprecated/Components/SettingsList/Security/GroupManagement/PolicyTypes';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetPoliciesForGroup {
  execute(groupId: number): Promise<IPolicy[]>;
}

export class GetPoliciesForGroup extends RemoteUseCaseBase implements IGetPoliciesForGroup {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(groupId: number): Promise<IPolicy[]> {
    const response = await axios.get<IPolicy[]>(
      `${this.url.href}/${groupId}/policies`,
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
