/* eslint-disable class-methods-use-this */
import { AxiosRequestConfig } from 'axios';

export interface IGetTokenFromLocalStorage {
  getAuthConfig(): AxiosRequestConfig;
}

/**
 * @deprecated
 */
export default class GetTokenFromLocalStorage implements IGetTokenFromLocalStorage {
  public getAuthConfig(): AxiosRequestConfig {
    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    let idToken;
    if (oktaTokenStorage) {
      idToken = JSON.parse(oktaTokenStorage);
    }

    if (idToken === undefined || idToken.accessToken === undefined) {
      // TODO: redirect to login? Warn? Something - this could be causing no products on first login
      return {
        headers: {
          Authorization: 'Bearer undefined',
        },
      };
    }
    const accessToken = idToken.accessToken.accessToken;
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
}
