import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { FEATURE_TOGGLE_TYPE as FeatureToggleTypes } from 'fragments/FeatureToggle';

import GetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetFeatureToggles {
  execute(): Promise<FeatureToggleTypes[]>;
}

export default class GetFeatureToggles implements IGetFeatureToggles {
  public host: URL;

  public config: GetTokenFromLocalStorage;

  constructor(host: string, config: GetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async execute(): Promise<FeatureToggleTypes[]> {
    const url = new URL(this.host.href);
    url.pathname = 'api/system/featureToggles';

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());
      return response.data.activeFeatureToggles;
    } catch (error) {
      return [];
      // TODO: logging
      // API is down etc
    }
  }
}
