import { getOktaError } from '../../Boundary/Okta/OktaError';
import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ISendActivationEmail {
  executeById(presenter: ISendActivationEmailPresenter, userId: number): Promise<void>;
  executeByEmail(presenter: ISendActivationEmailPresenter, userEmail: string): Promise<void>;
  executeBulk(presenter: ISendActivationEmailPresenter, userIds: number[]): Promise<void>;
}

export interface ISendActivationEmailPresenter {
  onSuccess(): void;
  onFailure(message: string): void;
}

export default class SendActivationEmail implements ISendActivationEmail {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async executeById(presenter: ISendActivationEmailPresenter, userId: number) {
    const url = new URL(this.hostname);
    url.pathname = `/api/users/${userId}/send-activation-email`;

    try {
      await axios.post(url.href, {}, this.config.getAuthConfig());
      presenter.onSuccess();
    } catch (error) {
      if (isAxiosError(error)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        presenter.onFailure((error as AxiosError<any>).response?.data);
      }
    }
  }

  public async executeByEmail(presenter: ISendActivationEmailPresenter, userEmail: string) {
    const url = new URL(this.hostname);

    url.pathname = '/api/users/send-activation-email';
    url.searchParams.set('userEmail', userEmail);
    url.searchParams.set('orgNodeCode', this.orgNodeCode);

    try {
      await axios.post(url.href, {}, this.config.getAuthConfig());
      presenter.onSuccess();
    } catch (error: unknown) {
      const oktaError = getOktaError(error);

      if (oktaError === null) {
        presenter.onFailure(error?.toString() ?? 'Error');

        return;
      }

      presenter.onFailure(oktaError.message);
    }
  }

  public async executeBulk(presenter: ISendActivationEmailPresenter, userIds: number[]) {
    const url = new URL(this.hostname);

    url.pathname = '/api/users/bulk-send-activation-email';

    try {
      await axios.post(url.href, { userIds }, this.config.getAuthConfig());
      presenter.onSuccess();
    } catch (error) {
      if (isAxiosError(error)) {
        presenter.onFailure(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error as AxiosError<any>).response?.data.errors?.map((e: string) => e).join('\n'),
        );
      }
    }
  }
}
