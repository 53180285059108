import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IProgramRequest } from 'deprecated/Domain/Program/IProgramRequest';
import { IProgramResponse } from 'deprecated/Domain/Program/IProgramResponse';
import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IUpdateProgram {
  execute: (program: IProgramRequest) => Promise<ResponseResult<IProgramResponse>>;
}

export default class UpdatePrograms implements IUpdateProgram {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (program: IProgramRequest): Promise<ResponseResult<IProgramResponse>> => {
    const url = new URL(this.hostname);
    url.pathname = '/api/programs';

    try {
      const response = await axios.patch<IProgramResponse>(
        url.href,
        program,
        this.config.getAuthConfig(),
      );
      return Result.success(response.data);
    } catch (error) {
      return Result.failed(error);
    }
  };
}
