import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IProductListing from 'deprecated/Domain/IProductListing';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetProductListing {
  execute(ignoreProductSharing?: boolean): Promise<IProductListing[]>;
}

export default class GetProductListing implements IGetProductListing {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  execute = async (ignoreProductSharing?: boolean | null | undefined) => {
    const ignoreProductSharingInternal = ignoreProductSharing || false;

    const url = new URL(this.hostname);
    url.pathname = '/api/node/orgNode/products/list';
    url.search = `ignoreProductSharing=${ignoreProductSharingInternal}`;

    const response = await axios.get<{ products: IProductListing[] }>(
      url.href,
      this.config.getAuthConfig(),
    );

    return response.data.products;
  };
}
