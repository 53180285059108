import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { lazy, Suspense } from 'react';

const Index = lazy(() => import('./index'));

const IndexContainer = () => (
  <Suspense fallback={<LoadRetry isLoading cover />}>
    <Index />
  </Suspense>
);

export default IndexContainer;
