import { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

export type Errors = string[];
type FailedResponseResult = AxiosError<{ errors: Errors }>;

export type ResponseResult<T> = { result: T; succeeded: boolean; errors?: Errors };

export class Result {
  static success<T>(result: T): ResponseResult<T> {
    return {
      result,
      succeeded: true,
    };
  }

  static failed<T>(
    exception: unknown,
    defaultError = 'There was an error processing the request.',
  ): ResponseResult<T> {
    if (isAxiosErrorWithApiErrors(exception)) {
      return {
        result: undefined as never,
        succeeded: false,
        errors: exception.response?.data.errors,
      };
    }
    return {
      result: undefined as never,
      succeeded: false,
      errors: [defaultError],
    };
  }
}

function isAxiosErrorWithApiErrors(error: unknown): error is FailedResponseResult {
  if (!isAxiosError(error)) {
    return false;
  }
  const { response } = error;
  if (!response) {
    return false;
  }
  return response.data.errors !== undefined;
}
