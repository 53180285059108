import React, { useEffect, useRef, ReactNode } from 'react';

import { usePageTitleContext } from 'contexts/PageTitleContext';

interface PageTitleProps {
  /** Whether to disable organization name in the title */
  disableOrgName?: boolean;
  /** Title content as children */
  children: ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({ children, disableOrgName }) => {
  const { pushTitle, popTitle, setDisableOrgName } = usePageTitleContext();
  const titleIdRef = useRef<string | null>(null);

  // Generate a unique ID for this title
  const generateUniqueId = () => crypto.randomUUID();

  // Update page title when component mounts, push title to stack
  useEffect(() => {
    if (children) {
      const uniqueId = generateUniqueId();
      titleIdRef.current = uniqueId;
      pushTitle(children.toString(), uniqueId);
    }
    // Cleanup function: remove title from stack when component unmounts
    return () => {
      if (titleIdRef.current) {
        popTitle(titleIdRef.current);
      }
    };
  }, [children, pushTitle, popTitle]);

  // Effect to toggle organization name display
  useEffect(() => {
    setDisableOrgName(disableOrgName === true); // Toggle the organization name display

    // Cleanup function to reset the organization name display when component unmounts
    return () => {
      setDisableOrgName(false); // Reset to default state
    };
  }, [disableOrgName, setDisableOrgName]);

  return null; // PageTitle doesn't render anything
};

export default PageTitle;
