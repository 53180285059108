import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import { IPriceListItem } from 'deprecated/Boundary/GetPriceLists/index';
import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPriceListItemPresenter {
  priceListUpdated(
    success: boolean,
    priceList?: IPriceListItem,
    nameInUse?: boolean,
    error?: string,
  ): void;
}

export interface IUpdatePriceListItem {
  execute: (priceList: IPriceListItem, presenter: IPriceListItemPresenter) => Promise<void>;
}

export default class UpdatePriceListItem implements IUpdatePriceListItem {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(priceList: IPriceListItem, presenter: IPriceListItemPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${this.orgNodeCode}/sellerPriceLists/${priceList.id}`;

    try {
      const { data } = await axios.patch<IPriceListItem>(
        url.href,
        priceList,
        this.config.getAuthConfig(),
      );

      presenter.priceListUpdated(true, data);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = e as AxiosError<any>;
      if (error.response && error.response.status === 409) {
        presenter.priceListUpdated(false, priceList, true);
      } else {
        presenter.priceListUpdated(false, priceList, false, error.response?.data?.errors?.[0]);
      }
    }
  }
}
