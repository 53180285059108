import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import { IPriceListSettingsRequest } from 'deprecated/Boundary/CreateUser';
import { Attributes } from 'deprecated/Components/SettingsList/Security/UserManagement/BulkManagement/UserModalDefinitions';
import { IUserAttributes } from 'deprecated/Domain/IUserAttributes';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUserAttributesService {
  getUserAttributes(userId: number): Promise<IUserAttributes>;
  updateUserAttributesBulk(
    userIds: number[],
    userAttributesRequest: IUpdateUserAttributesBulkRequest,
  ): Promise<AxiosResponse>;
}

export interface IUserSegmentsService {
  addSegment(userId: number, segmentId: number): Promise<void>;
  removeSegment(userId: number, segmentId: number): Promise<void>;
  allSegments(userId: number): Promise<void>;
  clearSegments(userId: number): Promise<void>;
}

export interface IUserCostListsService {
  addCostList(userId: number, costListId: number): Promise<void>;
  removeCostList(userId: number, costListId: number): Promise<void>;
  allCostLists(userId: number): Promise<void>;
  clearCostLists(userId: number): Promise<void>;
}

export interface IUserPriceListsService {
  addPriceList(userId: number, priceListId: number): Promise<void>;
  removePriceList(userId: number, priceListId: number): Promise<void>;
  allPriceLists(userId: number): Promise<void>;
  clearPriceLists(userId: number): Promise<void>;
  linkToLocalePriceLists(userId: number): Promise<void>;
}

export interface IUserLocalesService {
  addLocation(userId: number, locationId: number): Promise<void>;
  removeLocation(userId: number, locationId: number): Promise<void>;
  addTerritory(userId: number, territoryId: number): Promise<void>;
  removeTerritory(userId: number, territoryId: number): Promise<void>;
  allLocales(userId: number): Promise<void>;
  clearLocales(userId: number): Promise<void>;
}

export interface IUserOrganizationsService {
  addOrganization(userId: number, organizationId: number): Promise<void>;
  removeOrganization(userId: number, organizationId: number): Promise<void>;
  addNetworkGroup(userId: number, networkGroupId: number): Promise<void>;
  removeNetworkGroup(userId: number, networkGroupId: number): Promise<void>;
  allOrganizations(userId: number): Promise<void>;
  clearOrganizations(userId: number): Promise<void>;
  updateReports(userId: number, reports: string[]): Promise<void>;
}

export interface IUpdateUserAttributesBulkRequest {
  attributes: { [key in Attributes]?: boolean };
  costListIds?: number[];
  priceListSettings?: IPriceListSettingsRequest;
  segmentIds?: number[];
  locationIds?: number[];
  territoryIds?: number[];
  groupIds?: number[];
  associatedOrganizationIds?: number[];
  associatedNetworkGroupIds?: number[];
  reportIds?: string[] | number[] | { id: number; name: string }[];
}

export class UserAttributesService
  implements
    IUserAttributesService,
    IUserSegmentsService,
    IUserCostListsService,
    IUserPriceListsService,
    IUserLocalesService,
    IUserOrganizationsService
{
  private readonly host: URL;

  private readonly config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(hostname);
    this.config = config;
  }

  private getBasePath(userId: number, path = ''): URL {
    const url = new URL(this.host.href);
    url.pathname = `api/users/${userId}/attributes${path}`;
    return url;
  }

  public async getUserAttributes(userId: number): Promise<IUserAttributes> {
    const url = this.getBasePath(userId);

    return axios
      .get<IUserAttributes>(url.href, this.config.getAuthConfig())
      .then((response) => response.data);
  }

  updateUserAttributesBulk(
    userIds: number[],
    userAttributesRequest: IUpdateUserAttributesBulkRequest,
  ) {
    const url = this.getBasePath(0);

    return axios.post(url.href, { userIds, ...userAttributesRequest }, this.config.getAuthConfig());
  }

  public async addSegment(userId: number, segmentId: number): Promise<void> {
    const url = this.getBasePath(userId, `/segments/${segmentId}`);

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async removeSegment(userId: number, segmentId: number): Promise<void> {
    const url = this.getBasePath(userId, `/segments/${segmentId}`);

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async allSegments(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/segments');

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async clearSegments(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/segments');

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async addCostList(userId: number, costListId: number): Promise<void> {
    const url = this.getBasePath(userId, `/cost-lists/${costListId}`);

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async removeCostList(userId: number, costListId: number): Promise<void> {
    const url = this.getBasePath(userId, `/cost-lists/${costListId}`);

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async allCostLists(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/cost-lists');

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async clearCostLists(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/cost-lists');

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async addPriceList(userId: number, priceListId: number): Promise<void> {
    const url = this.getBasePath(userId, `/price-lists/${priceListId}`);

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async removePriceList(userId: number, priceListId: number): Promise<void> {
    const url = this.getBasePath(userId, `/price-lists/${priceListId}`);

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async allPriceLists(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/price-lists');

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async clearPriceLists(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/price-lists');

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async linkToLocalePriceLists(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/price-lists');

    await axios.patch(url.href, {}, this.config.getAuthConfig());
  }

  public async addTerritory(userId: number, territoryId: number): Promise<void> {
    const url = this.getBasePath(userId, `/locales/territories/${territoryId}`);

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async removeTerritory(userId: number, territoryId: number): Promise<void> {
    const url = this.getBasePath(userId, `/locales/territories/${territoryId}`);

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async addLocation(userId: number, locationId: number): Promise<void> {
    const url = this.getBasePath(userId, `/locales/locations/${locationId}`);

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async removeLocation(userId: number, locationId: number): Promise<void> {
    const url = this.getBasePath(userId, `/locales/locations/${locationId}`);

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async allLocales(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/locales');

    await axios.put(url.href, undefined, this.config.getAuthConfig());
  }

  public async clearLocales(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/locales');

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async addOrganization(userId: number, organizationId: number): Promise<void> {
    const url = this.getBasePath(
      userId,
      `/associated-organizations/organization/${organizationId}`,
    );

    await axios.post(url.href, null, this.config.getAuthConfig());
  }

  public async removeOrganization(userId: number, organizationId: number): Promise<void> {
    const url = this.getBasePath(
      userId,
      `/associated-organizations/organization/${organizationId}`,
    );

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async allOrganizations(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/associated-organizations');

    await axios.post(url.href, null, this.config.getAuthConfig());
  }

  public async clearOrganizations(userId: number): Promise<void> {
    const url = this.getBasePath(userId, '/associated-organizations');

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async addNetworkGroup(userId: number, networkGroupId: number): Promise<void> {
    const url = this.getBasePath(
      userId,
      `/associated-organizations/network-group/${networkGroupId}`,
    );

    await axios.post(url.href, null, this.config.getAuthConfig());
  }

  public async removeNetworkGroup(userId: number, networkGroupId: number): Promise<void> {
    const url = this.getBasePath(
      userId,
      `/associated-organizations/network-group/${networkGroupId}`,
    );

    await axios.delete(url.href, this.config.getAuthConfig());
  }

  public async updateReports(userId: number, reports: string[]): Promise<void> {
    const url = this.getBasePath(userId, '/reports');

    await axios.put(url.href, { reportIds: reports }, this.config.getAuthConfig());
  }
}
