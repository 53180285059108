/* eslint-disable class-methods-use-this */
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export abstract class RemoteUseCaseBase {
  protected hostname: string;

  protected config: IGetTokenFromLocalStorage;

  protected orgNodeCode: string | undefined;

  protected url: URL;

  private pathAppended = false;

  /**
   * @abstract
   * @class
   * @name constructor
   * @param hostname base url path used to construct URL object
   * @param urlPath URL path portion of url
   * @param orgNodeCode String representation of org node code
   * @param config Authentication configuration value
   */
  constructor(
    hostname: string,
    urlPath: string,
    orgNodeCode: string | undefined,
    config: IGetTokenFromLocalStorage,
  ) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
    this.url = new URL(this.hostname);
    this.url.pathname = this.trimPath(urlPath);
  }

  private trimPath(path: string): string {
    return path.endsWith('/') ? path.slice(0, -1) : path;
  }

  protected appendPath(path: string): void {
    if (!this.pathAppended) {
      const trimmedPath = path.startsWith('/') ? path.substring(1) : path;
      this.url.pathname = this.trimPath(`${this.url.pathname}/${trimmedPath}`);
    }
  }
}
