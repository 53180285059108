import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { PriceRuleName } from 'deprecated/Boundary/GetPriceRulesNames';
import ITerritory from 'deprecated/Domain/ITerritory';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ITerritoryWithPriceRule extends ITerritory {
  priceRuleNames: PriceRuleName[];
}

export interface ITerritoriesResponse {
  organizationTerritories: ITerritory[];
  priceRuleTerritories: ITerritoryWithPriceRule[];
}

export interface IGetAllTerritoriesForOrg {
  execute(): Promise<ITerritoriesResponse>;
}

export default class GetAllTerritoriesForOrg implements IGetAllTerritoriesForOrg {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(): Promise<ITerritoriesResponse> {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/territories`;

    const response = await axios.get<ITerritoriesResponse>(url.href, this.config.getAuthConfig());
    return response.data;
  }
}
