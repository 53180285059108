import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Tooltip from 'antd/lib/tooltip';
import dayjs from 'dayjs';

export interface PriceExpiredTooltipProps {
  validTo: Date;
}

export function PriceExpiredTooltip({ validTo }: PriceExpiredTooltipProps) {
  return (
    <Tooltip
      data-testid="PriceExpiredTooltip:Tooltip"
      title={`No valid price today. This is the most recent valid price on ${dayjs(validTo).format(
        'MMM DD, YYYY',
      )}`}
    >
      <InfoCircleOutlined data-testid="PriceExpiredTooltip:Icon" />
    </Tooltip>
  );
}
