import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IProgramPaymentRequest {
  id: number | null;
  programId: string;
  checkNumber: number | null;
  dateReceived: Date | null;
  amount: number;
}

export interface IProgramPaymentResponse {
  id: number;
  checkNumber: number | null;
  dateReceived: Date | null;
  amount: number;
  errors: string[];
}

export interface ICreateOrUpdateProgramPaymentPresenter {
  programPaymentSaved(programPayment: IProgramPaymentResponse): void;
  programPaymentNotSaved(errors: string[]): void;
}

export interface ICreateOrUpdateProgramPayment {
  execute(
    programPaymentRequest: IProgramPaymentRequest,
    presenter: ICreateOrUpdateProgramPaymentPresenter,
  ): void;
}

export default class CreateOrUpdateProgramPayment implements ICreateOrUpdateProgramPayment {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    programPaymentRequest: IProgramPaymentRequest,
    presenter: ICreateOrUpdateProgramPaymentPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = 'api/programPayments';

    try {
      const response: AxiosResponse<IProgramPaymentResponse> = await axios.post(
        url.href,
        programPaymentRequest,
        this.config.getAuthConfig(),
      );
      presenter.programPaymentSaved(response.data);
    } catch (e) {
      if (isAxiosError(e)) {
        presenter.programPaymentNotSaved(e.response?.data.errors ?? []);
      }
    }
  }
}
