import InlineLoadRetry from '@smartwyre/components/lib/InlineLoadRetry';
import { useMemo } from 'react';

import { useOrgNodesDetailQuery } from '__generated__/api/OrgNodes/{code}/Api';

import { determineOrgNodeCode } from 'utils/organizations/determineOrgNodeCode';

import { OrganizationContext } from 'contexts/organizations/OrganizationContext/OrganizationContext';

export interface InitOrganizationContextProps {
  children: React.ReactNode;
}

const InitOrganizationContext = ({ children }: InitOrganizationContextProps) => {
  const code = determineOrgNodeCode();
  const queryResult = useOrgNodesDetailQuery(
    { code },
    {
      retry: 3,
      staleTime: Infinity, // always fresh, this is not updated in the span of a user session
      cacheTime: Infinity,
    },
  );

  const organization = useMemo(() => {
    if (!queryResult.data?.orgNode) {
      return null;
    }

    return {
      id: queryResult.data.orgNode.id,
      name: queryResult.data.orgNode.name || '',
      shortName: queryResult.data.orgNode.organisation?.marketingRightsOwner?.name ?? '',
      code: queryResult.data.orgNode.code || '',
      authClientId: queryResult.data.orgNode.authClientId || '',
      authIssuer: queryResult.data.orgNode.authIssuer || '',
      isSmartwyreManaged: queryResult.data.orgNode.isSmartwyreManaged,
      isExternalIdentityProvider: queryResult.data.orgNode.isExternalIdentityProvider,
      isRoutingNode: queryResult.data.orgNode.isRoutingNode,
      marketingRightsOwner: queryResult.data.orgNode.organisation?.marketingRightsOwner?.name || '',
      incorporatingParentOrganizationId:
        queryResult.data.orgNode.organisation?.incorporatingParentOrganizationId || null,
    };
  }, [queryResult.data?.orgNode]);

  return (
    <InlineLoadRetry
      queryResult={queryResult}
      dataAccessor={() => organization}
      loadRetryProps={{ cover: true }}
    >
      {(organizationNonNull) => {
        return (
          <OrganizationContext.Provider value={organizationNonNull}>
            {children}
          </OrganizationContext.Provider>
        );
      }}
    </InlineLoadRetry>
  );
};

export default InitOrganizationContext;
