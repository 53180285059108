import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';
import { useEffect } from 'react';

/**
 * Initializes pendo with user credentials
 */
export function usePendo() {
  const { isAuthenticated, smartwyreUserClaims } = useAuthentication();

  useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!isAuthenticated || !(window as any).pendo) return;

        /**
         * Get SmartwyreUserClaims
         */
        if (!smartwyreUserClaims) return;

        /**
         * Validate stage
         */
        if (
          !/Development|Staging|Production|dts|sandbox|demo|pilot.*/g.test(
            smartwyreUserClaims.aud || '',
          )
        ) {
          return;
        }

        /**
         * Call this function in your authentication promise handler or callback when your visitor and account id values are available
         * Please use Strings, Numbers, or Bools for value types.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).pendo.initialize({
          visitor: {
            id: smartwyreUserClaims.sub, // (email) - Required if user is logged in, default creates anonymous ID
            full_name: `${smartwyreUserClaims.firstname} ${smartwyreUserClaims.lastname}`, // Recommended if using Pendo Feedback
            // role: // Optional

            env: smartwyreUserClaims.aud, // "Development"/"Staging"/"Production"/"sandbox*"
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id: smartwyreUserClaims.organizationid, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            name: smartwyreUserClaims.organizationname, // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      } catch {}
    })();
  }, [isAuthenticated, smartwyreUserClaims]);
}
