import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAddGroupMember {
  execute(groupId: number, userId: number): Promise<void>;
}

export class AddGroupMember extends RemoteUseCaseBase implements IAddGroupMember {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(groupId: number, userId: number): Promise<void> {
    return axios
      .put(`${this.url.href}/${groupId}/users/${userId}`, null, this.config.getAuthConfig())
      .then(() => undefined);
  }
}
