import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeletePackageConfiguration {
  execute: (productId: number, packageConfigurationId: number) => Promise<void>;
}

export default class DeletePackageConfiguration implements IDeletePackageConfiguration {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (productId: number, packageConfigurationId: number) => {
    const url = new URL(this.hostname);
    url.pathname = `/api/canonicalproduct/${productId}/package-configuration/${packageConfigurationId}`;
    await axios.delete(url.href, this.config.getAuthConfig());
  };
}
