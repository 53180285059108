import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import ISegmentListing from 'deprecated/Domain/ISegmentListing';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUpdateSegment {
  execute(segmentRequest: ISegmentListing, presenter: IUpdateSegmentPresenter): void;
}

export interface IUpdateSegmentPresenter {
  segmentSaved(segment: IUpdateSegmentResponse): void;
  segmentNotSaved(): void;
}

export interface IUpdateSegmentResponse {
  id: number;
  name: string;
}

export default class UpdateSegment implements IUpdateSegment {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(segmentRequest: ISegmentListing, presenter: IUpdateSegmentPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/segments`;

    try {
      const response: AxiosResponse<IUpdateSegmentResponse> = await axios.patch(
        url.href,
        segmentRequest,
        this.config.getAuthConfig(),
      );
      presenter.segmentSaved(response.data);
    } catch {
      presenter.segmentNotSaved();
    }
  }
}
