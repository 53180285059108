import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IOrgNodeGateway, IOrganisationResponse } from 'deprecated/Boundary/OrgNodeGateway';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export default class OrgNodeGateway implements IOrgNodeGateway {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async getOrganisationData(code: string) {
    const getDistributorUrl = new URL(this.host.href);
    getDistributorUrl.pathname = `api/organizations/${encodeURIComponent(code)}/details`;
    try {
      const response = await axios.get<IOrganisationResponse>(
        getDistributorUrl.href,
        this.config.getAuthConfig(),
      );
      return response.data.organisation;
    } catch (error) {
      return null;
    }
  }
}
