import { INotificationContent } from '../../../../NotificationContent';
import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { useEffect, useState } from 'react';

import { useNotificationsInternalProgramNotificationsCustomersDetailQuery } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/customers/Api';
import { NotificationsInternalProgramNotificationsDetailParams } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/data-contracts';

import InternalProgramNotification from './InternalProgramNotification';

export default function InternalProgramNotificationWrapper({ notification }: INotificationContent) {
  const notificationId = parseInt(`${notification.id}`, 10);
  const customersQueryResult = useNotificationsInternalProgramNotificationsCustomersDetailQuery({
    notificationId,
  });
  /*
   * State is stored here instead of URL query params.
   * This is so when we exit the modal we have the same filters as when we entered.
   */
  const [queryParams, setQueryParams] =
    useState<NotificationsInternalProgramNotificationsDetailParams>({
      offset: 0,
      limit: 10,
      notificationId,
      organizationId: customersQueryResult.data?.data?.customers[0]?.id,
    });

  /*
   * Initialize the customer dropdown value to the first entry.
   */
  useEffect(() => {
    if (
      !queryParams.organizationId &&
      (customersQueryResult.data?.data?.customers?.length ?? -1) > 0
    ) {
      setQueryParams((prev) => ({
        ...prev,
        organizationId: customersQueryResult.data?.data?.customers[0].id,
      }));
    }
  }, [customersQueryResult.data?.data?.customers, queryParams]);

  if (
    customersQueryResult.isLoading ||
    customersQueryResult.isError ||
    !customersQueryResult.data?.data?.customers
  ) {
    return (
      <Card>
        <LoadRetry
          {...customersQueryResult}
          testIdPrefix="InternalProgramNotificationWrapper"
          cover
        />
      </Card>
    );
  }

  return (
    <InternalProgramNotification
      notification={notification}
      customers={customersQueryResult.data.data.customers}
      queryParams={queryParams}
      onCustomerSelected={(organizationId) =>
        setQueryParams((prev) => ({ ...prev, organizationId, offset: 0 }))
      }
      onPaginate={(offset, limit) => setQueryParams((prev) => ({ ...prev, offset, limit }))}
      onSearch={(search) => setQueryParams((prev) => ({ ...prev, search, offset: 0 }))}
    />
  );
}
