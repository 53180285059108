import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosError } from 'axios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteNetworkGroup {
  execute(presenter: IDeleteNetworkGroupPresenter, networkGroupId: string): void;
}

export interface IDeleteNetworkGroupPresenter {
  deleteNetworkGroupSuccess: (id: string) => void;
  deleteNetworkGroupFailed: (error: AxiosError) => void;
}

export default class DeleteNetworkGroup implements IDeleteNetworkGroup {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: IDeleteNetworkGroupPresenter, networkGroupId: string) {
    const url = new URL(this.host.href);

    url.pathname = `api/node/${encodeURIComponent(
      this.orgNodeCode,
    )}/network-groups/${networkGroupId}`;
    const headers = this.config.getAuthConfig();

    try {
      await axios.delete(url.href, headers);
      presenter.deleteNetworkGroupSuccess(networkGroupId);
    } catch (error) {
      if (isAxiosError(error)) {
        presenter.deleteNetworkGroupFailed(error);
      }
    }
  }
}
