import {
  SortableProductsProperties,
  OrderingOptions,
} from 'deprecated/Domain/SortableProductsProperties';
import ISortingParametersStore from 'deprecated/Gateways/ProductsQueryGateway/ISortingParametersStore';

export interface ISortingParameters {
  sortBy?: SortableProductsProperties;
  order?: OrderingOptions;
}

export interface IGetSortingParameters {
  execute(): ISortingParameters;
}

export default class GetSortingParameters implements IGetSortingParameters {
  private parameterStore: ISortingParametersStore;

  constructor(parameterStore: ISortingParametersStore) {
    this.parameterStore = parameterStore;
  }

  public execute() {
    return this.parameterStore.getSortingParameters();
  }
}
