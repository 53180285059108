/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';
import { AxiosError } from 'axios';
import qs from 'query-string';
import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import {
  HttpClient,
  RequestParams,
} from 'utils/axios/http-client';

import { ApiResponseSettingsThemeGetThemeGetThemeQueryResponse } from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Organizations
   * @name SettingsThemeDetail
   * @request GET:/api/{orgNodeCode}/settings/theme
   */
  settingsThemeDetail = async (variables: {
    orgNodeCode: string;
    requestParams?: RequestParams;
  }) => {
    const { orgNodeCode } = variables;
    const { data } = await this.request<ApiResponseSettingsThemeGetThemeGetThemeQueryResponse, any>(
      {
        path: `/api/${orgNodeCode}/settings/theme`,
        method: 'GET',
        format: 'json',
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'index' }),
        ...variables.requestParams,
      },
    );
    return data;
  };
}

/**
  Cache keys
 */

/**
 * infinite queries must differentiate between different queries, so we need to provide a unique cache key for each query
 */
export const settingsThemeDetailQueryCacheKey = (
  variables?: { orgNodeCode?: string; requestParams?: RequestParams },
  infiniteQuery = false,
) => {
  const { orgNodeCode } = variables || {};
  return [
    infiniteQuery ? 'infiniteQuery' : undefined,
    `api`,
    `${orgNodeCode}`,
    `settings`,
    `theme`,
    variables?.orgNodeCode,
    variables?.requestParams,
  ].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useSettingsThemeDetailQuery = (
  variables: { orgNodeCode: string; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<ApiResponseSettingsThemeGetThemeGetThemeQueryResponse, AxiosError<any>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    settingsThemeDetailQueryCacheKey(variables),
    () => new Api().settingsThemeDetail(variables),
    options,
  );
};

/**
  Infinite Query Hooks
 */

export const useInfiniteSettingsThemeDetailQuery = (
  initialVariables: { orgNodeCode: string; requestParams?: RequestParams },
  options?: Omit<
    UseInfiniteQueryOptions<ApiResponseSettingsThemeGetThemeGetThemeQueryResponse, AxiosError<any>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useInfiniteQuery(
    settingsThemeDetailQueryCacheKey(initialVariables, true),
    ({ pageParam }: { pageParam?: { orgNodeCode: string; requestParams?: RequestParams } }) =>
      new Api().settingsThemeDetail(pageParam || initialVariables),
    options,
  );
};

/**
  Mutation Hooks
 */
