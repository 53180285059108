import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IRevokeApiTokens {
  execute(present: IRevokeApiTokensPresenter): void;
}

export interface IRevokeApiTokensPresenter {
  revoked(success: boolean): void;
}

export default class RevokeApiTokens implements IRevokeApiTokens {
  public host: string;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = host;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(presenter: IRevokeApiTokensPresenter) {
    const url = new URL(this.host);

    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/authToken/revoke`;

    try {
      const response = await axios.delete(url.href, this.config.getAuthConfig());
      presenter.revoked(response.status === 200);
    } catch (e) {
      presenter.revoked(false);
    }
  }
}
