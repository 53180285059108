import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteSegmentFromProgram {
  execute: (
    programId: number,
    segmentId: number | null,
    presenter: IDeleteSegmentFromProgramPresenter,
  ) => void;
}

export interface IDeleteSegmentFromProgramPresenter {
  programSegmentDeleted: () => void;
  programSegmentNotDeleted: () => void;
}

export default class DeleteSegmentFromProgram implements IDeleteSegmentFromProgram {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (
    programId: number,
    segmentId: number | null,
    presenter: IDeleteSegmentFromProgramPresenter,
  ) => {
    const url = new URL(this.hostname);

    url.pathname = `/api/programs/${programId}/segments/${segmentId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());

      presenter.programSegmentDeleted();
    } catch (error) {
      presenter.programSegmentNotDeleted();
    }
  };
}
