import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGroup } from 'deprecated/Components/SettingsList/Security/GroupManagement/GroupTypes';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUpdateGroup {
  execute(group: IGroup): Promise<IGroup>;
}

export class UpdateGroup extends RemoteUseCaseBase implements IUpdateGroup {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(group: IGroup): Promise<IGroup> {
    const response = await axios.put<IGroup>(
      `${this.url.href}/${group.id}`,
      group,
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
