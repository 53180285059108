import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface UnitOfMeasureResponse {
  id: number;
  description: string;
  codeAnsi: string;
  codeUn: string;
}

export interface IAvailableUnitsOfMeasureResponse {
  unitsOfMeasure: UnitOfMeasureResponse[];
}

export interface IGetAvailableUnitsOfMeasure {
  execute(): Promise<IAvailableUnitsOfMeasureResponse>;
}

export class GetAvailableUnitsOfMeasure implements IGetAvailableUnitsOfMeasure {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  async execute(): Promise<IAvailableUnitsOfMeasureResponse> {
    const url = new URL(this.hostname);
    url.pathname = '/api/canonicalproduct/uom';
    const response = await axios.get<IAvailableUnitsOfMeasureResponse>(
      url.href,
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
