import { IPriceListNotificationFilters } from '../InternalPriceListNotification/InternalPriceListNotification';
import InlineFilterWrapper from '@smartwyre/components/lib/Filters/components/InlineFilterWrapper';
import SelectFilter from '@smartwyre/components/lib/Filters/components/SelectFilter';
import SwitchFilter from '@smartwyre/components/lib/Filters/components/SwitchFilter';
import { Dispatch, SetStateAction } from 'react';

import Search from 'ui/Search';

import styles from './PriceListNotificationFilters.module.scss';

const priceProductStatuses = ['All', 'Mapped', 'Unmapped', 'Unshared'];
const showAsOptions = ['$', '%'];

interface PriceListNotificationFiltersProps {
  selectedFilters: IPriceListNotificationFilters;
  setSelectedFilters: Dispatch<SetStateAction<IPriceListNotificationFilters>>;
}

const PriceListNotificationFilters = ({
  selectedFilters,
  setSelectedFilters,
}: PriceListNotificationFiltersProps) => {
  return (
    <div className={styles.filtersContainer}>
      <div className={styles.leftFilters}>
        <SelectFilter
          label="Price Status"
          value={selectedFilters.priceProductStatus}
          onChange={(priceProductStatus) => {
            setSelectedFilters({ ...selectedFilters, priceProductStatus });
          }}
          style={{ width: '150px' }}
          data-testid="PriceListNotificationFilters:PriceStatus"
          allowClear={false}
          showSearch={false}
        >
          {priceProductStatuses.map((x) => (
            <SelectFilter.Option
              key={x}
              value={x}
              data-testid={`PriceListNotificationFilters:PriceStatus:${x}`}
            >
              {x}
            </SelectFilter.Option>
          ))}
        </SelectFilter>
        <InlineFilterWrapper label="Only Show Price Changes">
          <SwitchFilter
            checked={selectedFilters.onlyPriceChanges}
            onChange={(onlyPriceChanges) => {
              setSelectedFilters({ ...selectedFilters, onlyPriceChanges });
            }}
            data-testid="PriceListNotificationFilters:OnlyShowPriceChanges"
          />
        </InlineFilterWrapper>
        <SelectFilter
          label="View As"
          value={selectedFilters.showAs}
          onChange={(showAs) => {
            setSelectedFilters({ ...selectedFilters, showAs });
          }}
          data-testid="PriceListNotificationFilters:ViewAs"
          allowClear={false}
          showSearch={false}
        >
          {showAsOptions.map((x) => (
            <SelectFilter.Option
              key={x}
              value={x}
              data-testid={`PriceListNotificationFilters:ViewAs:${x}`}
            >
              {x}
            </SelectFilter.Option>
          ))}
        </SelectFilter>
      </div>
      <Search
        className={styles.rightFilters}
        defaultValue={selectedFilters.searchText}
        onSearch={(search) => {
          setSelectedFilters({
            ...selectedFilters,
            searchText: search === '' ? undefined : search,
            offset: 0,
          });
        }}
        onBlur={(e) => {
          setSelectedFilters({
            ...selectedFilters,
            searchText: e.target.value === '' ? undefined : e.target.value,
            offset: 0,
          });
        }}
        data-testid="PriceListNotificationFilters:Search"
      />
    </div>
  );
};

export default PriceListNotificationFilters;
