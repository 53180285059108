import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IRemoveGroupMember {
  execute(groupId: number, userId: number): Promise<void>;
}

export class RemoveGroupMember extends RemoteUseCaseBase implements IRemoveGroupMember {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(groupId: number, userId: number): Promise<void> {
    return axios
      .delete(`${this.url.href}/${groupId}/users/${userId}`, this.config.getAuthConfig())
      .then(() => undefined);
  }
}
