import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAddOrganizationAliases {
  execute(organizationId: number, alias: string): Promise<void>;
}

export class AddOrganizationAliases extends RemoteUseCaseBase implements IAddOrganizationAliases {
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/organization-alias', undefined, config);
  }

  public async execute(organizationId: number, alias: string): Promise<void> {
    return axios.post(`${this.url.href}`, { organizationId, alias }, this.config.getAuthConfig());
  }
}
