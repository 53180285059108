import IFilterValuesPresenter from 'deprecated/Boundary/FilterValues/IFilterValuesPresenter';
import { IFilterValuesWatcher } from 'deprecated/Boundary/FilterValues/IFilterValuesWatcher';

import { ParamaterType, ParameterCollection } from './paramaterTypes';

/**
 * @deprecated
 */
export default class FilterValuesWatcher implements IFilterValuesWatcher {
  private observers: { [observerKey: string]: IFilterValuesPresenter } = {};

  public registerObserver = (parameterName: string, presenter: IFilterValuesPresenter) => {
    this.observers[parameterName] = presenter;
  };

  public filterValues = (filterValues: ParameterCollection) => {
    Object.keys(this.observers).forEach((observerKey: string) => {
      this.observers[observerKey].updateFilterValues(valuesOrEmpty(filterValues[observerKey]));
    });

    function valuesOrEmpty(values: ParamaterType | undefined) {
      if (values === undefined) {
        return [];
      }

      return values;
    }
  };
}
