import React from 'react';

import { IServiceProvider } from './ServiceContainer';

/**
 * @deprecated
 */
export const ServiceContext = React.createContext<IServiceProvider | null>(null);

/**
 * @deprecated
 */
export const ServiceProvider = ServiceContext.Provider;
