import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IDataFilters } from 'deprecated/Boundary/IDataFilters';
import ICost from 'deprecated/Domain/ICost';
import IGetTokenFromLocalStorage from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetCostsForCostList {
  execute(
    presenter: IGetCostsForCostListPresenter,
    costListId: number,
    offset: number,
    searchText: string,
    activeIngredientNames: string[],
    onlySelectedActiveIngredientsMode: boolean,
    standardUOM: boolean,
    validPricesToday: boolean,
    date?: string | null,
    programYear?: number | null,
    manufacturerId?: number,
    supplierId?: number,
    territoryId?: number,
    locationId?: number,
    featureLabelId?: number,
    retrieveFilters?: boolean,
    productType?: string,
    segmentId?: number,
    usePriorYearToDetermineNets?: boolean,
  ): void;
}

export interface IGetCostsForCostListPresenter {
  onSuccess(response: IGetCostsForCostListResponse): void;
  onFailure(message: string): void;
}

export interface IGetCostsForCostListResponse {
  costs: ICost[];
  costOfMoneyDateRange: Date[];
  totalProductCount: number;
  dataFilters: IDataFilters;
}

export default class GetCostsForCostList implements IGetCostsForCostList {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(
    presenter: IGetCostsForCostListPresenter,
    costListId: number,
    offset: number,
    searchText: string,
    activeIngredientNames: string[],
    onlySelectedActiveIngredientsMode: boolean,
    standardUOM: boolean,
    validPricesToday: boolean,
    date?: string,
    programYear?: number | null,
    manufacturerId?: number,
    supplierId?: number,
    territoryId?: number,
    locationId?: number,
    featureLabelId?: number,
    retrieveFilters?: boolean,
    productType?: string,
    segmentId?: number,
    usePriorYearToDetermineNets?: boolean,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `/api/node/${this.orgNodeCode}/cost-list/${costListId}/${offset}`;

    if (date) {
      url.searchParams.append('date', date);
    }

    if (programYear) {
      url.searchParams.append('programYear', programYear.toString());
    }

    if (territoryId) {
      url.searchParams.append('territoryId', territoryId.toString());
    }

    if (locationId) {
      url.searchParams.append('locationId', locationId.toString());
    }

    if (searchText) {
      url.searchParams.append('searchText', searchText);
    }

    if (manufacturerId) {
      url.searchParams.append('manufacturerId', manufacturerId.toString());
    }

    if (supplierId) {
      url.searchParams.append('supplierId', supplierId.toString());
    }

    if (validPricesToday !== undefined) {
      url.searchParams.append('validPricesToday', validPricesToday.toString());
    }

    if (standardUOM !== undefined) {
      url.searchParams.append('standardUOM', standardUOM.toString());
    }

    if (featureLabelId) {
      url.searchParams.append('featureLabelId', featureLabelId.toString());
    }

    if (activeIngredientNames && activeIngredientNames.length) {
      activeIngredientNames.forEach((ai) => url.searchParams.append('activeIngredients', ai));
      url.searchParams.append(
        'onlySelectedActiveIngredientsMode',
        onlySelectedActiveIngredientsMode.toString(),
      );
    }

    if (productType) {
      url.searchParams.append('productType', productType);
    }

    if (segmentId) {
      url.searchParams.append('segmentId', segmentId.toString());
    }

    if (retrieveFilters) {
      url.searchParams.append('retrieveFilters', retrieveFilters.toString());
    }

    if (usePriorYearToDetermineNets !== undefined) {
      url.searchParams.append(
        'usePriorYearToDetermineNets',
        usePriorYearToDetermineNets.toString(),
      );
    }

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());
      presenter.onSuccess({
        costs: response.data.products,
        totalProductCount: Number(response.data.totalProductCount),
        dataFilters: response.data.dataFilters,
        costOfMoneyDateRange:
          response.data.costOfMoneyDateRange?.map((x: string) => new Date(x)) ?? [],
      });
    } catch (error) {
      presenter.onFailure('There was an error retrieving the cost list.');
    }
  }
}
