import { NotificationPriceRow } from '../../PriceListNotificationTable/PriceListNotificationTable';
import NoPriceTooltip from '../../tooltips/NoPriceTooltip';

import { formatPriceWithUom } from 'utils/format/priceWithUom';

export interface NewPriceColumnProps {
  row: NotificationPriceRow;
  notificationType: 'InternalPriceList' | 'SharedPriceList';
}

export default function NewPriceColumn({ row, notificationType }: NewPriceColumnProps) {
  return (
    <>
      {formatPriceWithUom(row.newPrice, row.unitOfMeasure, true)}
      {row.hasNoPrice && (
        <>
          &nbsp;
          <NoPriceTooltip notificationType={notificationType} />
        </>
      )}
    </>
  );
}
