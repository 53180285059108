import { INotificationContent } from '../../../../NotificationContent';
import PriceListDetails from '../PriceListDetails/PriceListDetails';
import PriceListNotificationsActions from '../PriceListNotificationActions/PriceListNotificationActions';
import PriceListNotificationFilters from '../PriceListNotificationFilters/PriceListNotificationFilters';
import PriceListNotificationTable from '../PriceListNotificationTable/PriceListNotificationTable';
import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import Table from '@smartwyre/components/lib/Table';
import { useState } from 'react';

import { useNotificationsInternalPriceListNotificationsDetailQuery } from '__generated__/api/notifications/internal-price-list-notifications/{notificationId}/Api';
import { NotificationsInternalPriceListNotificationsDetailParams } from '__generated__/api/notifications/internal-price-list-notifications/{notificationId}/data-contracts';
import { NotificationsSharedPriceListNotificationsDetailParams } from '__generated__/api/notifications/shared-price-list-notifications/{notificationId}/data-contracts';

export interface IPriceListNotificationFilters
  extends Omit<
    // Both notification types share filters
    NotificationsSharedPriceListNotificationsDetailParams &
      NotificationsInternalPriceListNotificationsDetailParams,
    'notificationId'
  > {
  showAs: '$' | '%';
}

const InternalPriceListNotification = ({ notification }: INotificationContent) => {
  const [selectedFilters, setSelectedFilters] = useState<IPriceListNotificationFilters>({
    limit: Number(Table.DEFAULT_LIMIT_SIZE),
    offset: 0,
    onlyPriceChanges: true,
    priceProductStatus: 'All',
    showAs: '$',
  });

  const notificationId = parseInt(`${notification.id}`, 10);
  const query = useNotificationsInternalPriceListNotificationsDetailQuery({
    query: {
      notificationId,
      ...selectedFilters,
    },
  });

  return (
    <div>
      <PriceListDetails
        validFrom={query.data?.data?.validFrom}
        expiryDate={query.data?.data?.expiryDate}
      />
      <PriceListNotificationFilters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      {query.isError || !query.data?.data || !query.data?.data.priceListId ? (
        <Card>
          <LoadRetry {...query} />
        </Card>
      ) : (
        <>
          <PriceListNotificationTable
            prices={query.data.data.prices ?? []}
            totalCount={query.data.data.totalPriceCount ?? 0}
            loading={query.isLoading}
            priceListNotificationType="InternalPriceList"
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <PriceListNotificationsActions
            notification={notification}
            priceListId={query.data.data.priceListId}
            priceListNotificationType="InternalPriceList"
          />
        </>
      )}
    </div>
  );
};

export default InternalPriceListNotification;
