import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import { IContactPoint } from 'deprecated/Boundary/OrgNodeGateway';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateOrUpdateCustomerServiceContactPoint {
  execute(
    contactPoint: IContactPoint,
    presenter: ICreateOrUpdateCustomerServiceContactPointPresenter,
  ): void;
}

export interface ICreateOrUpdateCustomerServiceContactPointPresenter {
  contactPointSaved(contactPoint: IContactPoint): void;
  contactPointNotSaved(): void;
}

export default class CreateOrUpdateCustomerServiceContactPoint
  implements ICreateOrUpdateCustomerServiceContactPoint
{
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(
    contactPoint: IContactPoint,
    presenter: ICreateOrUpdateCustomerServiceContactPointPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(
      this.orgNodeCode,
    )}/contact-points/customer-service`;

    try {
      const response: AxiosResponse<IContactPoint> = await axios.post(
        url.href,
        contactPoint,
        this.config.getAuthConfig(),
      );
      presenter.contactPointSaved(response.data);
    } catch {
      presenter.contactPointNotSaved();
    }
  }
}
