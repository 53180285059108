import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ITerritory from 'deprecated/Domain/ITerritory';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IEditTerritory {
  execute(territory: ITerritory): Promise<void>;
}

export default class EditTerritory implements IEditTerritory {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(territory: ITerritory): Promise<void> {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/territories`;
    const config = this.config.getAuthConfig();

    await axios.patch(url.href, territory, config);
  }
}
