import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IRegulatoryDataListItem } from 'deprecated/Boundary/GetAllRegulatoryData';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IRegulatoryDataGateway {
  getRegulatoryData: () => Promise<IRegulatoryDataListItem[]>;
}

export default class RegulatoryDataGateway {
  public host: URL;

  public orgCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgCode = orgCode;
    this.config = config;
  }

  public async getRegulatoryData(): Promise<IRegulatoryDataListItem[]> {
    const url = new URL(this.host.href);
    url.pathname = 'api/regulatoryData';
    url.searchParams.set('organisationCode', this.orgCode);

    try {
      const response = await axios.get<IRegulatoryDataListItem[]>(
        url.href,
        this.config.getAuthConfig(),
      );

      return response.data;
    } catch (error) {
      return [];
      // TODO: error logging?
    }
  }
}
