import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUploadGateway {
  uploadProductCsv: (csv: File, dataType: string) => Promise<IApiUploadResponse>;
}

export interface IApiUploadResponse {
  results: IApiUploadResponseErrors[];
  success: boolean;
  invalidHeaders: string[];
  fileLevelError: string | null;
  hasFileLevelError: boolean;
  errorHeadline: string | null;
}

export interface IApiUploadResponseErrors {
  rowNumber: number;
  errors: string[];
  status: 'Created' | 'Updated' | 'Not Created' | 'Created With Warnings';
  errorsWithColumnInfo: ErrorWithColumnInfo[];
}

export type ErrorWithColumnInfo = {
  error: string;
  columnName: string;
  value: string | null;
};

const contentType = 'multipart/form-data';

export default class UploadGateway implements IUploadGateway {
  private static formData(csv: File) {
    const formData = new FormData();

    formData.append('upload', csv);

    return formData;
  }

  private static async catchHttpError(
    makeHttpRequest: () => Promise<IApiUploadResponse>,
  ): Promise<IApiUploadResponse> {
    try {
      return await makeHttpRequest();
    } catch (error) {
      if (isAxiosError(error) && error.response && error.response.status !== 200) {
        return {
          results: [
            {
              rowNumber: 0,
              errors: ['CSV_UPLOAD_ERROR'],
              status: 'Not Created',
              errorsWithColumnInfo: [],
            },
          ],
          invalidHeaders: [],
          success: false,
          errorHeadline: 'No rows imported.',
          fileLevelError: null,
          hasFileLevelError: false,
        };
      }

      throw error;
    }
  }

  public config: IGetTokenFromLocalStorage;

  public host: URL;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async uploadProductCsv(csv: File, dataType: string): Promise<IApiUploadResponse> {
    return UploadGateway.catchHttpError(async () => {
      const url = new URL(this.host.href);
      url.pathname = 'api/products';
      url.searchParams.set('format', 'csv');
      url.searchParams.set('dataType', dataType);

      const requestConfig = this.config.getAuthConfig();

      if (!requestConfig.headers) {
        throw new Error('Authorization headers not available while trying to upload product CSV');
      }

      requestConfig.headers['content-type'] = contentType;

      const formData = UploadGateway.formData(csv);

      requestConfig.transformRequest = () => formData;

      const response = await axios.post(url.href, formData, requestConfig);

      const { results, errorHeadline, invalidHeaders, fileLevelError, hasFileLevelError } =
        response.data;

      return {
        results,
        success: true,
        errorHeadline,
        invalidHeaders,
        fileLevelError,
        hasFileLevelError,
      };
    });
  }
}

export function isApiUploadResponseError(error: unknown): error is IApiUploadResponse {
  return typeof error === 'object' && error !== null && 'errorHeadline' in error;
}
