import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import React, { lazy, Suspense } from 'react';

import PageTitle from 'utils/pageTitle/PageTitle';

const Login = lazy(() => import('./index'));

const LoginContainer = () => (
  <Suspense fallback={<LoadRetry isLoading cover />}>
    <PageTitle>Login</PageTitle>
    <Login />
  </Suspense>
);

export default LoginContainer;
