/* eslint-disable class-methods-use-this */
import {
  IGetAllRegulatoryDataPresenter,
  IGetAllRegulatoryData,
  IRegulatoryDataListItem,
} from 'deprecated/Boundary/GetAllRegulatoryData';
import { IRegulatoryDataGateway } from 'deprecated/Gateways/RegulatoryDataGateway';

export default class GetAllRegulatoryData implements IGetAllRegulatoryData {
  private readonly gateway: IRegulatoryDataGateway;

  constructor(gateway: IRegulatoryDataGateway) {
    this.gateway = gateway;
  }

  public async execute(presenter: IGetAllRegulatoryDataPresenter) {
    const response = await this.gateway.getRegulatoryData();

    presenter.regulatoryData(response.sort(this.byName));
  }

  private byName(a: IRegulatoryDataListItem, b: IRegulatoryDataListItem): number {
    return a.name.localeCompare(b.name);
  }
}
