import { AxiosError } from 'axios';

export interface IOktaError {
  message: string;
}

export function isOktaError<T>(response: T | IOktaError): response is IOktaError {
  return (response as IOktaError).message !== undefined;
}

export function getOktaError(error: unknown) {
  if (typeof error === 'undefined' || error === null) {
    return null;
  }

  const axiosError = error as AxiosError;

  if (typeof axiosError.response === 'undefined') {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (typeof (axiosError.response.data as any).message === 'undefined') {
    return null;
  }

  return axiosError.response.data as IOktaError;
}
