import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import Tooltip from 'ui/Tooltip/Tooltip';

export const renderTooltip = () => {
  return (
    <Tooltip title="Displaying calculations for this program only">
      <InfoCircleOutlined />
    </Tooltip>
  );
};
