import { INotificationContent } from '../../../../NotificationContent';
import { AccommodationRequestNotificationDetail } from '../AccommodationRequestNotificationDetail/AccommodationRequestNotificationDetail';
import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import { FC } from 'react';

import { useNotificationsAccommodationRequestApprovedNotificationsDetailQuery } from '__generated__/api/notifications/accommodation-request-approved-notifications/{notificationId}/Api';

export const RequestApprovedNotificationDetail: FC<INotificationContent> = ({ notification }) => {
  const notificationsDetailQuery =
    useNotificationsAccommodationRequestApprovedNotificationsDetailQuery(
      { notificationId: notification.id as number },
      { enabled: !!notification.id },
    );

  if (
    notificationsDetailQuery.isLoading ||
    notificationsDetailQuery.isError ||
    !notificationsDetailQuery.data?.data
  ) {
    return (
      <Card>
        <LoadRetry {...notificationsDetailQuery} cover />
      </Card>
    );
  }
  const notificationData = notificationsDetailQuery.data.data.accommodationRequest;

  return <AccommodationRequestNotificationDetail id={notificationData?.id} />;
};
