import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';
import errorHandler from '@smartwyre/utils/lib/errorHandler';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import {
  notificationsSuppressionListQueryCacheKey,
  useNotificationsSuppressionUpdateMutation,
} from '__generated__/api/notifications/suppression/Api';

const useNotificationSuppression = () => {
  const { isAuthenticated } = useAuthentication();

  const queryClient = useQueryClient();

  const { mutate } = useNotificationsSuppressionUpdateMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(notificationsSuppressionListQueryCacheKey({}));
    },
    onError: (e) => {
      errorHandler(e);
    },
  });

  const update = useCallback(
    (suppress: boolean) => {
      if (isAuthenticated) {
        mutate({
          body: {
            suppressCurrentUserNotifications: suppress,
          },
        });
      }
    },
    [isAuthenticated, mutate],
  );

  return { update };
};

export default useNotificationSuppression;
