import { INotificationContent } from '../../../../NotificationContent';
import IPriceListNotificationType from '../../types/IPriceListNotificationType';
import Button, { ButtonTypes } from '@smartwyre/components/lib/Button/Button';
import { useNavigate } from 'react-router-dom';

import ResolveNotificationButton from 'components/Notifications/components/ResolveNotificationButton/ResolveNotificationButton';

import styles from './PriceListNotificationActions.module.scss';

interface IPriceListNotificationsActions extends INotificationContent, IPriceListNotificationType {
  priceListId?: number;
}

const PriceListNotificationsActions = ({
  notification,
  priceListId,
  priceListNotificationType,
}: IPriceListNotificationsActions) => {
  const navigate = useNavigate();

  const handleGoToCostLists = () => {
    navigate({
      pathname: '/cost-lists',
    });
  };

  const handleGoToPriceList = () => {
    navigate({
      pathname: `/price-lists/${priceListId}`,
    });
  };

  return (
    <div className={styles.actionsContainer}>
      <ResolveNotificationButton notification={notification} styleType={ButtonTypes.DEFAULT} />
      {priceListNotificationType === 'SharedPriceList' && (
        <Button onClick={handleGoToCostLists}>Go to Cost Lists</Button>
      )}
      {priceListNotificationType === 'InternalPriceList' && (
        <Button onClick={handleGoToPriceList}>Go to Price List</Button>
      )}
    </div>
  );
};

export default PriceListNotificationsActions;
