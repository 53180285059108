import { addSearchParameters } from '../addSearchParameters';
import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import axios from 'axios';

import { IPlanningListResponse } from 'deprecated/Boundary/GetPlans';
import {
  OrderingOptions,
  SortableProductsProperties,
} from 'deprecated/Domain/SortableProductsProperties';
import { IForecastPlan } from 'deprecated/RemoteUseCases/CreateForecastPlan';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

type PlanningGatewayParameters = {
  query?: string;
  sortBy?: SortableProductsProperties;
  order?: OrderingOptions;
  offset?: number;
  limit?: number;
};

export interface IPlanningApiGateway {
  getPlansByQuery: (parameters: PlanningGatewayParameters) => Promise<IPlanningListResponse>;
  getForecastPlanById: (forecastPlanId: number) => Promise<IForecastPlan>;
}

export default class PlanningApiGateway implements IPlanningApiGateway {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async getPlansByQuery(
    parameters: PlanningGatewayParameters,
  ): Promise<IPlanningListResponse> {
    try {
      const getPlansUrl = new URL(this.host.href);
      getPlansUrl.pathname = 'api/planning';

      addSearchParameters(getPlansUrl, parameters);

      const response = await authenticatedAxios.get<IPlanningListResponse>(
        getPlansUrl.href,
        this.config.getAuthConfig(),
      );
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw error;
      }
      return { plans: [] };
      // TODO: error logging?
    }
  }

  public async getForecastPlanById(forecastPlanId: number): Promise<IForecastPlan> {
    try {
      const getPlansUrl = new URL(this.host.href);
      getPlansUrl.pathname = `api/planning/${forecastPlanId}`;

      const response = await authenticatedAxios.get<IForecastPlan>(
        getPlansUrl.href,
        this.config.getAuthConfig(),
      );

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw error;
      }
      return Promise.reject();
    }
  }
}
