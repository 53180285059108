import { IBrandGateway } from 'deprecated/Boundary/BrandGateway';

import { IAttachRawDataToBrand } from './RemoteTypes';

export default class AttachRawDataToBrand implements IAttachRawDataToBrand {
  private readonly brandGateway: IBrandGateway;

  public constructor(brandGateway: IBrandGateway) {
    this.brandGateway = brandGateway;
  }

  public async execute(brandId: string, rawDataId: string) {
    await this.brandGateway.attachRawData(brandId, rawDataId);
  }
}
