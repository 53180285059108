import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

type IProgramGroupEditRequest = {
  programGroupId: number;
  name: string;
};

export interface IEditProgramGroup {
  execute(toEdit: IProgramGroupEditRequest): Promise<void>;
}

export class EditProgramGroup extends RemoteUseCaseBase implements IEditProgramGroup {
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/programGroups', undefined, config);
  }

  public async execute(toEdit: IProgramGroupEditRequest): Promise<void> {
    await axios.patch(
      `${this.url.href}/group/${toEdit.programGroupId}`,
      toEdit,
      this.config.getAuthConfig(),
    );
  }
}
