import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IGetGroupAssignableUsers {
  execute(): Promise<ResponseResult<IGroupUser[]>>;
}

export interface IGroupUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export class GetGroupAssignableUsers implements IGetGroupAssignableUsers {
  private hostName: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostName = hostname;
    this.config = config;
  }

  public async execute(): Promise<ResponseResult<IGroupUser[]>> {
    try {
      const url = new URL(this.hostName);
      url.pathname = 'api/groups/users';

      const response = await axios.get<IGroupUser[]>(url.href, this.config.getAuthConfig());

      return Result.success(response.data);
    } catch (e) {
      return Result.failed(e);
    }
  }
}
