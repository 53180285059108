/* eslint-disable max-classes-per-file */
import { IUploadGateway, IApiUploadResponse } from 'deprecated/Gateways/UploadGateway';

export interface IUploadProductCsv {
  execute(csv: File, dataType: string, presenter: IUploadProductCsvPresenter): void;
}

export interface IUploadProductCsvPresenter {
  setProductCsvUploadResult(response: IApiUploadResponse): void;
  setProductIdentifierCsvUploadResult(): void;
  startUpload(): void;
}

export default class UploadProductCsv implements IUploadProductCsv {
  private gateway: IUploadGateway;

  constructor(gateway: IUploadGateway) {
    this.gateway = gateway;
  }

  public async execute(csv: File, dataType: string, presenter: IUploadProductCsvPresenter) {
    const response = await this.gateway.uploadProductCsv(csv, dataType);

    presenter.setProductCsvUploadResult(response);
  }
}
