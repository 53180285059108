import { Route, Routes } from 'react-router-dom';

import { usePendo } from 'hooks/usePendo';

import IndexContainer from 'pages/index.container';
import LoginContainer from 'pages/login/index.container';
import PasswordContainer from 'pages/password/index.container';
import PrivacyContainer from 'pages/privacy/index.container';
import ResetPasswordContainer from 'pages/reset-password/{token}/index.container';
import TermsContainer from 'pages/terms/index.container';
import VerificationContainer from 'pages/verification/{token}/index.container';

import AuthenticatedRoute from 'routes/AuthenticatedRoute/AuthenticatedRoute';

import CustomLoginCallback from 'components/Login/components/CustomLoginCallback/CustomLoginCallback';
import Portals from 'components/Portals/Portals';

function App() {
  usePendo();

  return (
    <Routes>
      <Route path="/implicit/callback" element={<CustomLoginCallback />} />
      <Route path="/healthcheck" element={<>OK</>} />
      <Route path="/login" element={<LoginContainer />} />
      <Route path="/privacy" element={<PrivacyContainer />} />
      <Route path="/terms" element={<TermsContainer />} />
      <Route path="/verification/:token" element={<VerificationContainer />} />
      <Route path="/password" element={<PasswordContainer />} />
      <Route path="/reset-password/:token" element={<ResetPasswordContainer />} />
      <Route element={<AuthenticatedRoute />}>
        <Route
          path="*"
          element={
            <>
              <IndexContainer />
              <Portals />
            </>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
