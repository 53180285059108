import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetCashRebatePercentagePresenter {
  getCashRebatePercentageSuccess(cashRebatePercentage: ICashRebatePercentage): void;
}

export interface IGetCashRebatePercentage {
  execute(priceRuleId: string, presenter: IGetCashRebatePercentagePresenter): void;
}

export interface ICashRebatePercentage {
  rebatePercentage: number;
  priceRuleId: string;
}

export default class GetCashRebatePercentage implements IGetCashRebatePercentage {
  public host: URL;

  public orgNodeCode: string;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.orgNodeCode = orgNodeCode;
    this.config = config;
  }

  public async execute(priceRuleId: string, presenter: IGetCashRebatePercentagePresenter) {
    const url = new URL(this.host.href);
    url.pathname = `api/price-rules/${priceRuleId}/cash-rebate-percentage`;

    try {
      const response = await axios.get(url.href, this.config.getAuthConfig());
      presenter.getCashRebatePercentageSuccess(response.data);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}
