import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetGroupMembers {
  execute(groupId: number): Promise<number[]>;
}

export class GetGroupMembers extends RemoteUseCaseBase implements IGetGroupMembers {
  constructor(hostname: string, orgNodeCode: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/groups', orgNodeCode, config);
  }

  public async execute(groupId: number): Promise<number[]> {
    const response = await axios.get<number[]>(
      `${this.url.href}/${groupId}/users`,
      this.config.getAuthConfig(),
    );
    return response.data;
  }
}
