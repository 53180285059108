import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import ApiResponse from 'fragments/common/ApiResponse';

export interface IUpdateProductLifecycleActiveVariables {
  productId: number;
  active: boolean;
}

export type UpdateProductLifecycleActive = ApiResponse<null>;

export const updateProductLifecycleActive = (variables: IUpdateProductLifecycleActiveVariables) =>
  authenticatedAxios.patch<UpdateProductLifecycleActive>(
    `api/products/${variables.productId}/lifecycle/active`,
    {
      active: variables.active,
    },
  );

export const useUpdateProductLifecycleActive = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateProductLifecycleActive>,
      unknown,
      IUpdateProductLifecycleActiveVariables,
      unknown
    >,
    'mutationFn'
  >,
) =>
  useMutation(
    (variables: IUpdateProductLifecycleActiveVariables) => updateProductLifecycleActive(variables),
    options,
  );
