import { useSearchParams } from '@smartwyre/hooks/lib/useSearchParams/useSearchParams';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { notificationsListQueryCacheKey } from '__generated__/api/notifications/Api';
import { NotificationsListParams } from '__generated__/api/notifications/data-contracts';
import { notificationsUnreadCountListQueryCacheKey } from '__generated__/api/notifications/unread-count/Api';
import { notificationsDetailQueryCacheKey } from '__generated__/api/notifications/{notificationId}/Api';
import { useNotificationsReadStatusUpdateMutation } from '__generated__/api/notifications/{notificationId}/read-status/Api';

const useReadNotification = (notificationId: number, shouldReadNotification: boolean) => {
  const location = useLocation();

  const [shouldExecute, setShouldExecute] = useState(() => shouldReadNotification);
  const queryClient = useQueryClient();
  const { limit } = useSearchParams<NotificationsListParams>(location.search);

  const invalidateQueries = useCallback(async () => {
    queryClient.invalidateQueries(notificationsUnreadCountListQueryCacheKey({}));
    queryClient.invalidateQueries(notificationsDetailQueryCacheKey({ notificationId }));
    queryClient.invalidateQueries(
      notificationsListQueryCacheKey({
        query: { limit },
      }),
    );
  }, [limit, notificationId, queryClient]);

  const updateReadStatus = useNotificationsReadStatusUpdateMutation({
    onSuccess: invalidateQueries,
  });

  const setAsRead = updateReadStatus.mutateAsync;

  // Reset on prop change
  useEffect(
    () => setShouldExecute(shouldReadNotification),
    [notificationId, shouldReadNotification],
  );

  // Read once on open
  useEffect(() => {
    if (shouldExecute) {
      setAsRead({
        query: {
          notificationId,
          status: 'Read',
        },
      });
      setShouldExecute(false);
    }
  }, [notificationId, shouldExecute, setAsRead, setShouldExecute]);
};

export default useReadNotification;
