import { NotificationTypeApplicationEnum } from '__generated__/api/notifications/data-contracts';
import { QueriesGetNotificationGetNotificationQueryNotification } from '__generated__/api/notifications/{notificationId}/data-contracts';

import { RequestApprovedNotificationDetail } from './components/AccommodationRequests/components/RequestApprovedNotificationDetail/RequestApprovedNotificationDetail';
import { RequestDeniedNotificationDetail } from './components/AccommodationRequests/components/RequestDeniedNotificationDetail/RequestDeniedNotificationDetail';
import { RequestManagerNotificationDetail } from './components/AccommodationRequests/components/RequestManagerNotificationDetail/RequestManagerNotificationDetail';
import { RequestNotificationDetail } from './components/AccommodationRequests/components/RequestNotificationDetail/RequestNotificationsDetail';
import InternalPriceListNotification from './components/PriceLists/components/InternalPriceListNotification/InternalPriceListNotification';
import SharedPriceListNotification from './components/PriceLists/components/SharedPriceListNotification/SharedPriceListNotification';
import InternalProgramNotificationWrapper from './components/Programs/components/InternalProgramNotification/InternalProgramNotificationWrapper';
import SharedProgramNotification from './components/Programs/components/SharedProgramNotification/SharedProgramNotification';

export interface INotificationContent {
  notification: QueriesGetNotificationGetNotificationQueryNotification;
}

const NotificationContent = (props: INotificationContent) => {
  switch (props.notification.notificationType) {
    case NotificationTypeApplicationEnum.InternalPriceList:
      return <InternalPriceListNotification {...props} />;
    case NotificationTypeApplicationEnum.SharedPriceList:
      return <SharedPriceListNotification {...props} />;
    case NotificationTypeApplicationEnum.InternalProgram:
      return <InternalProgramNotificationWrapper {...props} />;
    case NotificationTypeApplicationEnum.SharedProgram:
      return <SharedProgramNotification {...props} />;
    case NotificationTypeApplicationEnum.AccommodationRequest:
      return <RequestNotificationDetail {...props} />;
    case NotificationTypeApplicationEnum.AccommodationRequestManager:
      return <RequestManagerNotificationDetail {...props} />;
    case NotificationTypeApplicationEnum.AccommodationRequestApproved:
      return <RequestApprovedNotificationDetail {...props} />;
    case NotificationTypeApplicationEnum.AccommodationRequestDenied:
      return <RequestDeniedNotificationDetail {...props} />;
    // This should never happen as we ensure consistency for the enum on the API
    // and the notification details are null checked before hand
    default:
      console.error(`Notification type is not valid: ${props.notification.notificationType}`);
      return <span>Error trying to handle the notification content</span>;
  }
};

export default NotificationContent;
