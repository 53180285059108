/* eslint-disable class-methods-use-this */
import axios, { AxiosError } from 'axios';

import {
  IUpdateProductBrandVariables,
  updateProductBrand,
} from 'api/products/brand/updateProductBrand';
import {
  IUpdateProductLifecycleActiveVariables,
  updateProductLifecycleActive,
} from 'api/products/lifecycle/active/updateProductLifecycleActive';
import { IUpdateProductVariables, updateProduct } from 'api/products/updateProduct';

export interface IUpdateProduct {
  executeUpdateProductAndBrand: (
    variables: { product: IUpdateProductVariables; brand: IUpdateProductBrandVariables },
    presenter: IUpdateProductPresenter,
  ) => void;
  executeUpdateProductLifecycleActive: (
    variables: IUpdateProductLifecycleActiveVariables,
    presenter: IUpdateProductPresenter,
  ) => void;
}

export interface IUpdateProductPresenter {
  productUpdated: (success: boolean, errors: string[] | undefined) => void;
}

export default class UpdateProduct implements IUpdateProduct {
  public executeUpdateProductAndBrand = async (
    variables: { product: IUpdateProductVariables; brand: IUpdateProductBrandVariables },
    presenter: IUpdateProductPresenter,
  ) => {
    const requestOne = updateProduct(variables.product);
    const requestTwo = updateProductBrand(variables.brand);

    await axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];

          const responseOneMessages = responseOne?.data?.errors
            .concat(responseOne?.data?.messages)
            .map((m) => m.description);
          const responseTwoMessages = responseTwo?.data?.errors
            .concat(responseTwo?.data?.messages)
            .map((m) => m.description);

          presenter.productUpdated(
            responseOne.status === 200 && responseTwo.status === 200,
            responseOneMessages.concat(responseTwoMessages),
          );
        }),
      )
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>;
        const formattedErrors =
          err.response?.status === 500
            ? [
                'An unexpected error occurred. Please try again later, or contact Smartwyre support.',
              ]
            : err.response?.data?.errors?.map((m: { description: string }) => m.description) || [];
        presenter.productUpdated(false, formattedErrors);
      });
  };

  public executeUpdateProductLifecycleActive = async (
    variables: IUpdateProductLifecycleActiveVariables,
    presenter: IUpdateProductPresenter,
  ) => {
    try {
      const response = await updateProductLifecycleActive(variables);

      const messages = response?.data?.errors
        .concat(response?.data?.messages)
        .map((m) => m.description);

      presenter.productUpdated(response.status === 200, messages);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as AxiosError<any>;
      presenter.productUpdated(false, err.response?.data?.errors);
    }
  };
}
