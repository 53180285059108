import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import axios, { CancelTokenSource } from 'axios';
import qs from 'query-string';

import { IResourceFilterSelection } from 'deprecated/Domain/IResourceFilter';
import { IProgram } from 'deprecated/Domain/Program/IProgram';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IGetSimplifiedPrograms {
  execute: (filterSelections: IResourceFilterSelection[]) => Promise<ResponseResult<IProgram[]>>;
}

export default class GetSimplifiedPrograms implements IGetSimplifiedPrograms {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  private cancellationToken: CancelTokenSource | undefined;

  public async execute(filterSelections: IResourceFilterSelection[]) {
    try {
      if (this.cancellationToken) {
        this.cancellationToken.cancel();
      }
      this.cancellationToken = axios.CancelToken.source();

      const url = new URL(this.hostname);
      url.pathname = '/api/programs/simplified';
      const headers = this.config.getAuthConfig().headers;
      const params = filterSelections.reduce(
        (acc, filter) => ({ ...acc, [`${filter.name}`]: filter.value }),
        {},
      );

      const response = await authenticatedAxios.get<{ programs: IProgram[] }>(url.href, {
        headers,
        params,
        cancelToken: this.cancellationToken?.token,
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'index' }),
      });

      return Result.success<IProgram[]>(response.data.programs);
    } catch (e) {
      return Result.failed<IProgram[]>(e);
    }
  }
}
