import { useMemo } from 'react';
import { Location, useLocation } from 'react-router-dom';

export const BACK_LINK_STACK: Location[] = [];

interface IBackLinkTracker {
  children?: React.ReactNode;
}

/**
 * Stores the last 2 locations in `BACK_LINK_STACK` when location changes
 * `useMemo` used to update before render
 */
const BackLinkTracker = (props: IBackLinkTracker) => {
  const location = useLocation();

  useMemo(() => {
    BACK_LINK_STACK.push(location);
    BACK_LINK_STACK.splice(0, BACK_LINK_STACK.length - 2);
  }, [location]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>;
};

export default BackLinkTracker;
