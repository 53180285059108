import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ICreateUpdatePriceRuleRequest } from 'deprecated/Domain/PriceRule/ICreateUpdatePriceRuleRequest';
import { IPriceRuleResponse } from 'deprecated/Domain/PriceRule/IPriceRuleResponse';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreatePriceRule {
  execute(priceRule: ICreateUpdatePriceRuleRequest): Promise<string>;
}

export default class CreatePriceRule {
  hostname: string;

  config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (priceRuleRequest: ICreateUpdatePriceRuleRequest) => {
    const url = new URL(this.hostname);
    url.pathname = '/api/price-rules';

    const response = await axios.post<IPriceRuleResponse>(
      url.href,
      priceRuleRequest,
      this.config.getAuthConfig(),
    );

    return response.data.id;
  };
}
