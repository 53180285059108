export enum EnvironmentType {
  Local = 'local',
  Demo = 'demo',
  Sandbox = 'sandbox',
  Pilot = 'pilot',
  Dts = 'dts',
  Staging = 'staging',
  Production = 'prod',
}

export interface ENV_CONFIG_FRAGMENT_TYPE {
  api_url: string;
  ai_instrumentation_key: string;
  blog_storage_url: string;
  azure_file_domain: string;
  azure_assets_domain: string;
  azure_downloads_domain: string;
  node_env: 'development' | 'staging' | 'production';
  env_name: EnvironmentType;
  sentryDsn: string;
  sentryEnvironment: string;
  sentryTracesSampleRate: number;
  releaseName: string;
  mapBoxApiKey: string;
  mapBoxStyle: string;
  reportIdMargin: string | undefined;
}
