import ListPriceColumn from '../../columns/ListPriceColumn/ListPriceColumn';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { QueriesGetInternalProgramNotificationGetInternalProgramNotificationQueryProduct } from '__generated__/api/notifications/internal-program-notifications/{notificationId}/data-contracts';

import { formatPriceWithUom } from 'utils/format/priceWithUom';

import { renderTooltip } from './renderTooltip';

export default function internalProgramNotificationColumns(
  viewGlobalNets: boolean,
): ColumnsType<QueriesGetInternalProgramNotificationGetInternalProgramNotificationQueryProduct> {
  const result: ColumnType<QueriesGetInternalProgramNotificationGetInternalProgramNotificationQueryProduct>[] =
    [
      {
        dataIndex: 'productName',
        key: 'productName',
        title: 'Product Name',
        width: 150,
        render: (_, row) => {
          return row.productName;
        },
      },
      {
        dataIndex: 'packageSizeLevel',
        key: 'packageSizeLevel',
        title: 'Package Size Level',
        width: 100,
        render: (_, row) => {
          return `${row.packageSize}${row.isRepack ? ' REPACK' : ''}`;
        },
      },
      {
        dataIndex: 'listPrice',
        key: 'listPrice',
        title: 'List Price',
        width: 100,
        render: (_, row) => {
          return (
            <ListPriceColumn
              notificationType="InternalProgram"
              listPrice={row.netPrice?.listPrice?.price}
              unitOfMeasure={row.netPrice?.unitOfMeasure}
              validTo={moment(row.netPrice?.listPrice?.validTo).utc().toDate()}
              hasNoPrice={row.netPrice?.listPrice?.hasNoPrice}
            />
          );
        },
      },
      {
        dataIndex: 'onInvoice',
        key: 'onInvoice',
        title: () => {
          if (viewGlobalNets) {
            return <span>On Invoice Price</span>;
          }

          return <span>On Invoice Discount {renderTooltip()}</span>;
        },
        width: 100,
        render: (_, row) => {
          if (viewGlobalNets) {
            return formatPriceWithUom(
              row.netPrice?.onInvoiceAdjustedPrice,
              row.netPrice?.unitOfMeasure,
              true,
              true,
            );
          }

          return formatPriceWithUom(
            row.netPrice?.programOnInvoiceRebateAmount,
            row.netPrice?.unitOfMeasure,
            true,
            true,
          );
        },
      },
      {
        dataIndex: 'potential',
        key: 'potential',
        title: () => {
          if (viewGlobalNets) {
            return <span>Potential Net Price</span>;
          }

          return <span>Potential Rebate Amount {renderTooltip()}</span>;
        },
        width: 100,
        render: (_, row) => {
          if (viewGlobalNets) {
            return formatPriceWithUom(
              row.netPrice?.potentialAdjustedPrice,
              row.netPrice?.unitOfMeasure,
              true,
              true,
            );
          }

          return formatPriceWithUom(
            row.netPrice?.programPotentialRebateAmount,
            row.netPrice?.unitOfMeasure,
            true,
            true,
          );
        },
      },
    ];

  return result;
}
