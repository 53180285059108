import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Tooltip from 'antd/lib/tooltip';

export interface NoPriceTooltipProps {
  notificationType: 'InternalPriceList' | 'SharedPriceList';
}

export default function NoPriceTooltip({ notificationType }: NoPriceTooltipProps) {
  return (
    <Tooltip
      data-testid="NoPriceTooltip:Tooltip"
      title={
        notificationType === 'InternalPriceList'
          ? 'Price for this product is marked as N/A in the current price list, please update your pricing'
          : 'No price available for this product in the current price list, please contact supplier to determine pricing'
      }
    >
      <InfoCircleOutlined data-testid="NoPriceTooltip:Icon" />
    </Tooltip>
  );
}
