import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import ApiResponse from 'fragments/common/ApiResponse';

export interface IUpdateProductBrandVariables {
  productId: number;
  name: string;
}

export type UpdateProductBrand = ApiResponse<null>;

export const updateProductBrand = (variables: IUpdateProductBrandVariables) =>
  authenticatedAxios.patch<UpdateProductBrand>(`api/products/${variables.productId}/brand`, {
    name: variables.name,
  });

export const useUpdateProductBrand = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<UpdateProductBrand>,
      unknown,
      IUpdateProductBrandVariables,
      unknown
    >,
    'mutationFn'
  >,
) =>
  useMutation(
    async (variables: IUpdateProductBrandVariables) => updateProductBrand(variables),
    options,
  );
