import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteTerritory {
  execute(territoryId: number, presenter: IDeleteTerritoryPresenter): void;
}

export interface IDeleteTerritoryPresenter {
  territoryDeleted(): void;
  territoryNotDeleted(error: string, priceRuleNames: string[], priceRulesExist: boolean): void;
}

export class DeleteTerritory implements IDeleteTerritory {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(territoryId: number, presenter: IDeleteTerritoryPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/territories/${territoryId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());
      presenter.territoryDeleted();
    } catch (e) {
      if (isAxiosError(e)) {
        const { response } = e;
        if (response && response.status === 409) {
          presenter.territoryNotDeleted(
            response.data.errors[0],
            response.data.priceRuleNames,
            true,
          );
        } else {
          presenter.territoryNotDeleted('There was an issue deleting the territory', [], false);
        }
      }
    }
  }
}
