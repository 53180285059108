import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface ICreateOrUpdateProductFeatureLabel {
  execute(
    productId: number,
    featureLabelId: number,
    presenter: ICreateOrUpdateProductFeatureLabelPresenter,
  ): void;
}

export interface ICreateOrUpdateProductFeatureLabelPresenter {
  featureLabelSaved(): void;
  featureLabelNotSaved(): void;
}

export default class CreateOrUpdateProductFeatureLabel
  implements ICreateOrUpdateProductFeatureLabel
{
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(
    productId: number,
    featureLabelId: number,
    presenter: ICreateOrUpdateProductFeatureLabelPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = 'api/feature-labels';

    try {
      await axios.post(url.href, { productId, featureLabelId }, this.config.getAuthConfig());
      presenter.featureLabelSaved();
    } catch {
      presenter.featureLabelNotSaved();
    }
  }
}
