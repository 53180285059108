import { ENV_CONFIG_FRAGMENT_TYPE } from 'fragments/Config';
import { ICustomTheme } from 'fragments/organization/ICustomTheme';
import { IOrganization } from 'fragments/organization/IOrganization';

import { IOrgNode } from 'deprecated/Boundary/OrgNodeGateway';
import configureServices from 'deprecated/InitServiceContainer/utils/configureServices';
import { ServiceContainer } from 'deprecated/Utilities/ServiceContainer';

export const serviceContainer = new ServiceContainer();

export function initializeServiceContainer(
  envConfig: ENV_CONFIG_FRAGMENT_TYPE,
  orgNode: IOrganization,
  fullTheme: ICustomTheme,
) {
  serviceContainer.register<IOrgNode>('OrgNode', {
    ...orgNode,
    organizationId: orgNode.id,
    alternateName: orgNode?.marketingRightsOwner,
  });

  configureServices({
    container: serviceContainer,
    hostname: window.location.hostname,
    envConfig,
  });

  serviceContainer.register<ICustomTheme>('Theme', fullTheme);

  return serviceContainer;
}
