import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteProgram {
  execute(programId: string, presenter: IDeleteProgramPresenter): void;
}

export interface IDeleteProgramPresenter {
  deleteSuccessful(): void;
  deleteUnsuccessful(): void;
}

export default class DeleteProgram implements IDeleteProgram {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public execute = async (programId: string, presenter: IDeleteProgramPresenter) => {
    const url = new URL(this.hostname);
    url.pathname = `/api/programs/${programId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());
      presenter.deleteSuccessful();
    } catch {
      presenter.deleteUnsuccessful();
    }
  };
}
