// Based on code from https://github.com/gion/is-dark-color
import hexToRgb from './hexToRgb';

export interface IsDarkColorOptions {
  readonly luminanceThreshold?: number;
  readonly override?: { [color: string]: boolean };
}

export const isDarkColor = (hexColor: string, options?: IsDarkColorOptions) => {
  if (options && options.override) {
    const overridedColor = Object.keys(options.override).find(
      (k) => k.toLowerCase() === hexColor.toLowerCase(),
    );

    if (overridedColor !== undefined) {
      return options.override[overridedColor];
    }
  }

  const { r, g, b } = hexToRgb(hexColor);
  const colorArray = [r / 255, g / 255, b / 255].map((v) => {
    if (v <= 0.03928) {
      return v / 12.92;
    }

    return ((v + 0.055) / 1.055) ** 2.4;
  });

  const luminance = 0.2126 * colorArray[0] + 0.7152 * colorArray[1] + 0.0722 * colorArray[2];
  const threshold = options?.luminanceThreshold?.valueOf() ?? 0.179;

  return luminance <= threshold;
};
