import axios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';

import { OrganisationListing } from 'deprecated/Domain/IOrganisation';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IPresentConnectedOrganisations {
  presentOrganisations(organisations: OrganisationListing[]): void;
}

export interface IOrganisationsSharingProgramsTo {
  request(): Promise<AxiosResponse<{ organisations: OrganisationListing[] }>>;
  execute(presenter: IPresentConnectedOrganisations): void;
}

export default class OrganisationsSharingProgramsTo implements IOrganisationsSharingProgramsTo {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public request = () => {
    const url = new URL(this.hostname);
    url.pathname = '/api/programs/connected-organisations';
    return axios.get(url.href, this.config.getAuthConfig());
  };

  public execute = async (presenter: IPresentConnectedOrganisations) => {
    try {
      const response = await this.request();
      presenter.presentOrganisations(response.data.organisations);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
}
