/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';
import { AxiosError } from 'axios';
import qs from 'query-string';
import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { HttpClient, RequestParams } from 'utils/axios/http-client';

import {
  ApiResponseUsersGetUserOrgNodesGetUserOrgNodesQueryResponseOrganizations,
  UsersAuthOrgNodesDetailParams,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Users
   * @name UsersAuthOrgNodesDetail
   * @summary Returns a list of org nodes and their respective app url for the current environment and user
   * @request GET:/api/users/auth/{authId}/org-nodes
   */
  usersAuthOrgNodesDetail = async (variables: {
    query: UsersAuthOrgNodesDetailParams;
    requestParams?: RequestParams;
  }) => {
    const { authId, ...query } = variables.query;
    const { data } = await this.request<
      ApiResponseUsersGetUserOrgNodesGetUserOrgNodesQueryResponseOrganizations,
      any
    >({
      path: `/api/users/auth/${authId}/org-nodes`,
      method: 'GET',
      query: query,
      format: 'json',
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'index' }),
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

/**
 * infinite queries must differentiate between different queries, so we need to provide a unique cache key for each query
 */
export const usersAuthOrgNodesDetailQueryCacheKey = (
  variables?: { query?: UsersAuthOrgNodesDetailParams; requestParams?: RequestParams },
  infiniteQuery = false,
) => {
  const { authId, ...query } = variables?.query || {};
  return [
    infiniteQuery ? 'infiniteQuery' : undefined,
    `api`,
    `users`,
    `auth`,
    `${authId}`,
    `org-nodes`,
    query,
    variables?.requestParams,
  ].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useUsersAuthOrgNodesDetailQuery = (
  variables: { query: UsersAuthOrgNodesDetailParams; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseUsersGetUserOrgNodesGetUserOrgNodesQueryResponseOrganizations,
      AxiosError<any>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    usersAuthOrgNodesDetailQueryCacheKey(variables),
    () => new Api().usersAuthOrgNodesDetail(variables),
    options,
  );
};

/**
  Infinite Query Hooks
 */

export const useInfiniteUsersAuthOrgNodesDetailQuery = (
  initialVariables: { query: UsersAuthOrgNodesDetailParams; requestParams?: RequestParams },
  options?: Omit<
    UseInfiniteQueryOptions<
      ApiResponseUsersGetUserOrgNodesGetUserOrgNodesQueryResponseOrganizations,
      AxiosError<any>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useInfiniteQuery(
    usersAuthOrgNodesDetailQueryCacheKey(initialVariables, true),
    ({
      pageParam,
    }: {
      pageParam?: { query: UsersAuthOrgNodesDetailParams; requestParams?: RequestParams };
    }) => new Api().usersAuthOrgNodesDetail(pageParam || initialVariables),
    options,
  );
};

/**
  Mutation Hooks
 */
