/* eslint-disable class-methods-use-this */
import { RcFile } from 'antd/lib/upload';
import { UploadFile as UploadFileInterface } from 'antd/lib/upload/interface';
import axios from 'axios';

export interface IUploadFile {
  execute(presenter: IUploadFilePresenter, sasToken: string, file: UploadFileInterface): void;
}

export interface IUploadFilePresenter {
  fileUploaded: () => void;
  fileNotUploaded: () => void;
}

export default class UploadFile {
  public async execute(presenter: IUploadFilePresenter, sasUrl: string, file: RcFile) {
    const url = new URL(sasUrl);
    const headers = {
      headers: {
        'x-ms-date': `${new Date().toUTCString()}`,
        'x-ms-blob-type': 'BlockBlob',
      },
    };
    if (!file) {
      presenter.fileNotUploaded();
      return;
    }
    try {
      await axios.put(url.href, file, headers);
      presenter.fileUploaded();
    } catch (error) {
      presenter.fileNotUploaded();
    }
  }
}
