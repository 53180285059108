import axios from '@smartwyre/utils/lib/authenticatedAxios';

import ApiResponse from 'fragments/common/ApiResponse';

import { ITerritoryWithOwner } from 'deprecated/Domain/ITerritory';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetAllSharedTerritoriesResponse {
  territories: ITerritoryWithOwner[];
}

export interface IGetAllSharedTerritoriesPresenter {
  presentTerritories(territories: ITerritoryWithOwner[]): void;
}

export interface IGetAllSharedTerritories {
  execute(presenter: IGetAllSharedTerritoriesPresenter): void;
}

export default class GetAllSharedTerritories implements IGetAllSharedTerritories {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  public constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(presenter: IGetAllSharedTerritoriesPresenter) {
    const url = new URL(this.hostname);

    url.pathname = '/api/organizations/territories/shared';

    const response = await axios.get<ApiResponse<IGetAllSharedTerritoriesResponse>>(
      url.href,
      this.config.getAuthConfig(),
    );

    if (!response.data.data) {
      presenter.presentTerritories([]);
    } else {
      presenter.presentTerritories(response.data.data.territories);
    }
  }
}
