import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export default interface IGetPublisherProgramGroupName {
  execute: (programGroupId: number) => Promise<ResponseResult<string>>;
}

export class GetPublisherProgramGroupName implements IGetPublisherProgramGroupName {
  private hostname: string;

  private getAuth: IGetTokenFromLocalStorage;

  constructor(hostname: string, getAuth: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.getAuth = getAuth;
  }

  public async execute(programId: number) {
    const url = new URL(this.hostname);
    url.pathname = `api/programs/${programId}/publisher-group`;

    try {
      const response = await axios.get<{ programGroupName: string }>(
        url.href,
        this.getAuth.getAuthConfig(),
      );

      return Result.success<string>(response.data.programGroupName);
    } catch (e) {
      return Result.failed<string>(e);
    }
  }
}
