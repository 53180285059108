import React from 'react';
import { createPortal } from 'react-dom';

import PermissionsContextProvider from 'contexts/Permissions/PermissionsContextProvider';

import NotificationModalPortal from './NotificationModalPortal/NotificationModalPortal';
import SuppressNotificationPortal from './SuppressNotificationPortal/SuppressNotificationPortal';

export default function Portal() {
  const root = document.getElementById('root');
  return root ? (
    <PermissionsContextProvider>
      {createPortal(<SuppressNotificationPortal />, root)}
      {createPortal(<NotificationModalPortal />, root)}
    </PermissionsContextProvider>
  ) : null;
}
