import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IUpdatePriceRuleRolloverStatus {
  execute(
    priceRuleId: number,
    isProposed: boolean,
    presenter: IUpdatePriceRuleRolloverStatusPresenter,
  ): void;
}

export interface IUpdatePriceRuleRolloverStatusPresenter {
  rolloverStatusUpdated(success: boolean): void;
}

export default class UpdatePriceRuleRolloverStatus implements IUpdatePriceRuleRolloverStatus {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(
    priceRuleId: number,
    isProposed: boolean,
    presenter: IUpdatePriceRuleRolloverStatusPresenter,
  ) {
    const url = new URL(this.hostname);
    url.pathname = `api/price-rules/${priceRuleId}/rollover/${isProposed}`;

    try {
      await axios.post(url.href, null, this.config.getAuthConfig());
      presenter.rolloverStatusUpdated(true);
    } catch (e) {
      presenter.rolloverStatusUpdated(false);
    }
  }
}
