import Button from '@smartwyre/components/lib/Button';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import useAuthentication from '@smartwyre/hooks/lib/useAuthentication';
import { FC, useMemo } from 'react';

import { useGetFeatureTogglesQuery } from 'api/system/featureToggles/getFeatureToggles';

import classes from './PermissionsContextProvider.module.scss';

import PermissionsContext from './PermissionsContext';

const errorMessage =
  'There was an error getting app permissions. Please reload and try again. If this problem persists, please contact Smartwyre';

const policyClaimsMessage =
  'There was an error getting app permissions. Please try again. If this problem persists, please contact Smartwyre';

const PermissionsContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  /**
   * Get feature toggles
   */
  const featureTogglesQueryResult = useGetFeatureTogglesQuery();

  /**
   * Get claims
   */
  const { smartwyreUserClaims, areClaimsValid, signOut } = useAuthentication();
  const claims = useMemo(() => smartwyreUserClaims?.policyclaims ?? [], [smartwyreUserClaims]);

  /**
   * Handle feature toggles loading or error
   */
  if (
    featureTogglesQueryResult.isLoading ||
    featureTogglesQueryResult.error ||
    !featureTogglesQueryResult.data?.activeFeatureToggles ||
    featureTogglesQueryResult.data.activeFeatureToggles.length === 0
  ) {
    return (
      <LoadRetry
        {...featureTogglesQueryResult}
        testIdPrefix="PermissionsContextProvider:GetFeatureToggles"
        errorMessageOverride={errorMessage}
        cover
      />
    );
  }

  /**
   * Handle claims loading
   */
  if (!areClaimsValid) {
    return (
      <LoadRetry
        testIdPrefix="PermissionsContextProvider:Claims"
        errorMessageOverride={errorMessage}
        cover
      />
    );
  }

  if (!smartwyreUserClaims?.policyclaims) {
    return (
      <div className={`${classes.wrap} ${classes.cover}`}>
        <div>
          <h3 className={classes.subHeading}>Oops! </h3>
          <h5 className={classes.heading}>{policyClaimsMessage}</h5>
          <Button
            className={classes.button}
            data-testid="PermissionsContextProvider:SmartwyrePolicyClaims:signOut"
            onClick={() => signOut()}
            size="small"
          >
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Set provider
   */
  return (
    <PermissionsContext.Provider
      /**
       * @TODO - implement jsx-no-constructed-context-values
       */
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={[...featureTogglesQueryResult.data.activeFeatureToggles, ...claims]}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsContextProvider;
