import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ILocaleTreeNode } from 'deprecated/Domain/ILocaleTreeNode';
import { IGetTerritoryTree } from 'deprecated/RemoteUseCases/GetTerritoryTreeForUser';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetTerritoryTreeForOrg extends IGetTerritoryTree {}

export default class GetTerritoryTreeForOrg implements IGetTerritoryTreeForOrg {
  public hostname: string;

  public config: IGetTokenFromLocalStorage;

  public orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(): Promise<ILocaleTreeNode | null> {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${this.orgNodeCode}/territories/org-tree`;

    const response = await axios.get<ILocaleTreeNode | null>(url.href, this.config.getAuthConfig());

    if (response.status === 204) {
      return null;
    }
    return response.data;
  }
}
