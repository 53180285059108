import { ICreateUser, ICreateUserItem } from 'deprecated/Boundary/CreateUser';
import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class CreateUser implements ICreateUser {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute(user: ICreateUserItem) {
    return this.gateway.createUser(user);
  }
}
