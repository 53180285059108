import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import ApiResponse from 'fragments/common/ApiResponse';

export interface IUpdateProductVariables {
  productId: number;
  name: string;
}

export type UpdateProduct = ApiResponse<null>;

export const updateProduct = (variables: IUpdateProductVariables) =>
  authenticatedAxios.patch<UpdateProduct>(`api/products/${variables.productId}`, {
    name: variables.name,
  });

export const useUpdateProduct = (
  options?: Omit<
    UseMutationOptions<AxiosResponse<UpdateProduct>, unknown, IUpdateProductVariables, unknown>,
    'mutationFn'
  >,
) => useMutation((variables: IUpdateProductVariables) => updateProduct(variables), options);
