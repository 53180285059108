/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';
import { AxiosError } from 'axios';
import qs from 'query-string';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from 'react-query';
import {
  HttpClient,
  RequestParams,
} from 'utils/axios/http-client';

import { BoundaryResponsesGetOrgNodeResponse } from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Organizations
   * @name OrgNodesDetail
   * @request GET:/api/OrgNodes/{code}
   */
  orgNodesDetail = async (variables: { code: string; requestParams?: RequestParams }) => {
    const { code } = variables;
    const { data } = await this.request<BoundaryResponsesGetOrgNodeResponse, any>({
      path: `/api/OrgNodes/${code}`,
      method: 'GET',
      format: 'json',
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'index' }),
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

/**
 * infinite queries must differentiate between different queries, so we need to provide a unique cache key for each query
 */
export const orgNodesDetailQueryCacheKey = (
  variables?: { code?: string; requestParams?: RequestParams },
  infiniteQuery = false,
) => {
  const { code } = variables || {};
  return [
    infiniteQuery ? 'infiniteQuery' : undefined,
    `api`,
    `OrgNodes`,
    `${code}`,
    variables?.code,
    variables?.requestParams,
  ].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useOrgNodesDetailQuery = (
  variables: { code: string; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<BoundaryResponsesGetOrgNodeResponse, AxiosError<any>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    orgNodesDetailQueryCacheKey(variables),
    () => new Api().orgNodesDetail(variables),
    options,
  );
};

/**
  Infinite Query Hooks
 */

export const useInfiniteOrgNodesDetailQuery = (
  initialVariables: { code: string; requestParams?: RequestParams },
  options?: Omit<
    UseInfiniteQueryOptions<BoundaryResponsesGetOrgNodeResponse, AxiosError<any>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useInfiniteQuery(
    orgNodesDetailQueryCacheKey(initialVariables, true),
    ({ pageParam }: { pageParam?: { code: string; requestParams?: RequestParams } }) =>
      new Api().orgNodesDetail(pageParam || initialVariables),
    options,
  );
};

/**
  Mutation Hooks
 */

export const useOrgNodesDetailMutation = (
  options?: Omit<
    UseMutationOptions<
      BoundaryResponsesGetOrgNodeResponse,
      AxiosError<any>,
      { code: string; requestParams?: RequestParams },
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(new Api().orgNodesDetail, options);
};
