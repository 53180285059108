import { DEFAULT_THEME } from '../ThemeContext';

import { SettingsThemeGetThemeGetThemeQueryResponse } from '__generated__/api/{orgNodeCode}/settings/theme/data-contracts';

import { ICustomTheme } from 'fragments/organization/ICustomTheme';
import { IOrganization } from 'fragments/organization/IOrganization';

import {
  DARKEN_COLOR_AMOUNT,
  DEFAULT_ISDARK_OPTIONS,
  DEFAULT_LIGHTENDARKEN_OPTIONS,
  isDarkColor,
  lightenDarkenColor,
  LIGHTEN_COLOR_AMOUNT,
} from 'utils/colors';

import {
  getTableHighlightBackgroundColor,
  getTableRowHoverColor,
} from 'deprecated/Constants/ThemeLookupValues';

type Must<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export function initFullTheme(
  theme: Must<SettingsThemeGetThemeGetThemeQueryResponse>,
  orgNode: IOrganization,
): ICustomTheme {
  if (!theme) {
    return DEFAULT_THEME;
  }

  const primaryVariantColor = isDarkColor(theme.secondaryColor, DEFAULT_ISDARK_OPTIONS)
    ? lightenDarkenColor(theme.secondaryColor, DARKEN_COLOR_AMOUNT, DEFAULT_LIGHTENDARKEN_OPTIONS)
    : lightenDarkenColor(theme.secondaryColor, LIGHTEN_COLOR_AMOUNT, DEFAULT_LIGHTENDARKEN_OPTIONS);

  /**
   * Setup secondary color variable
   * If `secondaryColor` and `primaryColor` are not equal we can use `secondaryColor` as the secondary
   * If they are equal then use `primaryVariantColor`
   */
  const secondaryVariantColor = (() => {
    if (theme.secondaryColor !== theme.primaryColor) {
      return theme.secondaryColor;
    }

    return primaryVariantColor;
  })();

  /**
   * Setup old UI color variables
   */
  const primaryButtonTextColor = isDarkColor(theme.secondaryColor, {
    ...DEFAULT_ISDARK_OPTIONS,
    luminanceThreshold: 0.4,
  })
    ? 'white'
    : 'black';
  const tableRowHoverColor = getTableRowHoverColor(orgNode.id);
  const tableHighlightBackgroundColor = getTableHighlightBackgroundColor(orgNode.id);

  const fullTheme = {
    ...theme,
    primaryVariantColor,
    secondaryVariantColor,
    primaryButtonTextColor,
    tableRowHoverColor,
    tableHighlightBackgroundColor,
  };

  const root = document.documentElement;

  /**
   * Set theme as site wide css variables
   */
  for (const key of Object.keys(fullTheme) as (keyof ICustomTheme)[]) {
    if (typeof key === 'string') {
      root.style.setProperty(`--theme-${key}`, String(fullTheme[key]));
    }
  }

  return fullTheme;
}
