import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IAssignTypeToOrganisation {
  execute(orgNodeCode: string, networkGroupId: string): void;
}

export default class AssignTypeToOrganisation implements IAssignTypeToOrganisation {
  public host: URL;

  public config: IGetTokenFromLocalStorage;

  constructor(host: string, config: IGetTokenFromLocalStorage) {
    this.host = new URL(host);
    this.config = config;
  }

  public async execute(orgNodeCode: string, networkGroupId: string) {
    const url = new URL(this.host.href);

    url.pathname = `api/orgNodes/${orgNodeCode}/organisation/assignNetworkGroup/${networkGroupId}`;

    await axios.post(url.href, '', this.config.getAuthConfig());
  }
}
