import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IProgramGroupOption {
  id: number;
  name: string;
}

export interface IGetProgramGroupsResponse {
  programGroupOptions: IProgramGroupOption[];
}

export interface IGetProgramGroupsForOrganization {
  execute: (isDefaultIncluded: boolean) => Promise<ResponseResult<IGetProgramGroupsResponse>>;
}

export default class GetProgramGroupsForOrganization implements IGetProgramGroupsForOrganization {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(isDefaultIncluded: boolean) {
    const url = new URL(this.hostname);
    url.pathname = `api/programs/groups/${isDefaultIncluded}`;
    const config = this.config.getAuthConfig();

    try {
      const response = await axios.get<IGetProgramGroupsResponse>(url.href, config);

      return Result.success<IGetProgramGroupsResponse>(response.data);
    } catch (e) {
      return Result.failed<IGetProgramGroupsResponse>(e);
    }
  }
}
