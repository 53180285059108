import { IEditUserPresenter, IEditUser } from 'deprecated/Boundary/EditUser';
import { IUser } from 'deprecated/Components/SettingsList/Security/GroupManagement/GroupTypes';
import { IUserApiGateway } from 'deprecated/Gateways/UserApiGateway';

export default class EditUser implements IEditUser {
  private readonly gateway: IUserApiGateway;

  constructor(gateway: IUserApiGateway) {
    this.gateway = gateway;
  }

  public async execute(user: IUser, presenter: IEditUserPresenter) {
    try {
      const response = await this.gateway.editUser(user);
      presenter.user(response);
    } catch (error) {
      console.error(error);
      presenter.notUser(error);
    }
  }
}
