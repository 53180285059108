import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';
import { ResponseResult, Result } from 'deprecated/Utilities/Response';

export interface IGetPreviousYearPrograms {
  execute: (currentProgramYear: number) => Promise<ResponseResult<IMinimalProgram[]>>;
}

export interface IMinimalProgram {
  id: number;
  name: string;
}

export default class GetPreviousYearPrograms implements IGetPreviousYearPrograms {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(currentProgramYear: number) {
    try {
      const url = new URL(this.hostname);
      url.pathname = '/api/programs/previousYear';
      url.searchParams.set('currentProgramYear', currentProgramYear.toString());

      const response = await axios.get<{ previousYearPrograms: IMinimalProgram[] }>(
        url.href,
        this.config.getAuthConfig(),
      );

      return Result.success<IMinimalProgram[]>(response.data.previousYearPrograms);
    } catch (e) {
      return Result.failed<IMinimalProgram[]>(e);
    }
  }
}
