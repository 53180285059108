import axios from '@smartwyre/utils/lib/authenticatedAxios';

import { ISegmentWithProductIds } from 'deprecated/Domain/ISegmentWithProductIds';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IGetSegmentsForAvailableProducts {
  execute(presenter: IGetSegmentsForAvailableProductsPresenter): void;
}

export interface IGetSegmentsForAvailableProductsPresenter {
  setSegments(segments: ISegmentWithProductIds[]): void;
}

export default class GetSegmentsForAvailableProducts implements IGetSegmentsForAvailableProducts {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  private orgNodeCode: string;

  constructor(hostname: string, config: IGetTokenFromLocalStorage, orgNodeCode: string) {
    this.hostname = hostname;
    this.config = config;
    this.orgNodeCode = orgNodeCode;
  }

  public async execute(presenter: IGetSegmentsForAvailableProductsPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/node/${encodeURIComponent(this.orgNodeCode)}/segments/availableProducts`;

    const response = await axios.get<{ segments: ISegmentWithProductIds[] }>(
      url.href,
      this.config.getAuthConfig(),
    );
    presenter.setSegments(response.data.segments);
  }
}
