import axios from '@smartwyre/utils/lib/authenticatedAxios';

import IFeatureLabel from 'deprecated/Domain/IFeatureLabel';
import { RemoteUseCaseBase } from 'deprecated/RemoteUseCases/RemoteUseCaseBase';
import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

type FeatureLabelEdit = Pick<IFeatureLabel, 'organizationId' | 'name'>;

export interface IEditFeatureLabel {
  execute(featureLabelId: number, toEdit: FeatureLabelEdit): Promise<void>;
}

export class EditFeatureLabel extends RemoteUseCaseBase implements IEditFeatureLabel {
  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    super(hostname, 'api/feature-labels', undefined, config);
  }

  public async execute(featureLabelId: number, toEdit: FeatureLabelEdit): Promise<void> {
    await axios.patch(`${this.url.href}/${featureLabelId}`, toEdit, this.config.getAuthConfig());
  }
}
