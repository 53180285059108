/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';
import { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { HttpClient, RequestParams } from 'utils/axios/http-client';

import {
  ApiResponseProgramsAccommodationsQueriesGetAccommodationRequestGetAccommodationRequestQueryResponseRebates,
  FrameworkWebResponsesApiResponse,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Programs
   * @name ProgramsAccommodationRequestDelete
   * @request DELETE:/api/programs/accommodation-request/{id}
   */
  programsAccommodationRequestDelete = async (variables: {
    id: number;
    requestParams?: RequestParams;
  }) => {
    const { id } = variables;
    const { data } = await this.request<
      FrameworkWebResponsesApiResponse,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/programs/accommodation-request/${id}`,
      method: 'DELETE',
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
  /**
   * No description
   *
   * @tags Programs
   * @name ProgramsAccommodationRequestDetail
   * @request GET:/api/programs/accommodation-request/{id}
   */
  programsAccommodationRequestDetail = async (variables: {
    id: number;
    requestParams?: RequestParams;
  }) => {
    const { id } = variables;
    const { data } = await this.request<
      ApiResponseProgramsAccommodationsQueriesGetAccommodationRequestGetAccommodationRequestQueryResponseRebates,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/programs/accommodation-request/${id}`,
      method: 'GET',
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const programsAccommodationRequestDetailQueryCacheKey = (variables: {
  id: number;
  requestParams?: RequestParams;
}) => {
  const { id } = variables;
  return [
    `api`,
    `programs`,
    `accommodation-request`,
    `${id}`,
    variables.id,
    variables.requestParams,
  ].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useProgramsAccommodationRequestDetailQuery = (
  variables: { id: number; requestParams?: RequestParams },
  options?: Omit<
    UseQueryOptions<
      ApiResponseProgramsAccommodationsQueriesGetAccommodationRequestGetAccommodationRequestQueryResponseRebates,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    programsAccommodationRequestDetailQueryCacheKey(variables),
    () => new Api().programsAccommodationRequestDetail(variables),
    options,
  );
};

/**
  Mutation Hooks
 */

export const useProgramsAccommodationRequestDeleteMutation = (
  options?: Omit<
    UseMutationOptions<
      FrameworkWebResponsesApiResponse,
      AxiosError<FrameworkWebResponsesApiResponse>,
      { id: number; requestParams?: RequestParams },
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(new Api().programsAccommodationRequestDelete, options);
};
