/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { HttpClient, RequestParams } from 'utils/axios/http-client';

import filterCacheKey from '@smartwyre/utils/lib/filterCacheKey';

import {
  ApiResponseQueriesGetSharedProgramNotificationGetSharedProgramNotificationQuery,
  FrameworkWebResponsesApiResponse,
  NotificationsSharedProgramNotificationsDetailParams,
} from './data-contracts';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Notifications
   * @name NotificationsSharedProgramNotificationsDetail
   * @request GET:/api/notifications/shared-program-notifications/{notificationId}
   */
  notificationsSharedProgramNotificationsDetail = async (variables: {
    query: NotificationsSharedProgramNotificationsDetailParams;
    requestParams?: RequestParams;
  }) => {
    const { notificationId, ...query } = variables.query;
    const { data } = await this.request<
      ApiResponseQueriesGetSharedProgramNotificationGetSharedProgramNotificationQuery,
      FrameworkWebResponsesApiResponse
    >({
      path: `/api/notifications/shared-program-notifications/${notificationId}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...variables.requestParams,
    });
    return data;
  };
}

/**
  Cache keys
 */

export const notificationsSharedProgramNotificationsDetailQueryCacheKey = (variables: {
  query: NotificationsSharedProgramNotificationsDetailParams;
  requestParams?: RequestParams;
}) => {
  const { notificationId, ...query } = variables.query;
  return [
    `/api/notifications/shared-program-notifications/${notificationId}`,
    query,
    variables.requestParams,
  ].filter(filterCacheKey);
};

/**
  Query Hooks
 */

export const useNotificationsSharedProgramNotificationsDetailQuery = (
  variables: {
    query: NotificationsSharedProgramNotificationsDetailParams;
    requestParams?: RequestParams;
  },
  options?: Omit<
    UseQueryOptions<
      ApiResponseQueriesGetSharedProgramNotificationGetSharedProgramNotificationQuery,
      AxiosError<FrameworkWebResponsesApiResponse>
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    notificationsSharedProgramNotificationsDetailQueryCacheKey(variables),
    () => new Api().notificationsSharedProgramNotificationsDetail(variables),
    options,
  );
};

/**
  Mutation Hooks
 */
