import { INotificationContent } from '../../../../NotificationContent';
import ViewAsNetsToggle from '../ViewAsNetsToggle';
import getPaginationOffset from '@smartwyre/utils/lib/getPaginationOffset';
import { useCallback, useState } from 'react';

import { NotificationsSharedProgramNotificationsDetailParams } from '__generated__/api/notifications/shared-program-notifications/{notificationId}/data-contracts';

import Search from 'ui/Search';

import style from './SharedProgramNotification.module.scss';

import SharedProgramNotificationTableWrapper from './components/SharedProgramNotificationTable/SharedProgramNotificationTableWrapper';

const SharedProgramNotification = ({ notification }: INotificationContent) => {
  const notificationId = parseInt(`${notification.id}`, 10);
  const [viewAsNets, setViewAsNets] = useState<boolean>(false);

  /*
   * State is stored here instead of URL query params.
   * This is so when we exit the modal we have the same filters as when we entered.
   */
  const [queryParams, setQueryParams] =
    useState<NotificationsSharedProgramNotificationsDetailParams>({
      offset: 0,
      limit: 10,
      notificationId,
      search: undefined,
    });

  const onPaginate = useCallback(
    (page: number, newLimit: number) =>
      setQueryParams((prev) => {
        return {
          ...prev,
          offset: getPaginationOffset(page, newLimit),
          limit: newLimit,
        };
      }),
    [setQueryParams],
  );

  const onSearch = useCallback(
    (value: string) =>
      setQueryParams((prev) => {
        return {
          ...prev,
          search: value,
          offset: 0,
        };
      }),
    [setQueryParams],
  );

  return (
    <div>
      <div className={style.controls}>
        <ViewAsNetsToggle viewAsNets={viewAsNets} setViewAsNets={setViewAsNets} />
        <div>
          <Search onSearch={onSearch} onBlur={(e) => onSearch(e.target.value)} />
        </div>
      </div>
      <SharedProgramNotificationTableWrapper
        notification={notification}
        viewAsNets={viewAsNets}
        queryParams={queryParams}
        onPaginate={onPaginate}
      />
    </div>
  );
};

export default SharedProgramNotification;
