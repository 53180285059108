import authenticatedAxios from '@smartwyre/utils/lib/authenticatedAxios';
import { useQuery } from 'react-query';

import { FEATURE_TOGGLE_TYPE } from 'fragments/FeatureToggle';

export interface GetFeatureToggles {
  activeFeatureToggles: FEATURE_TOGGLE_TYPE[] | null;
}

export const GET_FEATURE_TOGGLES_QUERY_KEY = 'GET_FEATURE_TOGGLES_QUERY_KEY';

/**
 * Get feature toggles from api
 */
export const getFeatureTogglesQuery = async () => {
  const { data } = await authenticatedAxios.get<GetFeatureToggles>('/api/system/featureToggles');
  return data;
};

/**
 * Get feature toggles hook
 */
export const useGetFeatureTogglesQuery = () => {
  return useQuery<GetFeatureToggles>(GET_FEATURE_TOGGLES_QUERY_KEY, getFeatureTogglesQuery);
};
