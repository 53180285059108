import ProgramNotificationFooter from '../../../ProgramNotificationFooter/ProgramNotificationFooter';
import SharedProgramNotificationCostLists from '../SharedProgramNotificationCostLists/SharedProgramNotificationCostLists';
import Card from '@smartwyre/components/lib/Card';
import LoadRetry from '@smartwyre/components/lib/LoadRetry';
import getPaginationPage from '@smartwyre/utils/lib/getPaginationPage';

import { useNotificationsSharedProgramNotificationsDetailQuery } from '__generated__/api/notifications/shared-program-notifications/{notificationId}/Api';
import { NotificationsSharedProgramNotificationsDetailParams } from '__generated__/api/notifications/shared-program-notifications/{notificationId}/data-contracts';

import { INotificationContent } from 'components/Notifications/components/NotificationModal/components/NotificationContent/NotificationContent';
import Pagination from 'ui/Pagination/Pagination';

import styles from './SharedProgramNotificationTable.module.scss';

import SharedProgramNotificationTable from './SharedProgramNotificationTable';

interface SharedProgramNotificationTableWrapperProps extends INotificationContent {
  queryParams: NotificationsSharedProgramNotificationsDetailParams;
  viewAsNets: boolean;
  onPaginate: (page: number, newLimit: number) => void;
}

const SharedProgramNotificationTableWrapper = ({
  notification,
  queryParams,
  viewAsNets,
  onPaginate,
}: SharedProgramNotificationTableWrapperProps) => {
  const queryResult = useNotificationsSharedProgramNotificationsDetailQuery({
    query: queryParams,
  });

  if (
    queryResult.isLoading ||
    queryResult.isError ||
    !queryResult.data?.data ||
    !queryResult.data?.data?.products ||
    !queryResult.data?.data?.programId ||
    !queryResult.data?.data?.costLists
  ) {
    return (
      <Card>
        <LoadRetry {...queryResult} testIdPrefix="SharedProgramNotification" />
      </Card>
    );
  }

  if (!queryResult.data.data.totalProductCount) {
    return (
      <>
        <Card>No products found to display nets for.</Card>
        <br />
        <ProgramNotificationFooter
          notification={notification}
          programId={queryResult.data.data.programId}
        />
      </>
    );
  }

  const showNets = !!queryResult.data.data.isEligible && queryResult.data.data.costLists.length > 0;

  return (
    <>
      <SharedProgramNotificationCostLists
        costLists={queryResult.data.data.costLists}
        isEligible={!!queryResult.data.data.isEligible}
      />
      <SharedProgramNotificationTable
        products={queryResult.data.data.products}
        showNets={showNets}
        viewAsNets={viewAsNets}
      />
      <Pagination
        className={styles.pagination}
        current={getPaginationPage(queryParams.offset, queryParams.limit)}
        pageSize={queryParams.limit ?? 10}
        showSizeChanger
        onChange={onPaginate}
        total={queryResult.data.data.totalProductCount}
      />
      <ProgramNotificationFooter
        notification={notification}
        programId={queryResult.data.data.programId}
      />
    </>
  );
};

export default SharedProgramNotificationTableWrapper;
