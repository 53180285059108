import axios, { isAxiosError } from '@smartwyre/utils/lib/authenticatedAxios';

import { IGetTokenFromLocalStorage } from 'deprecated/Utilities/GetTokenFromLocalStorage';

export interface IDeleteProgramPayment {
  execute(programPaymentId: number, presenter: IDeleteProgramPaymentPresenter): void;
}

export interface IDeleteProgramPaymentPresenter {
  programPaymentDeleted(): void;
  programPaymentNotDeleted(error: string[] | string): void;
}

export class DeleteProgramPayment implements IDeleteProgramPayment {
  private hostname: string;

  private config: IGetTokenFromLocalStorage;

  constructor(hostname: string, config: IGetTokenFromLocalStorage) {
    this.hostname = hostname;
    this.config = config;
  }

  public async execute(programPaymentId: number, presenter: IDeleteProgramPaymentPresenter) {
    const url = new URL(this.hostname);
    url.pathname = `api/programPayments/${programPaymentId}`;

    try {
      await axios.delete(url.href, this.config.getAuthConfig());
      presenter.programPaymentDeleted();
    } catch (e) {
      if (isAxiosError(e)) {
        const { response } = e;
        if (response && response.status === 404) {
          presenter.programPaymentNotDeleted(response.data.errors[0]);
        } else {
          presenter.programPaymentNotDeleted('There was an issue deleting the payment');
        }
      }
    }
  }
}
